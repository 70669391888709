import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Title } from './LocalStoreNameStyled';
import { LocalStoreShape } from '../../../api';
import { Container } from '../../Layout';
import ReactTooltip from 'react-tooltip';
// import Tooltip from '../Tooltip/Tooltip';

const ccid = 'localstore-name';

export const LocalStoreName = ({ cropped, shop, active, ...otherProps }) => {
  const { Name } = shop;
  const [showTooltip, setShowToolTip] = useState(false);

  useEffect(() => {
    let el = document.getElementById(Name);
    if (el.offsetWidth < el.scrollWidth) {
      setShowToolTip(true);
    }
  }, [Name]);

  const renderTitle = () => (
    <Title
      cropped={cropped}
      data-ccid={ccid}
      id={Name}
      {...otherProps}
      active={active}>
      {Name}
    </Title>
  );

  return cropped && showTooltip ? (
    <>
      <Container
        data-ccid={`${ccid}-container`}
        data-tip={Name}
        data-iscapture="true">
        {renderTitle()}
      </Container>
      <ReactTooltip />
    </>
  ) : (
    renderTitle()
  );
};

LocalStoreName.propTypes = {
  cropped: PropTypes.bool,
  shop: LocalStoreShape.isRequired,
};

export default LocalStoreName;
