import React from 'react';
import {
  SVGIcon,
  IconPropTypes,
  defaultIconSize,
  defaultIconColor,
} from '../SVGIcon';

const CaretDown = ({ color = defaultIconColor, size = defaultIconSize }) => {
  return (
    <SVGIcon
      width={size}
      height={size}
      viewBox="0 0 24 24"
      title="Caret Down Icon"
      uid="cc-icon-caret-down">
      <path
        fill={color}
        d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
      />
    </SVGIcon>
  );
};

CaretDown.propTypes = IconPropTypes;

export default CaretDown;
