import { confKeys } from '../../constants';

export const defaultConfig = {
  [confKeys.AUTO_LOCATE_BTN]: true,
  [confKeys.FILTER_CUSTOMER_TYPE]: true,
  [confKeys.FILTER_OPEN_NOW]: true,
  [confKeys.ITEMS_PER_PAGE]: 10,
  [confKeys.LOCALE]: 'en-US',
  [confKeys.MAP_LIST_MODE]: 'listInside',
  [confKeys.MAP_THEME]: 'default',
  [confKeys.MAPS_API_PROVIDER]: 'google',
  [confKeys.MAPS_API_KEY]: '',
  [confKeys.MAX_RESULTS]: 50,
  [confKeys.POPUP_BRAND_IMAGE]: false,
  [confKeys.POPUP_HEADER]: false,
  [confKeys.RESULTS_DISPLAY_MODE]: 'map',
  [confKeys.RESULTS_DISPLAY_MODE_ORDER]: ['map', 'list'],
  [confKeys.SEARCH_RADIUS]: 100,
  // [confKeys.WIDGET_MODE]: 'inline',
  [confKeys.WIDGET_MODE]: 'popup',
};

export default defaultConfig;
