import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { v4 as uuid4 } from 'uuid';
import styled, { createGlobalStyle } from 'styled-components';

const OverlayGlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
  .pac-container { 
    z-index: 7500; 
  }
`;

const containerId = 'cc-overlay-root';

const Wrapper = styled.div`
  direction: ${({ theme }) => (theme.rtl ? 'rtl' : 'ltr')};
  font-family: ${({ theme }) => theme.typography.main.fontFamily};
  color: ${({ theme }) => theme.colors.text};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  z-index: 7400;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;

const Layer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Backdrop = styled(Layer)`
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  opacity: ${({ opened }) => (opened ? 1 : 0)};
  transition: opacity 0.3s ease;
`;

const getRootNode = () => {
  let element = document.getElementById(containerId);
  if (!element) {
    element = document.createElement('div');
    element.id = containerId;
    document.body.appendChild(element);
  }
  return element;
};

export const Overlay = ({ children, id, opened, onClose = () => {} }) => {
  const overlayId = useMemo(() => {
    return `${id || uuid4()}`;
  }, [id]);

  return createPortal(
    <Wrapper data-overlay-id={overlayId} data-ccid="overlay-wrapper">
      <OverlayGlobalStyle />
      <Backdrop role="button" onClick={onClose} opened={opened} />
      {children}
    </Wrapper>,
    getRootNode()
  );
};

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Overlay;
