export const getResponseSummary = data => {
  const resultHeader =
    data &&
    data.getResult &&
    data.getResult[0] &&
    data.getResult[0].ResultGroups[0].ResultGroupHeader;
  if (resultHeader) {
    return {
      amount: resultHeader.ResultAmount,
      limit: parseInt(resultHeader.ResultLimit),
      offset: resultHeader.ResultOffset,
      total: parseInt(resultHeader.ResultAmountTotal),
      location: resultHeader.RequestGeoLocation
        ? {
            lat: parseFloat(resultHeader.RequestGeoLocation.Lat),
            lng: parseFloat(resultHeader.RequestGeoLocation.Lng),
          }
        : null,
    };
  }
  return {
    resultAmount: 0,
    offset: 0,
    limit: 0,
    total: 0,
    location: null,
  };
};

export const getCustomFields = store => {
  const { CustomFields } = store;
  store.mappedCustomFields = CustomFields.map((field, index) => {
    const { Values } = field;

    const isArray = Array.isArray(Values);

    const mappedValues = isArray
      ? Values.map(item => item.Value)
      : Object.keys(Values).map(key => Values[key].Value);

    if (field) {
      return {
        [field.Key]: mappedValues,
      };
    }

    return false;
  }).filter(field => field);
  store.CustomFields = CustomFields.map(field => {
    if (Array.isArray(field.Values)) return field;
    return {
      ...field,
      Values: Object.values(field.Values),
    };
  });
  return store;
};

export const getResponseLocalStores = data => {
  if (
    data &&
    data.getResult &&
    data.getResult[0] &&
    data.getResult[0].ResultGroups[0].LocalStores &&
    data.getResult[0].ResultGroups[0].LocalStores.length
  ) {
    return data.getResult[0].ResultGroups[0].LocalStores.filter(
      store => !!(store.GeoInfo && store.GeoInfo[0])
    ).map(store => getCustomFields(store));
  }
  return [];
};

export const createResultList = data => {
  return getResponseLocalStores(data).map((store, index) => ({
    id: `LocalStore-${store.Id}`,
    index: index,
    location: {
      lat: parseFloat(store.GeoInfo[0].Lat),
      lng: parseFloat(store.GeoInfo[0].Lng),
    },
    marker: null,
    shop: store,
  }));
};

export const exportCustomFields = data => {
  const result = JSON.parse(JSON.stringify(data));
  const customFields = {};

  result.forEach(item => {
    const shop = item.shop;
    const customAttrArray = shop.CustomFields;

    customAttrArray.forEach(attr => {
      const isArray = Array.isArray(attr.Values);

      attr.Values = isArray
        ? attr.Values.map(value => value.Value)
        : Object.keys(attr.Values).map(key => attr.Values[key].Value);

      const attrKey = attr.Key;

      if (customFields[attrKey]) {
        const values = [...attr.Values, ...customFields[attrKey].values];
        customFields[attrKey].values = values.filter(
          (item, index) => values.indexOf(item) === index
        );
      } else {
        customFields[attrKey] = {
          key: attrKey,
          name: attr.Name,
          values: attr.Values,
        };
      }
    });
  });

  let arr = Object.keys(customFields).map(key => ({
    key: key,
    name: customFields[key].name,
    //alphanumerical sorting
    values: customFields[key].values.sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true })
    ),
  }));

  return arr;
};
