import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { List } from './LocalStoreListStyled';
import {
  useSearchResults,
  useActiveSearchResult,
  useSetActiveSearchResult,
  useTrackingContext,
  useSearchPages,
  useConfigValue,
  useResultsDisplayMode,
  useDisplayModesLocalized,
  useActiveSearchFilters,
  useVisibleFilters,
} from '../../../hooks';

import { confKeys } from '../../../constants';

import LocalStoreListItem from './LocalStoreListItem';
import LocalStoreCard from '../LocalStoreCard/LocalStoreCard';

import { findClickoutType } from '../../../utils/dom';
import { groupObjects } from '../../../utils/filters';

export const listItemDisplayModes = {
  CARD: 'card',
  LISTITEM: 'listitem',
};

export const LocalStoreList = ({
  itemDisplayMode = listItemDisplayModes.LISTITEM,
}) => {
  const trackingContext = useTrackingContext();
  const { track } = trackingContext;
  const searchResults = useSearchResults();
  const selectedItem = useActiveSearchResult();
  const setSelectedItem = useSetActiveSearchResult();
  const [currentPage] = useSearchPages();
  const [displayMode] = useResultsDisplayMode();
  const displayModeOptions = useDisplayModesLocalized();
  const filters = useActiveSearchFilters();
  const visibleFilters = useVisibleFilters();

  const perPage = useConfigValue(confKeys.ITEMS_PER_PAGE);

  const ItemComponent =
    itemDisplayMode === listItemDisplayModes.CARD
      ? LocalStoreCard
      : LocalStoreListItem;

  const getStores = (currentPage, searchResults, perPage) => {
    const start = currentPage * perPage;
    const end = (currentPage + 1) * perPage;

    let results = searchResults.slice(start, end);

    return results
      .map(store => store.shop)
      .map((store, index) => {
        return {
          local_store_id: store.Id,
          local_store_name: store.Name,
          local_store_position: currentPage * perPage + index,
          distance_to_store:
            store.GeoInfo && store.GeoInfo[0]
              ? parseFloat(store.GeoInfo[0].Distance)
              : 0,
          click_and_collect_displayed:
            store.OfferInfo && store.OfferInfo.ClickAndCollectLink,
        };
      });
  };

  const getStoreTrackingData = useCallback((item, listIndex) => {
    return {
      local_store_id: item.id.split('-')[1],
      local_store_name: item.shop.Name,
      local_store_position: listIndex,
      distance_to_store:
        item.shop.GeoInfo && item.shop.GeoInfo[0]
          ? parseFloat(item.shop.GeoInfo[0].Distance)
          : 0,
    };
  }, []);

  const handleClickCard = useCallback(
    (event, id) => {
      if (setSelectedItem && id) {
        setSelectedItem(id);
        const clickoutType = findClickoutType(
          event.target,
          'store_card_click',
          event.currentTarget
        );
        const storeList = getStores(currentPage, searchResults, perPage);
        const storeIndex = searchResults.findIndex(store => store.id === id);
        const singleStore = searchResults.find(store => store.id === id);
        const displayModeDetails = displayModeOptions
          .filter(mode => mode.id === displayMode)
          .map((mode, index) => {
            return {
              label: mode.label,
              position: index,
            };
          })[0];

        track('store_click', {
          ...getStoreTrackingData(singleStore, storeIndex),
          clickout_type: clickoutType,
          local_store_list: storeList,
          click_and_collect_displayed: false,
          displayed_tab_type: displayMode,
          displayed_tab_name: displayModeDetails.label,
          displayed_tab_position: displayMode === 'list' ? 1 : 0,
          selected_filters: groupObjects(filters),
          available_filters: groupObjects(visibleFilters),
        });
      }
    },
    [
      currentPage,
      displayMode,
      displayModeOptions,
      getStoreTrackingData,
      perPage,
      searchResults,
      setSelectedItem,
      track,
      filters,
      visibleFilters,
    ]
  );

  useEffect(() => {
    track('widget_other_impression', {
      page_number: currentPage + 1,
      local_store_list: getStores(currentPage, searchResults, perPage),
      selected_filters: groupObjects(filters),
      available_filters: groupObjects(visibleFilters),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchResults, perPage, track]);

  return searchResults ? (
    <>
      <div role="alert" className="srOnly">
        {searchResults.length} results returned.
      </div>
      <List aria-live="polite">
        {searchResults.map(item => (
          <ItemComponent
            key={item.id}
            item={item}
            resultsDisplayMode={itemDisplayMode}
            selected={selectedItem && selectedItem.id === item.id}
            onClickItem={handleClickCard}
          />
        ))}
      </List>
    </>
  ) : null;
};

LocalStoreList.propTypes = {
  itemDisplayMode: PropTypes.oneOf(Object.values(listItemDisplayModes)),
};

export default LocalStoreList;
