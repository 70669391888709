import React from 'react';
import PropTypes from 'prop-types';
import {
  ContactList,
  ContactLink,
  Button,
  ContactIconLink,
  ContactLinkText,
  ContactLinkTextRoute,
} from './LocalStoreContactsStyled';
import { LocalStoreShape } from '../../../api';
import { useTranslation } from '../../../hooks';
import { Spacer } from '../../Layout';
// import { Button } from '../Button';
import { confKeys, textKeys } from '../../../constants';
import { useConfigValue } from '../../../contexts/config';

import { useSearchLocation } from '../../../contexts/search';

import {
  EmailSymbolIcon,
  GlobeOutlineIcon,
  PhoneOutlineIcon,
  RouteIcon,
  WhatsAppIcon,
} from '../../Icon';
import { getContactData } from '../utils';

const ccid = 'localstore-contacts';

const iconSize = 12;

export const contactLinkTypes = {
  EMAIL: 'email',
  PHONE: 'phone',
  WEBSITE: 'website',
  ROUTE: 'route',
  WHATSAPP: 'whatsapp',
};

const iconComponents = {
  [contactLinkTypes.EMAIL]: EmailSymbolIcon,
  [contactLinkTypes.PHONE]: PhoneOutlineIcon,
  [contactLinkTypes.WEBSITE]: GlobeOutlineIcon,
  [contactLinkTypes.ROUTE]: RouteIcon,
  [contactLinkTypes.WHATSAPP]: WhatsAppIcon,
};

const getContactIcon = type => {
  return iconComponents[type] || null;
};

const getLinkTarget = type => {
  switch (type) {
    case contactLinkTypes.EMAIL:
    case contactLinkTypes.PHONE:
      return '_self';
    case contactLinkTypes.WEBSITE:
    case contactLinkTypes.ROUTE:
    default:
      return '_blank';
  }
};

export const ContactIcon = ({ type, size }) => {
  const Icon = getContactIcon(type);
  return Icon && <Icon size={size} />;
};

export const ContactListItem = ({ type, href, title, children, style }) => {
  let clickoutType;

  switch (type) {
    case 'phone':
      clickoutType = 'phone_click';
      break;
    case 'email':
      clickoutType = 'email_click';
      break;
    case 'website':
      clickoutType = 'website_link_click';
      break;
    case 'whatsapp':
      clickoutType = 'whatsapp_link_click';
      break;
    case 'route':
      clickoutType = 'route_link_click';
      break;
    default:
      break;
  }

  return (
    <ContactLink
      data-ccid={`${ccid}__${type}`}
      data-clickout-type={clickoutType}
      href={href}
      target={getLinkTarget(type)}
      rel="noopener noreferrer"
      title={title}>
      <ContactIcon type={type} size={type === 'whatsapp' ? 13 : iconSize} />
      <Spacer size={type === 'whatsapp' ? 6 : 8} />
      {type !== 'route' ? (
        <ContactLinkText>{children}</ContactLinkText>
      ) : (
        <ContactLinkTextRoute styles={style}>{children}</ContactLinkTextRoute>
      )}
    </ContactLink>
  );
};

ContactListItem.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.values(contactLinkTypes)).isRequired,
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
  style: PropTypes.object,
};

export const ContactNavItem = ({ type, href, title }) => {
  let clickoutType;

  switch (type) {
    case 'phone':
      clickoutType = 'phone_click';
      break;
    case 'email':
      clickoutType = 'email_click';
      break;
    case 'website':
      clickoutType = 'website_link_click';
      break;
    case 'route':
      clickoutType = 'route_link_click';
      break;
    default:
      break;
  }

  return (
    <ContactIconLink
      data-ccid={`${ccid}__${type}`}
      data-clickout-type={clickoutType}
      href={href}
      target={getLinkTarget(type)}
      rel="noopener noreferrer"
      title="Store phone number">
      <ContactIcon type={type} size={14} />
    </ContactIconLink>
  );
};

ContactNavItem.propTypes = {
  type: PropTypes.oneOf(Object.values(contactLinkTypes)).isRequired,
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
};

const handleStyleValues = style => {
  Object.keys(style).forEach(() => {
    style['text'] = style.label_color;
    style['background'] = style.background_color;
    style['border'] = style.border_color;
  });

  return style;
};

export const LocalStoreContacts = ({ shop, viewType, showWhatsApp }) => {
  const t = useTranslation();
  const mapProvider = useConfigValue(confKeys.MAPS_API_PROVIDER);
  const searchLocation = useSearchLocation();
  const contacts = getContactData({
    shop,
    t,
    mapProvider,
    searchLocation,
    showWhatsApp,
  });

  const listButtonValues = useConfigValue(confKeys.LIST_VIEW_ROUTE_TO_BUTTON);
  const mapButtonValues = useConfigValue(confKeys.MAP_VIEW_ROUTE_TO_BUTTON);

  const mapRouteLabelLink = t(textKeys.LINK_LABEL_ROUTE_MAP);
  const mapRouteLabelButton = t(textKeys.BUTTON_LABEL_ROUTE_MAP);
  const listRouteLabelLink = t(textKeys.LINK_LABEL_ROUTE_LIST);
  const listRouteLabelButton = t(textKeys.BUTTON_LABEL_ROUTE_LIST);

  let buttonLabel = 'Directions';
  const mapRouteIsLink = mapButtonValues.type === 'link';
  const listRouteIsLink = listButtonValues.type === 'link';

  if (viewType === 'map') {
    if (mapButtonValues.type === 'link') {
      buttonLabel = mapRouteLabelLink;
    } else {
      buttonLabel = mapRouteLabelButton;
    }
  } else {
    if (listButtonValues.type === 'link') {
      buttonLabel = listRouteLabelLink;
    } else {
      buttonLabel = listRouteLabelButton;
    }
  }

  const openRoute = url => {
    window.open(url, '_blank');
  };

  const handleKeyDown = (event, url) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      openRoute(url);
    }
  };

  const buttonStyle = viewType === 'list' ? listButtonValues : mapButtonValues;

  return (
    <ContactList data-ccid={`${ccid}`}>
      {Object.keys(contacts).map(type => {
        const { url, text } = contacts[type];
        if (type !== 'route') {
          return (
            <ContactListItem key={type} type={type} href={url}>
              {text}
            </ContactListItem>
          );
        } else {
          if (
            (listRouteIsLink && viewType === 'list') ||
            (mapRouteIsLink && viewType === 'map')
          ) {
            return (
              <ContactListItem
                style={handleStyleValues(buttonStyle)}
                key={type}
                type={type}
                href={url}>
                {buttonLabel}
              </ContactListItem>
            );
          }
          return (
            <Button
              data-ccid={`${ccid}-btn__${type}`}
              onClick={() => openRoute(url)}
              onKeyDown={event => handleKeyDown(event, url)}
              styles={handleStyleValues(buttonStyle)}
              key={type}>
              {buttonLabel}
            </Button>
          );
        }
      })}
    </ContactList>
  );
};

LocalStoreContacts.propTypes = {
  shop: LocalStoreShape.isRequired,
};

export default LocalStoreContacts;

export const LocalStoreContactIcons = ({ shop, showWhatsApp }) => {
  const t = useTranslation();
  const mapProvider = useConfigValue(confKeys.MAPS_API_PROVIDER);
  const searchLocation = useSearchLocation();
  const contacts = getContactData({
    shop,
    t,
    mapProvider,
    searchLocation,
    showWhatsApp,
  });

  return (
    <>
      {Object.keys(contacts).map(type => {
        const { url, text } = contacts[type];
        return (
          <ContactNavItem key={type} type={type} href={url} title={text} />
        );
      })}
    </>
  );
};

LocalStoreContactIcons.propTypes = {
  shop: LocalStoreShape.isRequired,
};
