import { useContext } from 'react';
import { useConfig } from '../config';
import { confKeys } from '../../constants';
import { MapsContext } from './context';

export const useMapsConfig = () => {
  const config = useConfig();
  return {
    apiKey: config[confKeys.MAPS_API_KEY],
    apiProvider: config[confKeys.MAPS_API_PROVIDER],
    language: config[confKeys.DISPLAY_LANGUAGE] || config[confKeys.LANGUAGE],
    country: config[confKeys.COUNTRY],
  };
};

export const useMaps = () => useContext(MapsContext);

export const useMapsApiKey = () => {
  const { apiKey } = useMaps();
  return apiKey;
};

export const useMapsCountry = () => {
  const { country } = useMaps();
  return country;
};

export const useMapsLanguage = () => {
  const { language } = useMaps();
  return language;
};

/**
 * @returns {{Map:any, SearchInput:any}}
 */
export const useMapsComponents = () => {
  const { components } = useMaps();

  return components || {};
};

export const useMapComponent = () => {
  const { Map } = useMapsComponents();
  return Map;
};

export const useSearchInputComponent = () => {
  const { SearchInput } = useMapsComponents();
  return SearchInput;
};
