import React from 'react';
import PropTypes from 'prop-types';
import { Address } from './LocalStoreAddressStyled';
import { LocalStoreShape } from '../../../api';
import { Container } from '../../Layout';
import { getAddressText } from '../utils';
import { useDisplayLanguage } from '../../../contexts/config';
import ReactTooltip from 'react-tooltip';

const ccid = 'localstore-address';

export const LocalStoreAddress = ({ cropped, shop, ...otherProps }) => {
  const addressText = getAddressText(shop, useDisplayLanguage());
  return cropped ? (
    <Container
      data-ccid={`${ccid}-container`}
      data-tip={addressText}
      data-iscapture="true">
      <ReactTooltip />
      <Address cropped data-ccid={ccid} {...otherProps}>
        {addressText}
      </Address>
    </Container>
  ) : (
    <Address data-ccid={ccid} {...otherProps}>
      {addressText}
    </Address>
  );
};

LocalStoreAddress.propTypes = {
  cropped: PropTypes.bool,
  shop: LocalStoreShape.isRequired,
};

export default LocalStoreAddress;
