import axios from 'axios';
import { apiBaseUrls, apiEndpointDefaults, countriesEU } from './constants';
import { milesToKm } from '../utils/distance';
import { getDeviceType } from '../utils/deviceType';

const apiEndpoints = {
  article: 'Article',
  localStore: 'LocalStore',
  onlineShop: 'OnlineShop',
};

const getApiBaseUrl = countryCode => {
  return countriesEU.indexOf(countryCode.toUpperCase()) !== -1
    ? apiBaseUrls.EU
    : apiBaseUrls.INT;
};

const getApiEndpointUrl = (country, endpoint, payload) => {
  const baseUrl = getApiBaseUrl(country);
  const query = [];
  if (payload && typeof payload === 'object') {
    Object.keys(payload).forEach(key => query.push(`${key}=${payload[key]}`));
  }
  return `${baseUrl}${endpoint}/?${query.join('&')}`;
};

export const searchLocalStores = options => {
  const {
    token,
    country,
    language,
    subid,
    location,
    searchRadius = 0,
    maxResults = 0,
    storeType,
    customAttributes,
    distanceUnit,
    trackingid,
  } = options;
  const payload = {
    token: token,
    Country: country,
    Language: language,
    viewId: options.viewid,
    deviceType: getDeviceType(),
    productLine: 'gsf',
    widgetId: options.widgetid,
  };

  const customAttributesList = customAttributes
    ? customAttributes.custom_attributes_selection
    : [];
  payload['call'] = 'fei';
  if (subid) payload['F_SubId'] = subid;
  payload['StoreFinderRequest'] = 1;
  if (location) {
    payload['F_Lng'] = location.lng;
    payload['F_Lat'] = location.lat;
    if (location.zip) payload['F_Postcode'] = location.zip;
    if (location.city) payload['F_City'] = location.city;
  }

  if (trackingid) {
    payload['trackingId'] = trackingid;
  }

  if (searchRadius > 0) {
    let searchRadiusConverted = searchRadius;
    if (distanceUnit === 'automatic') {
      searchRadiusConverted = milesToKm(searchRadius);
    }
    payload['F_Distance[Value]'] = searchRadiusConverted;
    payload['F_Distance[CompareOperator]'] = encodeURIComponent('<');
  }
  if (storeType) {
    payload['F_StoreType[Value]'] = searchRadius;
    payload['F_StoreType[CompareOperator]'] = encodeURIComponent('=');
  }
  payload['O_ResultAmount[Limit]'] =
    maxResults > 0 ? maxResults : apiEndpointDefaults.LocalStore.limit;

  payload['O_ResultFields'] = customAttributesList
    ? apiEndpointDefaults.LocalStore.resultFields.concat(
        customAttributesList.join(',')
      )
    : apiEndpointDefaults.LocalStore.resultFields;
  const apiUrl = getApiEndpointUrl(country, apiEndpoints.localStore, payload);
  return axios.get(apiUrl).then(response => response.data);
};
