import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import {
  textKeys,
  // confKeys
} from '../../constants';
import {
  useSearchPages,
  useTranslation,
  useTrackingContext,
  useDisplayModesLocalized,
  useResultsDisplayMode,
  // useSearchResults,
  // useConfigValue,
} from '../../hooks';
import { Button } from '../Button';
import { Box } from '../Layout';

const ccid = 'more-results';

export const MoreResultsContainer = styled(Box)`
  padding: 10px 16px 80px;
  align-items: center;
`;

export const MoreResults = () => {
  const t = useTranslation();
  const trackingContext = useTrackingContext();
  const { track } = trackingContext;
  const [currentPage, setCurrentPage, numPages] = useSearchPages();
  const displayModeOptions = useDisplayModesLocalized();
  const [displayMode] = useResultsDisplayMode();
  // const searchResults = useSearchResults();
  // const perPage = useConfigValue(confKeys.ITEMS_PER_PAGE);

  const hasMoreResults = useMemo(
    () => numPages > 1 && currentPage < numPages - 1,
    [currentPage, numPages]
  );

  // const getStores = (currentPage, searchResults, perPage) => {
  //   const start = currentPage * perPage;
  //   const end = (currentPage + 1) * perPage;

  //   let results = searchResults.slice(start, end);

  //   return results
  //     .map(store => store.shop)
  //     .map((store, index) => {
  //       return {
  //         local_store_id: store.Id,
  //         local_store_name: store.Name,
  //         local_store_position: currentPage * perPage + index,
  //         distance_to_store:
  //           store.GeoInfo && store.GeoInfo[0]
  //             ? parseFloat(store.GeoInfo[0].Distance)
  //             : 0,
  //         click_and_collect_displayed:
  //           store.OfferInfo && store.OfferInfo.ClickAndCollectLink,
  //       };
  //     });
  // };

  const getDisplayTabsDetails = useCallback(() => {
    const displayModeDetails = displayModeOptions
      .filter(mode => mode.id === displayMode)
      .map((mode, index) => {
        return {
          label: mode.label,
          position: index,
        };
      })[0];

    return {
      displayMode,
      ...displayModeDetails,
    };
  }, [displayMode, displayModeOptions]);

  const handleClickMoreResults = useCallback(() => {
    if (currentPage >= 0) {
      setCurrentPage(currentPage + 1);
      // const storeList = getStores(currentPage, searchResults, perPage);

      const payload = {
        // local_store_list: storeList,
      };

      if (getDisplayTabsDetails()) {
        const tabs = getDisplayTabsDetails();

        payload.displayed_tab_type = tabs.displayMode;
        payload.displayed_tab_name = tabs.label;
        payload.displayed_tab_position = tabs.position;
      }

      track('view_more_click', payload);
    }
  }, [currentPage, getDisplayTabsDetails, setCurrentPage, track]);

  return (
    <MoreResultsContainer data-ccid={`${ccid}-container`}>
      {hasMoreResults && (
        <Button data-ccid={`${ccid}-button`} onClick={handleClickMoreResults}>
          {t(textKeys.CTA_MORE_RESULTS)}
        </Button>
      )}
    </MoreResultsContainer>
  );
};

export default MoreResults;
