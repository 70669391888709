import { confKeys } from '../../../constants';
import defaultConfig from '../defaultConfig';

const defaultGoogleMapsApiKey = 'AIzaSyDEYvc8rutqaKFJH9XeSmVz9GF3iCJEyRU';
const defaultMapboxApiKey =
  'pk.eyJ1IjoicHJpY2VzcGlkZXIiLCJhIjoiY2tmc3FzeDQ1MGV6MzJ1cXFzeWV6ZHJ2MyJ9.GdN4ehIASUapDAN2VtMiNQ';

const defaultPreset = {
  ...defaultConfig,
  [confKeys.MAPS_API_PROVIDER]: 'google',
  [confKeys.MAPS_API_KEY]: defaultGoogleMapsApiKey,
};

const presets = {
  default: {
    ...defaultPreset,
  },
  google: {
    ...defaultPreset,
    [confKeys.MAPS_API_PROVIDER]: 'google',
    [confKeys.MAPS_API_KEY]: defaultGoogleMapsApiKey,
  },
  mapbox: {
    ...defaultPreset,
    [confKeys.MAPS_API_PROVIDER]: 'mapbox',
    [confKeys.MAPS_API_KEY]: defaultMapboxApiKey,
  },
  orthomol: {
    ...defaultPreset,
    [confKeys.LOCALE]: 'de-DE',
    [confKeys.COUNTRY]: 'de',
    [confKeys.SUB_ID]: 'website',
    [confKeys.TOKEN]: 'c49b9d4c57ca15bfdbecac78151d20e285f193ea',
  },
  '3m': {
    ...defaultPreset,
    [confKeys.LOCALE]: 'zh-CN',
    [confKeys.COUNTRY]: 'cn',
    [confKeys.SUB_ID]: 'sl_tebg_csd_cwf',
    [confKeys.TOKEN]: '99c976f1d1aab575ceaba5749f1c60fa4df039d9',
    [confKeys.WIDGET_ID]: 'kMOxemmxj8_6b214900-460f-11ec-a5ed-258753442c4866',
    [confKeys.DISPLAY_LANGUAGE]: 'zh',
  },
  '3Mbaidu': {
    ...defaultPreset,
    [confKeys.LOCALE]: 'zh-CN',
    [confKeys.COUNTRY]: 'cn',
    [confKeys.SUB_ID]: 'sl_tebg_csd_cwf',
    [confKeys.TOKEN]: '99c976f1d1aab575ceaba5749f1c60fa4df039d9',
    [confKeys.WIDGET_ID]: 'kMOxemmxj8_6b214900-460f-11ec-a5ed-258753442c4866',
  },
  pierrefabre: {
    ...defaultPreset,
    [confKeys.LOCALE]: 'en-SG',
    [confKeys.COUNTRY]: 'fr',
    [confKeys.SUB_ID]: 'organic_website_standard',
    [confKeys.TOKEN]: 'f3c3639e0502122ca93017226090373d85e7c05a',
    [confKeys.WIDGET_ID]: '6oVArLjxJG_bf2caba0-1436-11ef-8dcb-c3a0d3d9ef4451',
    [confKeys.DISPLAY_LANGUAGE]: 'en_GB',
  },

  fellowes: {
    ...defaultPreset,
    [confKeys.LOCALE]: 'en-GB',
    [confKeys.COUNTRY]: 'gb',
    [confKeys.SUB_ID]: 'generic_storefinder_demo_gb',
    [confKeys.TOKEN]: '18e832a956420b9e213d973d4a71a8d26cc51c0f',
    [confKeys.WIDGET_ID]: 'kMOxemmxj8_d313b220-908b-11ec-b7d0-c344828c87dd50',
    [confKeys.DISPLAY_LANGUAGE]: 'en_GB',
    [confKeys.DISTANCE_UNIT]: 'automatic',
    [confKeys.SEARCH_RADIUS]: '100',
  },
  '3MLabel': {
    ...defaultPreset,
    [confKeys.LOCALE]: 'zh-CN',
    [confKeys.SUB_ID]: 'sl_tebg_csd_cwf',
    [confKeys.TOKEN]: '99c976f1d1aab575ceaba5749f1c60fa4df039d9',
    [confKeys.WIDGET_ID]: 'Em0AM36x8g_d4381960-7dbd-11ec-a07e-0b407e4c44e6111',
    [confKeys.DISPLAY_LANGUAGE]: 'zh',
  },
  '3MTaiwan': {
    ...defaultPreset,
    [confKeys.LOCALE]: 'zn-TW',
    [confKeys.SUB_ID]: 'sl_cbg_hhac_futuro',
    [confKeys.TOKEN]: '99c976f1d1aab575ceaba5749f1c60fa4df039d9',
    [confKeys.WIDGET_ID]: 'kMOxemmxj8_6b214900-460f-11ec-a5ed-258753442c4866',
    [confKeys.DISPLAY_LANGUAGE]: 'zn-tw',
  },
  '24/7': {
    ...defaultPreset,
    [confKeys.LOCALE]: 'de-DE',
    [confKeys.SUB_ID]: 'website',
    [confKeys.TOKEN]: '33b1e5ed7a828ce6a7e6ad4c80c88dda4c1ec6f0',
    [confKeys.WIDGET_ID]: 'Em0AM36x8g_e329d030-efa6-11eb-b390-ed832e7d3d3831',
    [confKeys.DISPLAY_LANGUAGE]: 'en_GB',
  },
  filters: {
    ...defaultPreset,
    [confKeys.LOCALE]: 'en-GB',
    [confKeys.SUB_ID]: 'generic_storefinder_demo_gb',
    [confKeys.TOKEN]: '18e832a956420b9e213d973d4a71a8d26cc51c0f',
    [confKeys.WIDGET_ID]: 'p5Rx6PAyqe_45c612c0-30b7-11ec-9bb2-e5ac00c75308180',
    [confKeys.DISPLAY_LANGUAGE]: 'id',
  },
  subidtest: {
    ...defaultPreset,
    [confKeys.LOCALE]: 'de-AT',
    // [confKeys.SUB_ID]: 'distributor',
    [confKeys.TOKEN]: '900d6a2c047e3510c6ba951aa5c2059280d82837',
    [confKeys.WIDGET_ID]: 'p5Rx6PAyqe_45c612c0-30b7-11ec-9bb2-e5ac00c75308180',
    [confKeys.DISPLAY_LANGUAGE]: 'de',
    [confKeys.TRACKING_ID]: 'KDAT-Haendlersuche',
  },
  clusters: {
    ...defaultPreset,
    [confKeys.LOCALE]: 'fr-MC',
    // [confKeys.SUB_ID]: 'distributor',
    [confKeys.TOKEN]: '900d6a2c047e3510c6ba951aa5c2059280d82837',
    [confKeys.WIDGET_ID]: 'BnZ4OKX47q_c46d18d0-7494-11ee-b50e-a7e32a38ff3f187',
    [confKeys.DISPLAY_LANGUAGE]: 'fr',
    [confKeys.TRACKING_ID]: 'KDAT-Haendlersuche',
  },
};

export default presets;
