import React, { useCallback, useEffect } from 'react';
import { useHasSearchResults, useResultsDisplayMode } from '../../hooks';
import {
  useTrackingContext,
  useConfigValue,
  useDisplayModesLocalized,
} from '../../hooks';
import { TabsMenu } from '../TabsMenu';

import { confKeys } from '../../constants';

const ccid = 'display-mode-tabs';

export const DisplayModeTabs = () => {
  const trackingContext = useTrackingContext();
  const { track } = trackingContext;

  const mapItems = useConfigValue(confKeys.MAP_VIEW_RETAILERS_PER_PAGE);
  const listItems = useConfigValue(confKeys.LIST_VIEW_RETAILERS_PER_PAGE);
  const hasResults = useHasSearchResults();
  const displayModeOptions = useDisplayModesLocalized(mapItems, listItems);
  const [displayMode, setDisplayMode] = useResultsDisplayMode();

  useEffect(() => {
    setDisplayMode(displayModeOptions[0].id);
  }, [displayModeOptions, setDisplayMode]);

  const getTabPosition = useCallback(
    tab => {
      return displayModeOptions.findIndex(mode => mode.id === tab.id);
    },
    [displayModeOptions]
  );

  const getTabName = useCallback(
    tab => {
      return displayModeOptions.find(mode => mode.id === tab.id).label;
    },
    [displayModeOptions]
  );

  const handleChange = useCallback(
    mode => {
      if (mode && mode.id) {
        setDisplayMode(mode.id);

        track('displayed_tab_click', {
          displayed_tab_type: mode.id,
          displayed_tab_position: getTabPosition(mode),
          displayed_tab_name: getTabName(mode),
        });
      }
    },
    [getTabName, getTabPosition, setDisplayMode, track]
  );

  const hidden = !hasResults || displayModeOptions.length < 2;

  return hidden ? null : (
    <TabsMenu
      ccid={`${ccid}-menu`}
      items={displayModeOptions}
      selectedId={displayMode}
      onChange={handleChange}
    />
  );
};

export default DisplayModeTabs;
