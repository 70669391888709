import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  confKeys,
  textKeys,
  popupButtonModes,
  popupButtonStyles,
} from '../../constants';
import { useTrackingContext } from '../../hooks';
import { useTranslation } from '../../contexts/i18n';
import { TextButton } from '../Button/ButtonStyled';
import { useConfigValue } from '../../contexts/config';

const ccid = 'popup-open-button';

const getStyle = (styles, name) => {
  if (styles && typeof styles[name] === 'string') {
    return styles[name];
  }
};

const getBorderColor = ({ styles, theme }) => {
  return getStyle(styles, popupButtonStyles.BORDER) || theme.colors.highlight;
};

const getBackgroundColor = ({ styles, theme }) => {
  return (
    getStyle(styles, popupButtonStyles.BACKGROUND) || theme.colors.highlight
  );
};

const getTextColor = ({ styles, theme }) => {
  return getStyle(styles, popupButtonStyles.TEXT) || theme.colors.white;
};

const getBackgroundHoverColor = ({ styles, theme }) => {
  return getStyle(styles, popupButtonStyles.TEXT) || theme.colors.white;
};

const getTextHoverColor = ({ styles, theme }) => {
  return (
    getStyle(styles, popupButtonStyles.BACKGROUND) || theme.colors.highlight
  );
};

const InlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin-bottom: 40px;
`;

const StickyContainer = styled.div`
  position: fixed;
  overflow: visible;
  top: 50%;
  left: ${({ theme }) => (theme.rtl ? '-1px' : 'auto')};
  right: ${({ theme }) => (theme.rtl ? 'auto' : '-1px')};
  margin-top: -25px;
  transform: ${({ opened, theme }) =>
    opened ? `translateX(${theme.rtl ? '-100%' : '100%'})` : 'none'};
  transition: transform 0.3s ease;
  transition-delay: ${({ opened }) => (opened ? '1s' : '0s')};
  pointer-events: ${({ opened }) => (opened ? 'none' : 'auto')};
  z-index: 7200;
`;

const DefaultButton = styled(TextButton)`
  background-color: ${getBackgroundColor};
  border-color: ${getBorderColor};
  color: ${getTextColor};
  white-space: nowrap;
  max-width: 100%;
  padding: 0 25px;
  :hover {
    background-color: ${getBackgroundHoverColor};
    color: ${getTextHoverColor};
  }
  :disabled {
    pointer-events: none;
  }
`;

const StickyButton = styled(DefaultButton)`
  min-width: 80px;
  height: 50px;
  border-radius: ${({ theme }) =>
    theme.rtl ? '0 25px 25px 0' : '25px 0 0 25px'};
  border-color: ${getBorderColor};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const PopupOpenButton = ({
  kind = 'inline',
  label,
  onClick,
  opened,
}) => {
  const trackingContext = useTrackingContext();
  const { track } = trackingContext;
  const t = useTranslation();
  const buttonStyles = useConfigValue(confKeys.POPUP_FIND_STORE_BUTTON);
  const buttonLabel = label || t(textKeys.CTA_OPEN_POPUP);
  const buttonKind =
    kind === popupButtonModes.STICKY ? kind : popupButtonModes.INLINE;
  const Container =
    buttonKind === popupButtonModes.STICKY ? StickyContainer : InlineContainer;
  const Button =
    buttonKind === popupButtonModes.STICKY ? StickyButton : DefaultButton;

  useEffect(() => {
    track('lsf_button_impression', {
      button_host: 'commerce_connector',
      button_type: buttonKind,
    });
  }, [buttonKind, track]);

  const handleClick = useCallback(() => {
    track('lsf_button_click', {
      button_host: 'commerce_connector',
      button_type: buttonKind,
    });
    onClick();
  }, [buttonKind, onClick, track]);

  return (
    <Container data-ccid={`${ccid}-container__${buttonKind}`} opened={opened}>
      <Button
        data-ccid={`${ccid}-btn__${buttonKind}`}
        onClick={handleClick}
        styles={buttonStyles}
        aria-haspopup={true}
        aria-expanded={opened}>
        {buttonLabel}
      </Button>
    </Container>
  );
};

PopupOpenButton.propTypes = {
  kind: PropTypes.oneOf(['default', 'sticky']),
  label: PropTypes.string,
  onClick: PropTypes.func,
  opened: PropTypes.bool,
};

export default PopupOpenButton;
