import React from 'react';
import {
  SVGIcon,
  IconPropTypes,
  defaultIconSize,
  defaultIconColor,
} from '../SVGIcon';

const NoResults = ({ color = defaultIconColor, size = defaultIconSize }) => {
  return (
    <SVGIcon
      width={size}
      height={size}
      viewBox="0 0 24 24"
      title="No Results Icon"
      uid="cc-icon-noresults">
      <path
        fill={color}
        d="M23.809,23.232l-6.465-6.464c1.665-1.856,2.625-4.225,2.625-6.72
	c0-2.688-1.023-5.184-2.943-7.104C15.106,1.024,12.608,0,9.92,0C7.232,0,4.8,1.088,2.944,3.008C1.024,4.927,0,7.423,0,10.112
	c0,2.688,1.024,5.184,2.944,7.104s4.417,2.944,7.104,2.944c2.496,0,4.864-0.96,6.72-2.624L23.232,24L23.809,23.232z M3.584,16.447
	c-1.728-1.727-2.687-3.967-2.687-6.399c0-2.432,0.959-4.672,2.687-6.4C5.312,1.92,7.553,0.96,9.984,0.96
	c2.433,0,4.673,0.96,6.399,2.624c1.729,1.729,2.688,3.968,2.688,6.4c0,2.431-0.96,4.672-2.688,6.399
	c-1.727,1.729-3.967,2.625-6.399,2.625C7.553,19.136,5.312,18.175,3.584,16.447z"
      />
      <path
        fill={color}
        d="M13.633,14.08c-0.064,0-0.064,0-0.129-0.064l-3.52-3.52L6.4,14.08
	c0,0-0.064,0.063-0.128,0.063l0,0c-0.064,0-0.064,0-0.127-0.063l-0.385-0.384c-0.063-0.064-0.063-0.129,0-0.192l0.064-0.063
	l3.521-3.52L5.759,6.336c-0.063-0.063-0.063-0.128,0-0.191l0.385-0.385c0.063-0.063,0.127-0.063,0.192,0L9.92,9.344l3.583-3.585
	c0.064-0.063,0.129-0.063,0.192,0l0.384,0.385c0.063,0.063,0.063,0.128,0,0.191L10.496,9.92l3.584,3.583
	c0,0,0.063,0.063,0.063,0.128s0,0.064-0.063,0.129l-0.384,0.383C13.761,14.08,13.696,14.08,13.633,14.08z"
      />
    </SVGIcon>
  );
};

NoResults.propTypes = IconPropTypes;

export default NoResults;
