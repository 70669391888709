import React, { useEffect } from 'react';
import styled from 'styled-components';
import { textKeys } from '../../constants';
import {
  useSearch,
  useTranslation,
  useHasSearchResults,
  useTrackingContext,
  useActiveSearchFilters,
  useVisibleFilters,
} from '../../hooks';
import { NoResultsIcon } from '../Icon/ui';
import { Message } from './Message';

import { groupObjects } from '../../utils/filters';

export const Container = styled.div`
  width: 100%;
  position: relative;
  flex: 1 0 auto;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
  overflow: hidden;
`;

export const Notification = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-size: 16px;
  margin: 20px auto;
`;

export const Notifications = () => {
  const trackingContext = useTrackingContext();
  const { track } = trackingContext;
  const t = useTranslation();
  const { visibleResults, responseError } = useSearch();
  const hasResults = useHasSearchResults();
  const hasNoResults = visibleResults && visibleResults.length === 0;
  const filters = useActiveSearchFilters();
  const visibleFilters = useVisibleFilters();
  useEffect(() => {
    if (hasNoResults) {
      track('widget_other_impression', {
        page_number: 0,
        local_store_list: [],
        selected_filters: groupObjects(filters),
        available_filters: groupObjects(visibleFilters),
      });
    }
  }, [hasNoResults, track, filters, visibleFilters]);

  return hasResults ? null : (
    <>
      {responseError && (
        <Notification data-ccid="api-error-message">
          <Message
            content={t(textKeys.NOTICE_API_ERROR)}
            icon={<NoResultsIcon />}
          />
        </Notification>
      )}
      {hasNoResults && (
        <Notification data-ccid="no-results-message">
          <Message
            content={t(textKeys.NOTICE_NO_RESULTS)}
            icon={<NoResultsIcon />}
          />
        </Notification>
      )}
    </>
  );
};

export default Notifications;
