import { useMemo } from 'react';
import { confKeys, mapListModeTypes } from '../../constants';
import { useConfigValue, useIsMobile, useTranslation } from '../../hooks';
import { getDisplayModeOptionsLocalized } from './utils';

export const useDisplayModesLocalized = () => {
  const t = useTranslation();
  const configDisplayMode = useConfigValue(confKeys.RESULTS_DISPLAY_MODE);
  return useMemo(() => getDisplayModeOptionsLocalized(configDisplayMode, t), [
    configDisplayMode,
    t,
  ]);
};

export const useMapListMode = customMapListMode => {
  const configMapListMode = useConfigValue(confKeys.MAP_LIST_MODE);
  const isMobile = useIsMobile();
  return useMemo(() => {
    const mode = customMapListMode || configMapListMode;
    switch (mode) {
      case mapListModeTypes.LIST_INSIDE:
        return isMobile ? mapListModeTypes.SLIDER_INSIDE : mode;
      case mapListModeTypes.LIST_OUTSIDE:
        return isMobile ? mapListModeTypes.SLIDER_OUTSIDE : mode;
      case mapListModeTypes.SLIDER_INSIDE:
      case mapListModeTypes.SLIDER_OUTSIDE:
        return mode;
      default:
        return isMobile
          ? mapListModeTypes.SLIDER_INSIDE
          : mapListModeTypes.LIST_INSIDE;
    }
  }, [customMapListMode, configMapListMode, isMobile]);
};
