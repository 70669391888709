import styled from 'styled-components';
import { detectingAnim } from '../../styles/animations';
import { breakpointMobile, breakpointSmall } from '../../constants';

const getJustify = (hasAddress, rtl) => {
  if (!hasAddress) return 'center';
  return 'flex-start';
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ hasAddress, theme }) =>
    getJustify(hasAddress, theme.rtl)};
  margin-top: 10px;

  @media (max-width: ${breakpointMobile}px) {
    flex-direction: column;
  }
`;

export const Field = styled.div`
  position: relative;
  flex: 1 1 auto;
  min-width: 400px;
  max-width: 400px;
  @media (max-width: ${breakpointMobile}px) {
    min-width: 350px;
  }
  @media (max-width: ${breakpointSmall}px) {
    min-width: 310px;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  min-width: 40px;
  color: ${({ theme }) => theme.colors.highlight};
`;

export const SearchIconContainer = styled(IconContainer)`
  color: ${({ theme }) => theme.colors.black};
  left: ${({ theme }) => (theme.rtl ? 'auto' : '0')};
  right: ${({ theme }) => (theme.rtl ? '0' : 'auto')};
  width: 44px;
  cursor: ${({ isGeolocationPending = false }) =>
    isGeolocationPending ? 'auto' : 'pointer'};
  & > svg {
    animation: ${({ isGeolocationPending = false }) =>
      isGeolocationPending ? detectingAnim : 'none'};
    animation-timing-function: ease;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
`;

export const IconButtonsContainer = styled(IconContainer)`
  left: ${({ theme }) => (theme.rtl ? '0' : 'auto')};
  right: ${({ theme }) => (theme.rtl ? 'auto' : '0')};
  flex-direction: row;
  flex-wrap: nowrap;
`;
