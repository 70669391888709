import React, { useState, useEffect } from 'react';
import { useMaps } from '../hooks';
import { GoogleMapsApi } from './api';

/**
 * withMapsApi HOC
 * @param Component
 * @returns {{}} The wrapped component
 */
const withMapsApi = function (Component) {
  return props => {
    const { apiKey, country, language } = useMaps();
    const [state, setState] = useState({
      error: null,
      ready: !!GoogleMapsApi.isLoaded,
    });
    const { error, ready } = state;

    useEffect(() => {
      let promise = undefined;
      if (!ready && !error && apiKey && country && language) {
        promise = GoogleMapsApi.init({ apiKey, country, language })
          .then(() => {
            if (promise) {
              setState({
                error: null,
                ready: true,
              });
            }
          })
          .catch(err => {
            if (promise) {
              setState({
                error: err,
                ready: false,
              });
            }
          });
      }
      return () => {
        promise = undefined;
      };
    }, [apiKey, country, language, error, ready]);

    if (!ready) {
      return error ? `${error}` : null;
    }
    return <Component {...props} />;
  };
};

export default withMapsApi;
