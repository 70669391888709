export function isAutoCompleteAvailable() {
  const { google } = window;
  return google && google.maps.places && google.maps.places.Autocomplete;
}

const cleanup = () => {
  document.querySelectorAll('.pac-container').forEach(el => {
    el.style.display = 'none';
  });
};

const FRANCE_OVERSEAS_MAPPING = {
  FC: ['bl', 'mf', 'wf', 'pm', 'pf'],
  FD: ['yt', 're', 'mq', 'gp', 'gf'],
};

const initAutoComplete = (inputElement, options) => {
  const { callback, country, language } = options;
  const { google } = window;
  if (!country || !callback || !isAutoCompleteAvailable()) {
    return false;
  }

  const finalCountry = FRANCE_OVERSEAS_MAPPING[country] ?? country;

  // see https://developers.google.com/places/supported_types#table3
  const autocompleteOptions = {
    language: language,
    componentRestrictions: { country: finalCountry },
    // types: ['(regions)'],
  };

  let autocomplete = new google.maps.places.Autocomplete(
    inputElement,
    autocompleteOptions
  );
  let autocompleteListener = autocomplete.addListener('place_changed', () => {
    callback(autocomplete.getPlace());
  });

  const destroy = () => {
    if (!autocomplete) {
      return;
    }
    autocomplete.unbindAll();
    google.maps.event.removeListener(autocompleteListener);
    autocomplete = null;
    autocompleteListener = null;
    cleanup();
  };

  const setOptions = ({ country, language }) => {
    if (!autocomplete) {
      return;
    }

    const finalCountry = FRANCE_OVERSEAS_MAPPING[country] ?? country;

    autocomplete.setOptions({
      language: language,
      componentRestrictions: { country: finalCountry },
    });
  };

  return {
    destroy,
    setOptions,
  };
};

export default initAutoComplete;
