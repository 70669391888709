import React from 'react';
import withMapsApi from './withMapsApi';

import Supercluster from 'supercluster';

import {
  centerMap,
  getSearchMarkerIcon,
  getMarkerIcon,
  getActiveMarkerIcon,
  fixCenterOffset,
  fitBoundsWithPadding,
  createGeoJSONFromSearchResults,
  getClusterHTML,
  isMobile,
  getMapPadding
} from './mapUtils';

import { SILVER } from './styles';

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMapReady: false,
      mapInstance: null,
      mapMarkers: null,
      mapPadding: getMapPadding(isMobile()),
      searchMarker: null,
      ClusterLib: null,
      center: null,
      coreLib: null,
      supercluster: null
    };

    this.mapContainer = null;
  }

  componentDidMount() {
    const { mapTheme } = this.props;
    this.createMap('baiduContainer', mapTheme);
  }

  shouldComponentUpdate(nextProps) {
    const { markers, activeMarkerId} = this.props;

    if (JSON.stringify(nextProps.markers) !== JSON.stringify(markers)) {
      return true;
    }

    if (nextProps.activeMarkerId !== activeMarkerId) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, nextState) {
    const { markers, activeMarkerId } = this.props;

    if (markers !== prevProps.markers) {
      this.createOverlays(true);
    } else if (activeMarkerId !== prevProps.activeMarkerId) {
      this.activateMarker();
    }
  }

  setContainerRef = (el) => {
    this.mapContainer = el;
  };

  createMap = (container, mapTheme) => {
    const { BMap} = window;
    const { searchLocation } = this.props;

    const mapInstance = new BMap.Map(container);
    const point = new BMap.Point(searchLocation.lng, searchLocation.lat);

    mapInstance.centerAndZoom(point, 12);
    mapInstance.enableScrollWheelZoom();
    mapInstance.disableMapClick();
    mapInstance.disable3DBuilding();
    mapInstance.disableDoubleClickZoom();
    mapInstance.disableContinuousZoom();
    mapInstance.setMaxZoom(20);

    if (mapTheme === 'grey') { // v1 methods (googlelite, grayscale)
      mapInstance.setMapStyleV2({
        styleJson: SILVER,
      });
    }

    this.setState(
      {
        mapInstance: mapInstance,
        isMapReady: true,
        center: searchLocation,
        coreLib: BMap,
      },
      this.createOverlays
    );

    setTimeout(() => {
      this.resetMapBounds();
    }, 700);

    mapInstance.addEventListener('zoomend', () => this.createOverlays(false));
  };

  handleClickCluster = (index, feature) => {
    const { onClickMarker } = this.props;
    const { coreLib, mapInstance } = this.state;

    onClickMarker(null); // nije promena :(

    const zoomTo = index.getClusterExpansionZoom(feature.id);
    const point = new coreLib.Point(feature.geometry.coordinates[0], feature.geometry.coordinates[1]);

    mapInstance.centerAndZoom(point, zoomTo);
  }

  handleClickMapMarker = (feature) => {
    const { onClickMarker } = this.props;
    onClickMarker(feature.properties.id);
  };

  createMapCenter = () => {
    const { coreLib, mapInstance, state } = this.state;
    const { searchLocation } = this.props;

    const centerPoint = new coreLib.Point(searchLocation.lng, searchLocation.lat);
    const Marker = new coreLib.Marker(centerPoint, {
      icon: getSearchMarkerIcon('#000000'),
    });

    mapInstance.addOverlay(Marker);

    return centerPoint;
  }

  createMarkerPoints = () => {
    const { coreLib, mapInstance } = this.state;
    const { markers, markerColor } = this.props;

    const collection = createGeoJSONFromSearchResults(markers);

    const index = new Supercluster({
      log: false,
      radius: 60,
      extent: 512,
      maxZoom: 17
    }).load(collection.features);

    const bounds = mapInstance.getBounds();

    const mapData = {
      bbox: [bounds.getSouthWest().lng, bounds.getSouthWest().lat, bounds.getNorthEast().lng, bounds.getNorthEast().lat],
      zoom: mapInstance.getZoom(),
    };

    const tiles = index.getClusters(mapData.bbox, mapData.zoom);
    const overlays = [];

    tiles.forEach((feature) => {
      let marker = null;

      if (!feature.properties.cluster) {
        marker = this.createMapMarker(feature);
      } else {
        marker = new coreLib.Label(getClusterHTML(feature, markerColor), {
          position: new coreLib.Point(feature.geometry.coordinates[0], feature.geometry.coordinates[1]),
        });
        marker.setZIndex(1);
      }

      mapInstance.addOverlay(marker);

      if (feature.properties.cluster) {
        marker.addEventListener('click', () => this.handleClickCluster(index, feature));
      } else {
        marker.addEventListener('click', () => this.handleClickMapMarker(feature));
      }

      overlays.push({ feature, marker });
    });

    if (!tiles.length) {
      mapInstance.zoomOut()
    }

    this.setState({
      supercluster: index,
    });

    return overlays;
  }

  createOverlays = (resetCenter) => {
    const { mapInstance } = this.state;

    mapInstance.clearOverlays();

    // render center, markers & clusters
    const centerPoint = this.createMapCenter();
    const markerPoints = this.createMarkerPoints();

    this.setState(
      {
        mapMarkers: markerPoints,
        center: centerPoint
      },
      () => {
        if (resetCenter) {
          this.resetMapBounds(true);
        }
      }
    );
  }

  createMapMarker = (data) => {
    const { coreLib } = this.state;
    const { activeMarkerId, markerColor, activeMarkerColor } = this.props;

    let markerIsActive = false;
    let markerIsHighlighted = false;

    const point = new coreLib.Point(data.geometry.coordinates[0], data.geometry.coordinates[1]);
    const marker = new coreLib.Marker(point);

    if (activeMarkerId && data.properties.id === activeMarkerId) {
      marker.setIcon(getActiveMarkerIcon(activeMarkerColor));
      marker.setZIndex(999999);
      markerIsActive = true;
    } else {
      marker.setIcon(getMarkerIcon(markerColor));
      marker.setZIndex(1);
      markerIsActive = false;
    }

    const mapMarker = {
      active: markerIsActive,
      highlighted: markerIsHighlighted,
      id: data.properties.id,
      properties: {
        id: data.properties.id,
      },
      marker: marker,
    };

    mapMarker.marker.addEventListener('click', () => this.handleClickMapMarker(mapMarker));
    return mapMarker.marker;
  };

  getMapCenterOffset = () => {
    const { mapPadding } = this.state;

    const offset = {
      top: !isNaN(mapPadding.top) ? mapPadding.top : 0,
      bottom: !isNaN(mapPadding.bottom) ? mapPadding.bottom : 0,
      left: !isNaN(mapPadding.left) ? mapPadding.left : 0,
      right: !isNaN(mapPadding.right) ? mapPadding.right : 0,
    };

    return {
      x: 0.5 * (offset.left - offset.right),
      y: -0.5 * (offset.top - offset.bottom),
    };
  };

  resetMapBounds = () => {
    const { mapInstance } = this.state;

    const viewport = this.getMapViewport();

    if (viewport.bounds) {
      mapInstance.setViewport(viewport.bounds);
    } else {
      centerMap(mapInstance, viewport);
    }
  };

  getMapViewport = () => {
    const { mapInstance, center, coreLib, mapPadding } = this.state;
    const { markers } = this.props;

    const mapZoom = mapInstance.getZoom();
    const bounds = new coreLib.Bounds(center, center);

    for (let i = 0, marker; (marker = markers[i]); i++) {
      const point = new coreLib.Point(marker.location.lng, marker.location.lat);
      bounds.extend(point);
    }

    fitBoundsWithPadding(mapInstance, bounds, mapPadding);
    const fixedCenter = fixCenterOffset(mapInstance, center, this.getMapCenterOffset());

    return {
      zoom: mapZoom,
      center: fixedCenter,
      bounds: bounds,
    };
  };

  handleMarkerInCluster = () => {
    const { mapMarkers, coreLib, supercluster, mapInstance } = this.state;
    const { activeMarkerId } = this.props;

    const clusters = mapMarkers.filter(({ feature }) => feature.properties.cluster);

    for (let i = 0, cluster; (cluster = clusters[i]); i++) {
      const children = supercluster.getChildren(cluster.feature.id);

      const activeChild = children.find(child => child.properties.id === activeMarkerId);

      if (activeChild) {
        const zoomTo = supercluster.getClusterExpansionZoom(cluster.feature.id)
        const point = new coreLib.Point(activeChild.geometry.coordinates[0], activeChild.geometry.coordinates[1]);

        mapInstance.centerAndZoom(point, zoomTo);
        break;
      }
    }
  }

  handleMarker = () => {
    const { mapMarkers, coreLib, mapInstance } = this.state;
    const { activeMarkerId, activeMarkerColor, markerColor } = this.props;

    mapMarkers.forEach(({ marker, feature }) => {
      const activate = feature.properties.id === activeMarkerId;

      if (feature.properties.cluster) {
        this.handleMarkerInCluster();
      } else {

        if (activate) {
          const point = new coreLib.Point(feature.geometry.coordinates[0], feature.geometry.coordinates[1]);
          marker.setIcon(getActiveMarkerIcon(activeMarkerColor));
          marker.setZIndex(9999);
          mapInstance.panTo(point);
        } else if (!feature.properties.cluster) {
          marker.setIcon(getMarkerIcon(markerColor));
        }
      }


    });
  }

  handleOutOfBoundsMarker = () => {
    const { coreLib, mapInstance } = this.state;
    const { markers, activeMarkerId } = this.props;

    const marker = markers.find(marker => marker.id === activeMarkerId);
    const point = new coreLib.Point(marker.location.lng, marker.location.lat);

    mapInstance.centerAndZoom(point, 14);
    this.createOverlays();
  }

  pointInBounds = () => {
    const { mapInstance, coreLib, mapMarkers, supercluster } = this.state;
    const { activeMarkerId, markers } = this.props;

    const bounds = mapInstance.getBounds();
    const marker = markers.find(marker => marker.id === activeMarkerId);

    if (marker) {
      const point = new coreLib.Point(marker.location.lng, marker.location.lat);

      return bounds.containsPoint(point);
    }

    const cluster = mapMarkers.find(({ feature, marker }) => {
      if (feature.properties.cluster) {
        const children = supercluster.getChildren(feature.id);

        return children.map(child => child.properties.id === activeMarkerId);
      }
    });

    if (cluster) {
      const point = new coreLib.Point(cluster.feature.geometry.coordinates[0], cluster.feature.geometry.coordinates[1]);

      return bounds.containsPoint(point);
    }
  }

  activateMarker = () => {
    const { mapMarkers } = this.state;
    const { activeMarkerId } = this.props;

   if (this.pointInBounds()) {
    const isMarkerRendered = mapMarkers.find(({ feature }) => feature.properties.id === activeMarkerId);

    if (isMarkerRendered) {
      this.handleMarker();
    } else {
      this.handleMarkerInCluster();
    }
   } else {
    this.handleOutOfBoundsMarker();
   }
  };

  render() {
    return (
      <div
        id="baiduContainer"
        style={{
          height: '100%',
        }}
      />
    );
  }
}

export default withMapsApi(Map);
