import React from 'react';
import styled from 'styled-components';
import { confKeys } from '../../constants';
import { useConfigValue } from '../../contexts/config';

const ccid = 'popup-titlebar';

const getThemeColor = ({ themeColor, theme }) => {
  return themeColor || theme.colors.highlight;
};

const Container = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background-color: ${getThemeColor};
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  > img {
    display: block;
    height: 28px;
    width: auto;
  }
`;

export const PopupTitlebar = () => {
  const brandImage = useConfigValue(confKeys.POPUP_BRAND_IMAGE);
  const themeColor = useConfigValue(confKeys.COLOR_SCHEME);
  const showLogo = brandImage !== 'default';
  return (
    <Container data-ccid={`${ccid}`} themeColor={themeColor}>
      {showLogo && <img src={brandImage} alt="brand logo" />}
    </Container>
  );
};

export default PopupTitlebar;
