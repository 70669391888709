import trackEvent from './trackEvent';

export const createEventData = (state, payload) => {
  const { contextData } = state;
  const { eventName, eventData } = payload;

  let data = {
    event_type: eventName,
    ...contextData,
    ...eventData,
  };

  return data;
};

const trackingReducer = (state, action) => {
  const { payload } = action;
  const updatedState = state;

  // const configResultsDisplayMode = useConfigValue();


  trackEvent(payload.eventName, createEventData(updatedState, payload));
  // switch (type) {
  //   case 'UPDATE':
  //     updatedState.contextData = {
  //       ...state.contextData,
  //       ...payload.contextData,
  //     };
  //     break;
  //   case 'TAB':
  //     updatedState.tabData = payload.tab;
  //     break;
  //   case 'TRACK':
  //     updatedState.contextData = {
  //       ...state.contextData,
  //       ...payload.eventData,
  //     }
  //     // updatedState.contextData = {
  //     //   ...state.contextData,
  //     //   ...payload.contextData,
  //     // };
  //     trackEvent(payload.eventName, createEventData(updatedState, payload));
  //     break;
  //   default:
  //     break;
  // }

  return updatedState;
};

export default trackingReducer;
