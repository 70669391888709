import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { textKeys } from '../../constants';
import { useTranslation, useActiveSearchFilters } from '../../hooks';
import { BaseButton } from '../Button';
import { FilterIcon } from '../Icon/ui';
import { confKeys } from '../../constants';
import { useConfigValue } from '../../contexts/config';

const getThemeColor = ({ themeColor, theme }) => {
  return themeColor || theme.colors.highlight;
};

const Button = styled(BaseButton)`
  height: 100%;
  width: 40px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${getThemeColor};
  border-top-left-radius: ${({ theme }) => (theme.rtl ? '20px' : 0)};
  border-bottom-left-radius: ${({ theme }) => (theme.rtl ? '20px' : 0)};
  border-top-right-radius: ${({ theme }) => (theme.rtl ? 0 : '20px')};
  border-bottom-right-radius: ${({ theme }) => (theme.rtl ? 0 : '20px')};
  padding-left: ${({ theme }) => (theme.rtl ? '4px' : 0)};
  padding-right: ${({ theme }) => (theme.rtl ? 0 : '4px')};
  :disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const Badge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: ${({ theme }) => (theme.rtl ? 'auto' : '-4px')};
  left: ${({ theme }) => (theme.rtl ? '-4px' : 'auto')};
  top: -4px;
  height: 20px;
  min-width: 20px;
  background-color: ${({ theme }) => theme.colors.highlightActive};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  font-size: 11px;
  font-family: ${({ theme }) => theme.typography.bold.fontFamily};
  font-weight: ${({ theme }) => theme.typography.bold.fontWeight};
  line-height: 1;
  padding: 0 5px 2px;
`;

const ccid = 'search-filter-button';

export const SearchFilterButton = ({ opened = false, onClick, isDisabled }) => {
  const t = useTranslation();
  const activeFilters = useActiveSearchFilters();
  const badgeText = activeFilters.length > 0 ? `${activeFilters.length}` : '';
  const themeColor = useConfigValue(confKeys.COLOR_SCHEME);

  return (
    <Button
      data-ccid={`${ccid}`}
      title={t(textKeys.LABEL_FILTER)}
      aria-roledescription="Open filters dialog"
      aria-expanded={opened}
      onClick={onClick}
      themeColor={themeColor}
      disabled={isDisabled}>
      <FilterIcon size={20} />
      {!opened && badgeText && (
        <Badge data-ccid={`${ccid}-badge`}>{badgeText}</Badge>
      )}
    </Button>
  );
};

SearchFilterButton.propTypes = {
  opened: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default SearchFilterButton;
