import React from 'react';
import {
  SVGIcon,
  IconPropTypes,
  defaultIconSize,
  defaultIconColor,
} from '../SVGIcon';

const Route = ({ color = defaultIconColor, size = defaultIconSize }) => {
  return (
    <SVGIcon
      width={size}
      height={size}
      viewBox="0 0 24 24"
      title="Route Icon"
      uid="cc-icon-route">
      <path
        fill={color}
        d="M22.504,9.408l-6.943-6.075c-0.494-0.315-1.162-0.17-1.481,0.329c-0.317,0.499-0.171,1.164,0.329,1.481l4.865,4.097H5.911
	C3.755,9.24,2,10.995,2,13.152V21h2.146v-7.848c0-0.974,0.792-1.766,1.766-1.766h13.362l-4.865,4.097
	c-0.5,0.317-0.646,0.981-0.329,1.48c0.205,0.32,0.552,0.497,0.907,0.497c0.196,0,0.397-0.055,0.574-0.168l6.943-6.075
	C22.812,11.021,23,10.68,23,10.313C23,9.946,22.812,9.605,22.504,9.408z"
      />
    </SVGIcon>
  );
};

Route.propTypes = IconPropTypes;

export default Route;
