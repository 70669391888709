import React from 'react';
import PropTypes from 'prop-types';

export const defaultIconSize = 24;
export const defaultIconColor = 'currentcolor';

export const IconPropTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
  secondColor: PropTypes.string,
  pointerEvents: PropTypes.string,
};

export const SVGIconPropTypes = {
  uid: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  viewBox: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  title: PropTypes.string,
  desc: PropTypes.string,
  pointerEvents: PropTypes.string,
};

export const SVGIcon = ({
  width,
  height,
  viewBox = '0 0 24 24',
  title,
  desc,
  children,
  pointerEvents = 'none',
  uid,
  ...otherProps
}) => {
  const titleId = `title-${uid}`;
  const descId = `desc-${uid}`;
  const labelId = desc ? `${titleId} ${descId}` : titleId;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      pointerEvents={pointerEvents}
      aria-describedby={labelId}
      data-ccid={uid}
      aria-hidden="true"
      {...otherProps}>
      {title && <title id={`title-${uid}`}>{title}</title>}
      {desc && <desc id={`desc-${uid}`}>{desc}</desc>}
      {children}
    </svg>
  );
};

SVGIcon.propTypes = SVGIconPropTypes;

export default SVGIcon;
