import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AttrTile, Wrapper } from './LocalStoreCustomAttrStyled';
import { LocalStoreShape } from '../../../api';
import ReactTooltip from 'react-tooltip';

// const ccid = 'localstore-attr';

export const LocalStoreCustomAttr = ({ shop, allowedAttributes = [] }) => {
  const [customFields, setCustomFields] = useState([]);

  // filters out the CustomContactViaWhatsapp custom field to match the behaviour on SWN
  const filteredAllowedAttributes = allowedAttributes.filter(
    attr => attr !== 'CustomContactViaWhatsapp'
  );

  useEffect(() => {
    const { mappedCustomFields } = shop;
    if (mappedCustomFields) {
      setCustomFields(mappedCustomFields);
    }
  }, [shop]);

  const renderAttribute = () => {
    return customFields.map((field, index) => {
      const key = Object.keys(field)[0];
      if (field[key]) {
        return field[key]
          .filter(attr => filteredAllowedAttributes.includes(key))
          .map(attr => (
            <span
              key={shop.Name + shop.Id + attr}
              data-tip={attr}
              data-iscapture="true">
              <ReactTooltip />
              <AttrTile>{attr}</AttrTile>
            </span>
          ));
      }

      return null;
    });
  };

  const maxNumOfItemsPerRow = 5;
  const numOfItemsPerRow = Math.min(customFields.length, maxNumOfItemsPerRow);
  return (
    <Wrapper numOfItemsPerRow={numOfItemsPerRow}>{renderAttribute()}</Wrapper>
  );
};

LocalStoreCustomAttr.propTypes = {
  cropped: PropTypes.bool,
  shop: LocalStoreShape.isRequired,
  allowedAttributes: PropTypes.array,
};

export default LocalStoreCustomAttr;
