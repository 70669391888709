import styled from 'styled-components';

export const Input = styled.input`
  display: block;
  appearance: none;
  background-color: #fff;
  box-shadow: none;
  font-family: inherit;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.border};
  font-family: ${({ theme }) => theme.typography.main.fontFamily};
  font-weight: ${({ theme }) => theme.typography.main.fontWeight};
  font-size: 16px;
  text-align: start;
  width: 100%;
  padding: ${({ theme }) => (theme.rtl ? '0 10px 0 80px' : '0 80px 0 10px')};
  border-radius: 20px;
  height: 40px;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text};
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: ${({ theme }) => theme.colors.border};
  }
  &:disabled {
    pointer-events: none;
  }
  ::placeholder,
  ::-webkit-input-placeholder,
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.text};
    opacity: 0.75;
  }

  ::-ms-clear,
  ::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }
`;

export default Input;
