import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTheme } from '../../theme/hooks';
import { MapMarkerIcon } from '../../../components/Icon';

const FeatureType = PropTypes.shape({
  id: PropTypes.string,
  place_name: PropTypes.string,
});

const FeatureListProps = {
  activeIndex: PropTypes.number,
  data: PropTypes.shape({
    type: PropTypes.string,
    query: PropTypes.arrayOf(PropTypes.string),
    features: PropTypes.arrayOf(FeatureType),
    attribution: PropTypes.string,
  }),
  onSelect: PropTypes.func,
  inputRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) }),
  ]),
};

const ListBox = styled.div.attrs(() => ({ role: 'listbox' }))`
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 4000;
  margin-top: 2px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #c8c8c8;
`;

const ListItem = styled.div.attrs(({ active = false }) => ({
  role: 'listitem',
  'aria-selected': !!active,
}))`
  background-color: ${({ active = false }) => (active ? '#eff0f4' : '#fff')};
  padding: 5px 10px 5px 5px;
  cursor: pointer;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  :not(:last-child) {
    border-bottom: 1px solid #eff0f4;
  }
  & svg {
    flex: 0 0 16px;
    margin-right: 5px;
    opacity: ${({ active = false }) => (active ? 1 : 0.5)};
  }
  &:hover {
    background-color: #eff0f4;
    & svg {
      opacity: 1;
    }
  }
`;

const ListFooter = styled.div`
  padding: 5px;
  font-size: 11px;
  line-height: 13px;
  background-color: #fff;
`;

const FeatureList = ({ data, onSelect, activeIndex }) => {
  const theme = useTheme();
  return (
    <ListBox>
      {data.features.map((item, index) => (
        <ListItem
          key={item.id}
          onClick={() => onSelect(item)}
          active={index === activeIndex}>
          <MapMarkerIcon active size={16} color={theme.colors.highlight} />
          {item.place_name}
        </ListItem>
      ))}
      <ListFooter>&copy; Mapbox https://www.mapbox.com/about/maps</ListFooter>
    </ListBox>
  );
};

FeatureList.propTypes = FeatureListProps;

const AutocompleteList = ({ data, onSelect, inputRef }) => {
  const [activeIndex, setActiveIndex] = React.useState(-1);
  React.useEffect(() => {
    const input = inputRef.current;
    if (data && input) {
      const numItems = data.features.length;
      const keyHandler = event => {
        switch (event.key) {
          case 'ArrowDown':
            setActiveIndex(prevIndex => Math.min(numItems - 1, prevIndex + 1));
            break;
          case 'ArrowUp':
            setActiveIndex(prevIndex => Math.max(0, prevIndex - 1));
            break;
          default:
            break;
        }
      };
      input.addEventListener('keydown', keyHandler);
      return () => {
        input.removeEventListener('keydown', keyHandler);
      };
    }
  }, [data, inputRef]);

  React.useEffect(() => {
    const input = inputRef.current;
    if (input && data && data.features[activeIndex]) {
      input.value = data.features[activeIndex].place_name;
    }
  }, [data, inputRef, activeIndex]);

  return data ? (
    <FeatureList data={data} onSelect={onSelect} activeIndex={activeIndex} />
  ) : null;
};

AutocompleteList.propTypes = FeatureListProps;

export default AutocompleteList;
