import { confKeys } from '../../constants';

export function parseTagAttributes(element) {
  const data = {};
  if (element) {
    Object.values(confKeys).forEach(key => {
      let val;
      if (key === confKeys.T_UTM_TRACKING) {
        const value = element.getAttribute(`data-${key.toLowerCase()}`);
        val = value
          ? JSON.stringify(element.getAttribute(`data-${key.toLowerCase()}`))
          : value;
      } else {
        val = element.getAttribute(`data-${key.toLowerCase()}`);
      }
      if (val) {
        data[key] = val;
      }
    });
  }
  return data;
}

export function validateAttributes(data, requiredFields) {
  if (!data) return false;
  let isValid = true;
  requiredFields.forEach(key => {
    if (typeof data[key] === 'undefined') {
      isValid = false;
    }
  });
  return isValid;
}

const fixLanguage = str => str.toLowerCase();
const fixCountry = str => str.toUpperCase();

const createLocaleData = ({ language, country }) => {
  return {
    language: fixLanguage(language),
    country: fixCountry(country),
    locale: `${fixLanguage(language)}-${fixCountry(country)}`,
  };
};

export function parseLocale(str) {
  if (str) {
    const matches = str.match(/^(\w{2})[-_](\w{2})$/i);
    if (matches) {
      return createLocaleData({
        language: matches[1],
        country: matches[2],
      });
    }
  }
  return false;
}

export function fixLocaleData(data) {
  if (!data.locale && !data.language && !data.country) {
    return data;
  }
  if (data.locale) {
    const localeData = parseLocale(data.locale);
    if (localeData) {
      return {
        ...data,
        ...localeData,
      };
    }
  }
  if (data.country && (data.language || data.displaylanguage)) {
    return {
      ...data,
      ...createLocaleData({
        language: data.language || data.displaylanguage,
        country: data.country,
      }),
    };
  }
  return data;
}

export function fixLocaleString(str) {
  const localeData = parseLocale(str);
  return localeData ? localeData.locale : '';
}

export function fixDisplayLanguage(str) {
  if (!str) {
    return '';
  }
  const parts = str.replace('-', '_').split('_');
  if (parts.length > 1) {
    return `${fixLanguage(parts[0])}_${fixCountry(parts[1])}`;
  }
  return fixLanguage(str);
}

export function fixHexColor(str) {
  if (/^#([0-9a-f]{3}){1,2}$/i.test(str)) {
    return str.toLowerCase();
  }
  if (/^([0-9a-f]{3}){1,2}$/i.test(str)) {
    return `#${str.toLowerCase()}`;
  }
  if (/^([a-z]+)$/.test(str)) {
    return `${str}`;
  }
  return undefined;
}

export const getQueryStringData = (str = window.location.search) => {
  const data = {};
  const items = str.replace(/^\?/, '').split('&');
  items.forEach(item => {
    const pair = item.split('=');
    if (pair.length === 2) {
      data[pair[0]] = decodeURIComponent(pair[1]);
    }
  });
  return data;
};
