import React from 'react';
import {
  SVGIcon,
  IconPropTypes,
  defaultIconSize,
  defaultIconColor,
} from '../SVGIcon';

const CaretUp = ({ color = defaultIconColor, size = defaultIconSize }) => {
  return (
    <SVGIcon
      width={size}
      height={size}
      viewBox="0 0 24 24"
      title="Caret Up Icon"
      uid="cc-icon-caret-up">
      <path fill={color} d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
    </SVGIcon>
  );
};

CaretUp.propTypes = IconPropTypes;

export default CaretUp;
