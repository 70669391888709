import React from 'react';
import {
  SVGIcon,
  IconPropTypes,
  defaultIconSize,
  defaultIconColor,
} from '../SVGIcon';

const Spinner = ({ color = defaultIconColor, size = defaultIconSize }) => {
  return (
    <SVGIcon
      width={size}
      height={size}
      viewBox="0 0 240 240"
      title="Spinner Icon"
      uid="cc-icon-spinner">
      <path
        opacity="0.1"
        fill="none"
        stroke={color}
        strokeWidth="16"
        strokeMiterlimit="10"
        d="M220,120c0,55.229-44.771,100-100,100 S20,175.229,20,120C20,64.772,64.771,20,120,20"
      />
      <path
        fill="none"
        stroke={color}
        strokeWidth="16"
        strokeMiterlimit="10"
        d="M120,20c55.229,0,100,44.772,100,100"
      />
    </SVGIcon>
  );
};

Spinner.propTypes = IconPropTypes;

export default Spinner;
