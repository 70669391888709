import React from 'react';
import {
  SVGIcon,
  IconPropTypes,
  defaultIconColor,
  defaultIconSize,
} from '../SVGIcon';

const WhatsApp = ({ color = defaultIconColor, size = defaultIconSize }) => {
  return (
    <SVGIcon
      width={size}
      height={size}
      viewBox="0 0 16 16"
      title="WhatsApp Icon"
      uid="cc-icon-whatsapp">
      <path
        d="m13.641 2.325c-1.497-1.5-3.488-2.325-5.609-2.325-4.369 0-7.925 3.556-7.925 7.928 0 1.397.366 2.763 1.059 3.963l-1.125 4.109 4.203-1.103c1.159.631 2.463.966 3.787.966h.003c4.369 0 7.928-3.556 7.928-7.928 0-2.119-.825-4.109-2.322-5.609zm-5.607 12.2c-1.184 0-2.344-.319-3.356-.919l-.241-.144-2.494.653.666-2.431-.156-.25c-.663-1.047-1.009-2.259-1.009-3.506 0-3.634 2.956-6.591 6.594-6.591 1.759 0 3.416.688 4.659 1.931 1.244 1.247 1.928 2.9 1.928 4.662-.003 3.637-2.959 6.594-6.591 6.594zm3.613-4.937c-.197-.1-1.172-.578-1.353-.644s-.313-.1-.447.1c-.131.197-.512.644-.628.778-.116.131-.231.15-.428.05s-.838-.309-1.594-.984c-.588-.525-.987-1.175-1.103-1.372s-.013-.306.088-.403c.091-.088.197-.231.297-.347s.131-.197.197-.331c.066-.131.034-.247-.016-.347s-.447-1.075-.609-1.472c-.159-.388-.325-.334-.447-.341-.116-.006-.247-.006-.378-.006s-.347.05-.528.247-.694.678-.694 1.653.709 1.916.809 2.05c.1.131 1.397 2.134 3.384 2.991.472.203.841.325 1.128.419.475.15.906.128 1.247.078.381-.056 1.172-.478 1.338-.941s.166-.859.116-.941c-.047-.088-.178-.137-.378-.238z"
        fill={color}
      />
    </SVGIcon>
  );
};

WhatsApp.propTypes = IconPropTypes;

export default WhatsApp;
