import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { confKeys } from '../../constants';
import { useConfigValue } from '../../contexts/config';

const ccid = 'popup-header';

const Header = styled.header`
  display: ${({ disabled = false }) => (disabled ? 'none' : 'block')};
  flex: 0 0 auto;
  width: 100%;
  margin: 0;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  text-align: ${({ alignment = 'start' }) => alignment};
`;

const Title = styled.h3`
  font-family: ${({ theme }) => theme.typography.bold.fontFamily};
  font-weight: ${({ theme }) => theme.typography.bold.fontWeight};
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  display: block;
  color: inherit;
  text-align: inherit;
`;

const Desc = styled.p`
  font-family: ${({ theme }) => theme.typography.main.fontFamily};
  font-weight: ${({ theme }) => theme.typography.main.fontWeight};
  font-size: 13px;
  line-height: 19px;
  margin: 0;
  padding: 0;
  display: block;
  color: inherit;
  text-align: inherit;
`;

export const PopupHeader = ({ alignment, title, description }) => {
  const headerAlignment = useConfigValue(confKeys.POPUP_HEADER_ALIGNMENT);
  const headerDescription = useConfigValue(confKeys.PRODUCT_DESCRIPTION);
  const headerTitle = useConfigValue(confKeys.PRODUCT_TITLE);
  const disabled = !(headerTitle || headerDescription);

  return (
    <Header
      alignment={alignment || headerAlignment}
      disabled={disabled}
      data-ccid={`${ccid}`}
      aria-hidden={disabled}>
      {headerTitle && <Title data-ccid={`${ccid}-title`}>{headerTitle}</Title>}
      {headerDescription && (
        <Desc data-ccid={`${ccid}-description`}>{headerDescription}</Desc>
      )}
    </Header>
  );
};

PopupHeader.propTypes = {
  alignment: PropTypes.oneOf(['start', 'end', 'center']),
  title: PropTypes.string,
  description: PropTypes.string,
};

export default PopupHeader;
