import React from 'react';
import PropTypes from 'prop-types';

import { ConfigProvider } from './config';
import { I18nProvider } from './i18n';
import { MapsProvider } from './maps';
import { SearchProvider, searchStateShape } from './search';
import { ThemeProvider, themeShape } from './theme';
import { TrackingProvider } from './tracking';

export const ContextWrapper = ({
  children,
  config,
  theme,
  messages,
  initialState,
  confTranslations,
  globalTranslations,
}) => {

  return (
    <ConfigProvider config={config}>
      <I18nProvider
        globalTranslations={globalTranslations}
        confTranslations={confTranslations}
        messages={messages}>
        <ThemeProvider theme={theme}>
          <TrackingProvider>
            <MapsProvider>
              <SearchProvider initialState={initialState}>
                {children}
              </SearchProvider>
            </MapsProvider>
          </TrackingProvider>
        </ThemeProvider>
      </I18nProvider>
    </ConfigProvider>
  );
};

ContextWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.object,
  theme: PropTypes.shape(themeShape),
  messages: PropTypes.object,
  initialState: PropTypes.shape(searchStateShape),
  confTranslations: PropTypes.object,
  globalTranslations: PropTypes.object,
};

export default ContextWrapper;
