import React from 'react';
import ReactDOM from 'react-dom';
import { fixLocaleData } from '../contexts/config';
import { injectThemeColors } from '../contexts/theme';
import { ContextWrapper } from '../contexts/ContextWrapper';
import { App } from '../apps/App';
import { confKeys } from '../constants';

import {
  loadWidgetConfig,
  getSubidId,
  loadConfigurationTranslations,
  loadGlobalTranslations,
  // loadAnonT,
} from './requests';

/**
 * @param container {HTMLDivElement}
 * @param config {*}
 */
const startApp = (
  container,
  config,
  globalTranslations = {},
  confTranslations = {}
) => {
  const { messages, skinColor, skinActiveColor, markerColor } = config;
  const customColors = {
    skinColor,
    skinActiveColor,
    markerColor,
  };
  const theme = injectThemeColors(config.theme || {}, customColors);
  ReactDOM.render(
    <React.StrictMode>
      <ContextWrapper
        config={config}
        globalTranslations={globalTranslations}
        confTranslations={confTranslations}
        theme={theme}
        messages={messages}>
        <App />
      </ContextWrapper>
    </React.StrictMode>,
    container
  );
};

/**
 * @param baseConfig {{}}
 * @param widgetConfig {{}|undefined}
 */
const handleConfigValues = (prop, val) => {
  switch (prop) {
    case 'map_view_retailers_per_page':
    case 'list_view_retailers_per_page':
      return parseInt(val);
    case 'search_radius':
      return parseInt(val);
    case 'find_store_button':
      if (typeof val === 'object') {
        const buttonStyle = {
          ...val,
          background: val.background_color,
          border: val.border_color,
          text: val.label_color,
        };
        delete buttonStyle.background_color;
        delete buttonStyle.border_color;
        delete buttonStyle.label_color;
        return buttonStyle;
      } else {
        return val;
      }
    default:
      return val;
  }
};

const renameProps = name => {
  switch (name) {
    case 'auto-locate_button':
    case 'auto_locate_button':
      return confKeys.AUTO_LOCATE_BTN;
    case 'view_type':
      return confKeys.RESULTS_DISPLAY_MODE;
    case 'now_open_filter':
      return confKeys.FILTER_OPEN_NOW;
    case 'product_description':
      return confKeys.PRODUCT_DESCRIPTION;
    case 'product_description_position':
      return confKeys.POPUP_HEADER_ALIGNMENT;
    case 'product_title':
      return confKeys.PRODUCT_TITLE;
    case 'map_color_scheme':
      return confKeys.MAP_THEME;
    case 'map_pin_color':
      return confKeys.MAP_PIN_COLOR;
    case 'map_view_name':
      return confKeys.MAP_VIEW_NAME;
    case 'list_view_name':
      return confKeys.LIST_VIEW_NAME;
    case 'map_view_retailers_per_page':
      return confKeys.MAP_VIEW_RETAILERS_PER_PAGE;
    case 'list_view_retailers_per_page':
      return confKeys.LIST_VIEW_RETAILERS_PER_PAGE;
    case 'brand_logo':
      return confKeys.POPUP_BRAND_IMAGE;
    case 'list_view_route_to_button':
      return confKeys.LIST_VIEW_ROUTE_TO_BUTTON;
    case 'map_view_route_to_button':
      return confKeys.MAP_VIEW_ROUTE_TO_BUTTON;
    case 'search_radius':
      return confKeys.SEARCH_RADIUS;
    case 'color_scheme':
      return confKeys.COLOR_SCHEME;
    case 'custom_filters':
      return confKeys.FILTER_CUSTOM_FIELDS;
    case 'service_provider':
      return confKeys.MAPS_API_PROVIDER;
    case 'distance_unit':
      return confKeys.DISTANCE_UNIT;
    case 'find_store_button':
      return confKeys.POPUP_FIND_STORE_BUTTON;
    case 'type':
      return confKeys.WIDGET_MODE;
    case 'custom_attributes':
      return confKeys.FILTER_CUSTOM_FIELDS;
    case 'API_key':
      return confKeys.MAPS_API_KEY;
    default:
      return name;
  }
};

const mergeWidgetConfig = (baseConfig, widgetConfig) => {
  if (typeof widgetConfig !== 'object') {
    return baseConfig;
  }
  let parsedConfig = {};

  Object.keys(widgetConfig).forEach(item => {
    if (typeof widgetConfig[item] === 'object') {
      if (item === 'GENERAL') {
        widgetConfig[item]['type'] = widgetConfig.type;
      }
      Object.keys(widgetConfig[item]).forEach(prop => {
        Object.assign(parsedConfig, {
          [renameProps(prop)]: handleConfigValues(
            prop,
            widgetConfig[item][prop]
          ),
        });
      });
    }
  });

  const config = {
    ...baseConfig,
    ...parsedConfig,
  };

  [
    confKeys.TOKEN,
    confKeys.SUB_ID,
    confKeys.LOCALE,
    confKeys.DISPLAY_LANGUAGE,
    confKeys.TRACKING_ID,
  ].forEach(key => {
    config[key] = baseConfig[key] || widgetConfig[key];
  });

  return config;
};

const handlePromise = promise => {
  return promise
    .then(data => [data, undefined])
    .catch(error => Promise.resolve([undefined, error]));
};

const handleConfigurationData = async baseConfig => {
  const { widgetid } = baseConfig;
  let config;

  // --- Load widget configuration
  const [widgetConfig, widgetConfigError] = await handlePromise(
    loadWidgetConfig(widgetid)
  );

  if (widgetConfigError)
    throw Error(
      `Could not load widget config "${widgetid}": ${widgetConfigError}`
    );
  config = mergeWidgetConfig(baseConfig, widgetConfig);

  // --- Load subid id required for widget event tracking
  const [subidID, subidIDError] = await handlePromise(
    getSubidId({
      token: config[confKeys.TOKEN],
      subid: config[confKeys.SUB_ID],
      country: config[confKeys.COUNTRY],
    })
  );

  if (!subidIDError)
    config[confKeys.SUBID_ID] = parseInt(subidID.SubidResult.Id);

  // Load anon token
  // const [anonT, anonTerror] = await handlePromise(loadAnonT());

  // if (!anonTerror) config[confKeys.ANON_T] = anonT.t;

  // Load in-config translations
  const [inConfigTranslations, inConfigTranslationsError] = await handlePromise(
    loadConfigurationTranslations(widgetid)
  );

  if (inConfigTranslationsError) {
    console.warn(
      'In-config translations could not be loaded. Widget will fallback to default ones.'
    );
  }

  // Load global translations
  const [globalTranslations, globalTranslationsError] = await handlePromise(
    loadGlobalTranslations(widgetid)
  );

  if (globalTranslationsError) {
    console.warn(
      'Global translations could not be loaded. Widget will fallback to default ones.'
    );
  }

  return { config, inConfigTranslations, globalTranslations };
};

const initialize = async (container, params) => {
  const baseConfig = fixLocaleData({
    ...params,
  });

  const { token, widgetid } = baseConfig;

  if (!token && !widgetid) {
    throw Error('Missing required params token, widgetid');
  }

  if (!widgetid) {
    if (!token) {
      throw Error('Missing required param token');
    }
    startApp(container, baseConfig);
    return;
  }

  const {
    config,
    globalTranslations,
    inConfigTranslations,
  } = await handleConfigurationData(baseConfig);

  startApp(container, config, globalTranslations, inConfigTranslations);
};

export default initialize;
