/**
 * @param localStore
 * @returns {{City:string,Country:string,County:string,Postcode:string,Street:string,Street2:string}|null}
 */
export const getShopAddress = localStore => {
  const { AddressInfo } = localStore;
  return AddressInfo && AddressInfo[0] ? AddressInfo[0] : null;
};

/**
 * @param localStore
 * @returns {{lat:number,lng:number}|null}
 */
export const getShopLocation = localStore => {
  const { GeoInfo } = localStore;
  return GeoInfo && GeoInfo[0]
    ? {
        lat: parseFloat(GeoInfo[0].Lat),
        lng: parseFloat(GeoInfo[0].Lng),
      }
    : null;
};

/**
 * @param localStore
 * @returns {{Email:string,Fax:string,Phone:string,Website:string}|null}
 */
export const getShopContacts = localStore => {
  const { ContactInfo, CustomFields } = localStore;
  const whatsApp =
    CustomFields && CustomFields.length > 0
      ? CustomFields[0].Key === 'CustomContactViaWhatsapp' &&
        CustomFields[0].Values &&
        CustomFields[0].Values.length > 0
        ? CustomFields[0].Values[0].Value
        : null
      : null;

  if (ContactInfo && ContactInfo.length > 0) {
    const contact = ContactInfo[0];
    if (whatsApp) {
      return { ...contact, WhatsApp: whatsApp };
    }
    return contact;
  }

  return null;
};

/**
 * @param localStore
 * @returns {number|null}
 */
export const getShopDistance = localStore => {
  const { GeoInfo } = localStore;
  return GeoInfo &&
    GeoInfo[0] &&
    GeoInfo[0].Distance &&
    /^[0-9.]+$/.test(GeoInfo[0].Distance)
    ? parseFloat(GeoInfo[0].Distance)
    : null;
};

/**
 * @typedef {object} CustomField
 * @property {string} Key
 * @property {{Value:string,Position:string}[]} Values
 */

/**
 * @typedef {object} CustomFieldsItem
 * @property {CustomField[]} CustomFields
 */

/**
 * @param localStore {CustomFieldsItem}
 * @param customFieldName {string}
 * @returns {object|null}
 */
export const getCustomField = (localStore, customFieldName) => {
  const { CustomFields } = localStore;

  return CustomFields
    ? CustomFields.filter(({ Key }) => Key === customFieldName)[0]
    : null;
};

/**
 * @param localStore {CustomFieldsItem}
 * @param customFieldName {string}
 * @returns {string[]|null}
 */
export const getCustomFieldValues = (localStore, customFieldName) => {
  const customField = getCustomField(localStore, customFieldName);

  if (!customField || !customField.Values) {
    return null;
  }
  let array = [];
  if (Array.isArray(customField.Values)) array = customField.Values;
  else array = Object.values(customField.Values);
  return array.map(({ Value }) => Value).filter(value => !!value);
};

/**
 * @param localStore {CustomFieldsItem}
 * @returns {string|null}
 */
export const getCustomLocalStoreType = localStore => {
  const customStoreTypes = getCustomFieldValues(
    localStore,
    'CustomLocalStoreType'
  );
  if (!customStoreTypes || customStoreTypes.length === 0) {
    return null;
  }
  return customStoreTypes[0];
};
