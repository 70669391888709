export const popupButtonModes = {
  INLINE: 'default',
  STICKY: 'sticky',
};

export const popupButtonStyles = {
  BACKGROUND: 'background',
  BORDER: 'border',
  TEXT: 'text',
};
