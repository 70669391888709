import { useContext, useCallback, useMemo, useEffect, useRef } from 'react';
import { confKeys, displayModes } from '../../constants';
import { searchActionTypes, searchPaginationModes } from './types';
import { SearchContext, SearchDispatchContext } from './provider';
import { detectClientLocation } from '../../utils/geolocation';
import { useConfigValue } from '../config';

export const useSearch = () => useContext(SearchContext);
export const useSearchAction = () => useContext(SearchDispatchContext);

export const useSearchLocation = defaultValue => {
  const { searchLocation } = useSearch();
  return searchLocation || defaultValue;
};

export const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export const useSearchSummary = () => {
  const { summary } = useSearch();
  return summary;
};

export const useDisplayedTab = () => {
  const { resultsDisplayMode } = useSearch();
  return resultsDisplayMode;
};

export const useResultCount = () => {
  const { numResults } = useSearch();
  return numResults || 0;
};

export const useNumOfFilteredResults = () => {
  const { numOfFilteredResults } = useSearch();
  return numOfFilteredResults || 0;
};

export const useSearchResults = () => {
  const { visibleResults } = useSearch();
  return visibleResults;
};

export const useHasSearchResults = () => {
  const visibleResults = useSearchResults();
  return useMemo(() => visibleResults && visibleResults.length > 0, [
    visibleResults,
  ]);
};

// export const useActiveTab = () => {
//   const {}
// }

export const useActiveSearchResult = () => {
  const { selectedResult } = useSearch();
  return selectedResult;
};

export const useSetActiveSearchResult = () => {
  const dispatch = useSearchAction();
  return useCallback(
    id => {
      dispatch({
        type: searchActionTypes.SELECT_RESULT,
        id,
      });
    },
    [dispatch]
  );
};

export const useSearchPages = () => {
  const { currentPage, numPages } = useSearch();
  const dispatch = useSearchAction();
  const setCurrentPage = useCallback(
    (page, paginationMode) => {
      if (page >= 0 && page < numPages) {
        dispatch({
          type: searchActionTypes.SET_PAGE,
          page,
          paginationMode,
        });
      }
    },
    [dispatch, numPages]
  );
  return [currentPage, setCurrentPage, numPages];
};

export const usePaginationMode = () => {
  const { paginationMode } = useSearch();
  const dispatch = useSearchAction();
  const setPaginationMode = useCallback(
    mode => {
      if (Object.values(searchPaginationModes).includes(mode)) {
        dispatch({
          type: searchActionTypes.SET_PAGINATION_MODE,
          paginationMode: mode,
        });
      }
    },
    [dispatch]
  );
  return [paginationMode, setPaginationMode];
};

export const useResultsDisplayMode = () => {
  const configResultsDisplayMode = useConfigValue(
    confKeys.RESULTS_DISPLAY_MODE
  );
  const mapItemsPerPage = useConfigValue(confKeys.MAP_VIEW_RETAILERS_PER_PAGE);
  const listItemsPerPage = useConfigValue(
    confKeys.LIST_VIEW_RETAILERS_PER_PAGE
  );
  const { resultsDisplayMode } = useSearch();

  const dispatch = useSearchAction();
  const setResultsDisplayMode = useCallback(
    mode => {
      if ([displayModes.MAP, displayModes.LIST].indexOf(mode) !== -1) {
        dispatch({
          type: searchActionTypes.SET_RESULTS_DISPLAY_MODE,
          resultsDisplayMode: mode,
          mapItemsPerPage,
          listItemsPerPage,
        });
      }
    },
    [dispatch, listItemsPerPage, mapItemsPerPage]
  );
  return [
    resultsDisplayMode || configResultsDisplayMode,
    setResultsDisplayMode,
  ];
};

export const useActiveSearchFilters = () => {
  const { filters } = useSearch();
  return filters || [];
};

export const useIsGeolocationPending = () => {
  const { detectingLocation } = useSearch();
  return detectingLocation;
};

export const useIsSearchPending = () => {
  const { isPending } = useSearch();
  return isPending;
};

export const useCustomFields = () => {
  const { customFields } = useSearch();
  return customFields;
};

export const useDetectLocation = () => {
  const { detectingLocation } = useSearch();
  const dispatch = useSearchAction();

  return useCallback(() => {
    if (detectingLocation) {
      return;
    }

    dispatch({
      type: searchActionTypes.DETECT_LOCATION,
      pending: true,
    });

    detectClientLocation()
      .then(({ lat, lng }) => {
        dispatch({
          type: searchActionTypes.SEARCH_LOCATION,
          searchLocation: {
            lat,
            lng,
          },
        });
      })
      .catch(error => {
        dispatch({
          type: searchActionTypes.LOCATION_DETECTION_ERROR,
          error,
        });
      });
  }, [dispatch, detectingLocation]);
};

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = fun => useEffect(fun, []);

export const useVisibleFilters = () => {
  const { visibleFilters } = useSearch();
  return visibleFilters || [];
};
