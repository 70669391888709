import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { LocalStoresResultShape } from '../../../api';
import { Row, Spacer } from '../../Layout';
import {
  LocalStoreAddress,
  LocalStoreContacts,
  LocalStoreContactIcons,
  LocalStoreName,
  LocalStoreOpening,
  LocalStorePin,
  LocalStoreCustomAttr,
} from '..';
import {
  Card,
  CardTabSide,
  DetailsContainer,
  LocationContainer,
  ContactIconsContainer,
  ChevronIconContainer,
} from './Card';

import { CaretDownIcon } from '../../Icon';

import { confKeys } from '../../../constants';
import { useConfigValue } from '../../../contexts/config';

const ccid = 'localstore-card';

export const contactsDisplayModeTypes = {
  LIST: 'list',
  ICONS: 'icons',
};

export const LocalStoreCard = ({
  contactsDisplayMode = 'list',
  expandOpeningOnSelect = false,
  hideContent = false,
  item = {},
  onClickItem,
  selected,
}) => {
  const { id, shop } = item;

  const handleClickCard = useCallback(
    event => {
      if (onClickItem && id) {
        onClickItem(event, id);
      }
    },
    [id, onClickItem]
  );

  const handleKeyDown = useCallback(
    event => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        onClickItem(event, id);
      }
    },
    [id, onClickItem]
  );

  const isActive = !!selected;
  const hasOpeningHours =
    shop && shop.OpeningHours && shop.OpeningHours.length > 0;
  const showContactIcons =
    contactsDisplayMode === contactsDisplayModeTypes.ICONS;
  const themeColor = useConfigValue(confKeys.COLOR_SCHEME);
  const customAttributes = useConfigValue(confKeys.FILTER_CUSTOM_FIELDS);
  const showWhatsApp = useConfigValue(confKeys.SHOW_WHATSAPP);
  const attributesList = customAttributes
    ? customAttributes.custom_attributes_selection
    : [];

  return (
    <Card
      data-ccid={ccid}
      data-itemid={id}
      onClick={handleClickCard}
      onKeyDown={handleKeyDown}
      data-clickout-type="store_card_click"
      tabIndex="0"
      active={selected}>
      {!hideContent && shop && (
        <Row data-ccid={`${ccid}-inner`}>
          <LocationContainer data-ccid={`${ccid}-location-container`}>
            <LocalStorePin
              data-clickout-type="store_distance_click"
              shop={shop}
              active={selected}
            />
          </LocationContainer>
          <DetailsContainer data-ccid={`${ccid}-details-container`}>
            <LocalStoreName
              data-clickout-type="store_name_click"
              shop={shop}
              cropped={!isActive}
            />
            <LocalStoreCustomAttr
              shop={shop}
              allowedAttributes={attributesList}
            />
            <LocalStoreAddress
              data-clickout-type="address_click"
              shop={shop}
              cropped={!isActive}
            />
            {hasOpeningHours && (
              <LocalStoreOpening
                shop={shop}
                expanded={isActive && expandOpeningOnSelect}
              />
            )}
            {isActive && !showContactIcons && (
              <LocalStoreContacts
                showWhatsApp={showWhatsApp}
                shop={shop}
                viewType={'map'}
              />
            )}
          </DetailsContainer>
          {isActive && showContactIcons && (
            <ContactIconsContainer>
              <LocalStoreContactIcons shop={shop} />
            </ContactIconsContainer>
          )}
          {!showContactIcons && <Spacer size={20} />}
        </Row>
      )}
      {!hideContent && (
        <CardTabSide themeColor={themeColor} active={selected} />
      )}
      {!isActive && (
        <ChevronIconContainer>
          <CaretDownIcon />
          <CaretDownIcon />
        </ChevronIconContainer>
      )}
    </Card>
  );
};

LocalStoreCard.propTypes = {
  index: PropTypes.number,
  item: LocalStoresResultShape.isRequired,
  onClickItem: PropTypes.func,
  selected: PropTypes.bool,
  expandOpeningOnSelect: PropTypes.bool,
  hideContent: PropTypes.bool,
  contactsDisplayMode: PropTypes.oneOf(['list', 'icons']),
};

export default LocalStoreCard;
