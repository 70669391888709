export const rtlLanguages = [
  'ar',
  'dv',
  'fa',
  'ha',
  'he',
  'ku',
  'ps',
  'ur',
  'yi',
];
