import React from 'react';
import {
  SVGIcon,
  IconPropTypes,
  defaultIconSize,
  defaultIconColor,
} from '../SVGIcon';

const MapMarkerDot = ({ color = defaultIconColor, size = defaultIconSize }) => {
  return (
    <SVGIcon
      width={size}
      height={size}
      viewBox="0 0 24 24"
      title="Map Marker Dot Icon"
      uid="cc-icon-map-marker-dot">
      <path
        fill={color}
        stroke={color}
        strokeWidth={2}
        d="M11.997,12c-1.214,0-2.2-0.986-2.2-2.201c0-1.215,0.986-2.2,2.2-2.2
	c1.216,0,2.198,0.985,2.198,2.2C14.195,11.014,13.213,12,11.997,12z"
      />
    </SVGIcon>
  );
};

MapMarkerDot.propTypes = IconPropTypes;

export default MapMarkerDot;
