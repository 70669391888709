import React from 'react';
import {
  SVGIcon,
  IconPropTypes,
  defaultIconSize,
  defaultIconColor,
} from '../SVGIcon';

const AutoLocateCircle = ({
  color = defaultIconColor,
  size = defaultIconSize,
}) => {
  return (
    <SVGIcon
      width={size}
      height={size}
      viewBox="0 0 24 24"
      title="Detect Location Icon"
      uid="cc-icon-autolocate-circle">
      <path
        fill={color}
        d="M12,23C5.913,23,1,18.088,1,12C1,5.913,5.913,1,12,1c6.088,0,11,4.913,11,11
	C23,18.088,18.088,23,12,23z M12,1.801C6.393,1.801,1.801,6.393,1.801,12c0,5.607,4.592,10.199,10.199,10.199
	c5.607,0,10.199-4.592,10.199-10.199C22.199,6.393,17.607,1.801,12,1.801z"
      />
      <path
        fill={color}
        d="M12,17.126h-0.054c-0.16-0.054-0.267-0.159-0.267-0.321v-4.484H7.194
	c-0.16,0-0.266-0.107-0.319-0.267c-0.054-0.161,0.053-0.268,0.159-0.375l9.665-4.805c0.106-0.054,0.269-0.054,0.374,0.053
	c0.106,0.106,0.106,0.266,0.054,0.373l-4.805,9.666C12.214,17.072,12.107,17.126,12,17.126z M8.529,11.679H12
	c0.161,0,0.321,0.16,0.321,0.321v3.47l3.792-7.582L8.529,11.679z"
      />
    </SVGIcon>
  );
};

AutoLocateCircle.propTypes = IconPropTypes;

export default AutoLocateCircle;
