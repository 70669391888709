import { parseTagAttributes, getQueryStringData } from '../contexts/config';
import { confKeys } from '../constants';
import initialize from './main';
import { createSessId } from './../contexts/tracking/utils';

const bundleName = 'storefinder';
const containerId = 'cc-storefinder-root';

/**
 * @returns {HTMLScriptElement|null}
 */
const findScriptElement = () => {
  return document.querySelector(`script[src$="${bundleName}.min.js"]`);
};

const extractTrackingData = query => {
  const config = {};

  Object.keys(query)
    .filter(key => query[key] && query[key].trim().length > 0)
    .forEach(key => {
      const value = query[key].trim();
      switch (key) {
        case confKeys.GTM_TRACKING:
          config[key] = value;
          break;
        case confKeys.T_UTM_SOURCE:
          config[key] = value;
          break;
        case confKeys.T_UTM_TERM:
          config[key] = value;
          break;
        case confKeys.T_UTM_MEDIUM:
          config[key] = value;
          break;
        case confKeys.T_UTM_CONTENT:
          config[key] = value;
          break;
        case confKeys.T_UTM_CAMPAIGN:
          config[key] = value;
          break;
        case confKeys.T_UTM_TRACKING:
          config[key] = JSON.stringify(value);
          break;
        default:
          break;
      }
    });

  return config;
};

const getData = () => {
  const queryParams = getQueryStringData();
  const tracking = extractTrackingData(queryParams);
  const params = parseTagAttributes(scriptElement);

  return {
    ...tracking,
    ...params,
    viewid: createSessId(),
  };
};

const initializeMultiple = () => {
  const data = getData();
  const widgets = JSON.parse(data.widgets);
  widgets.forEach(widget => {
    const { target } = widget;
    const container = document.getElementById(target);

    const widgetData = {
      ...data,
      ...widget,
      target,
    };

    initialize(container, widgetData);
  });
};

const scriptElement = findScriptElement();
if (scriptElement) {
  let container = document.getElementById(containerId);
  const data = getData();
  if (data.ismultiple === 'true') {
    initializeMultiple(data);
  } else {
    if (!container) {
      container = document.createElement('div');
      container.id = containerId;
      scriptElement.parentElement.appendChild(container);
    }
    initialize(container, data);
  }
}
