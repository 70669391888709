import React from 'react';
import { SearchInputPropTypes } from '../types';
import { useAutocomplete, useReverseGeocoding } from './hooks';
import AutocompleteList from './AutocompleteList';
const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

const SearchInput = ({
    as: InputComponent = 'input',
    className,
    placeholder = '',
    resetKey = 0,
    value,
    style,
    searchLocation,
    onFocus,
    onBlur,
    onChange,
    onSubmit,
    onLocationChange,
    onAutoDetectAddress,
    ...otherProps
}) => {
  // const getLocationByAddress = useForwardGeocoding();
  const getAddressByLocation = useReverseGeocoding();
  const getAutocompleteLocations = useAutocomplete();
  const [locationData, setLocationData] = React.useState(null);
  const [autocompleteResult, setAutocompleteResult] = React.useState(null);
  const inputRef = React.useRef(null);

  // if now is in composition session
  let isOnComposition = false;

  const handleComposition = (e) => {
    if (e.type === 'compositionend') {
     //composition is end
      isOnComposition = false;

      if (e.target instanceof HTMLInputElement && !isOnComposition && isChrome) {
        handleChange(e)
      }
    } else {
     //in composition
      isOnComposition = true;
    }
  }

  React.useEffect(() => {
    if (locationData) {
      onLocationChange && onLocationChange(locationData);
    }
  }, [locationData, onLocationChange]);

    React.useEffect(() => {
        const input = inputRef.current;
        if (input) {
          if (searchLocation) {
            if (searchLocation.address) {
              input.value = searchLocation.address;
            } else if (searchLocation.lat && searchLocation.lng) {
              // Location was auto-detected
              // -> get address and inject into searchLocation silently
              // -> then call onAutoDetectAddress for tracking
              getAddressByLocation(searchLocation).then((addressData) => {
                const input = inputRef.current;
                if (input && addressData && addressData.address) {
                  searchLocation.address = addressData.address;
                  searchLocation.city = addressData.city || '';
                  searchLocation.country = addressData.country || '';
                  searchLocation.zip = addressData.zip || '';
                  input.value = searchLocation.address;
                  if (onAutoDetectAddress) {
                    onAutoDetectAddress(addressData);
                  }
                }
              });
            }
          } else {
            input.value = '';
            input.blur();
          }
        }
    }, [inputRef, searchLocation, onAutoDetectAddress, getAddressByLocation]);

    React.useEffect(() => {
      const input = inputRef.current;
      let timeout;

      if (input && resetKey > 0) {
        input.value = '';
        setAutocompleteResult(null);
        timeout = setTimeout(() => {
          input.focus();
        }, 10);
      }
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }, [inputRef, resetKey]);

    const handleSelectAutocomplete = React.useCallback((item) => {
      // example: 北京市门头沟区阜石路2号
      if (item) {
        setLocationData({
          lat: item.location.lat,
          lng: item.location.lng,
          address: item.address,
        });
      }
      setAutocompleteResult(null);
    }, []);

    const filterResults = (data) => {
      data.result = data.result.filter(item => item.location);
      return data
    }

    const handleChange = React.useCallback(
      (event) => {
        if (event.target instanceof HTMLInputElement && !isOnComposition) {
          if (event.target.value.length > 1) {
            getAutocompleteLocations(event.target.value).then((result) => {
              if (result && result.result && result.result.length > 0) {
                setAutocompleteResult(filterResults(result));
              } else {
                setAutocompleteResult(null);
              }
            });
          } else {
            setAutocompleteResult(null);
          }
          onChange && onChange(event);
        }
      },
      [getAutocompleteLocations, isOnComposition, onChange]
    );

    const handleBlur = React.useCallback(
      (event) => {
        if (searchLocation && searchLocation.address && event.target.value === '') {
          if (Date.now() - resetKey > 100) {
            event.target.value = searchLocation.address;
          }
        }
        setTimeout(() => {
          setAutocompleteResult(null);
        }, 100);
        onBlur && onBlur(event);
      },
      [searchLocation, resetKey, onBlur]
    );

    const handleKeyDown = (event) => {
      if (event.target instanceof HTMLInputElement && !isOnComposition) {
        if (event.key === 'Enter' || event.keyCode === 13) {
          const queryText = event.target.value;

          if (queryText.length > 2) {
            const item = autocompleteResult.result.find((item) => {
              if (item.address.length) return item.address === queryText;
              if (!item.address.length) return item.name === queryText;

              return null;
            });

            if (item) {
              setLocationData({
                lat: item.location.lat,
                lng: item.location.lng,
                address: item.address.length ? item.address : item.name,
              });

              const input = inputRef.current;
              if (input && item) {
                if (item.lat && item.lng && item.address) {
                  setLocationData(item);
                  input.value = item.address.length ? item.address : item.name;
                  input.blur();
                }
              }
            }
          }
          onSubmit && onSubmit(queryText);
          setTimeout(() => {
              setAutocompleteResult(null);
          }, 100);
        }
      }
    };

    return (
      <React.Fragment>
        <InputComponent
            className={className}
            style={style}
            type="search"
            autoComplete="off"
            spellCheck="false"
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            onFocus={onFocus}
            onBlur={handleBlur}
            onCompositionStart={handleComposition}
            onCompositionUpdate={handleComposition}
            onCompositionEnd={handleComposition}
            onChange={handleChange}
            ref={inputRef}
            {...otherProps}
        />
        {autocompleteResult && (
          <AutocompleteList inputRef={inputRef} data={autocompleteResult} onSelect={handleSelectAutocomplete} />
        )}
      </React.Fragment>
    );
};

SearchInput.propTypes = SearchInputPropTypes;

export default SearchInput;
