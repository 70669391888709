// globalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .srOnly {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

    /* Custom properties */
  :root {
    --tooltip-text-color: white;
    --tooltip-background-color: black;
    --tooltip-margin: 30px;
    --tooltip-arrow-size: 6px;
  }

  /* Wrapping */
  .Tooltip-Wrapper {
    display: inline-block;
    position: relative;
  }

  /* Absolute positioning */
  .Tooltip-Tip {
    position: fixed;
    border-radius: 4px;
    transform: translateX(-50%);
    padding: 6px;
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    font-size: 12px;
    font-family: sans-serif;
    line-height: 1;
    z-index: 10000;
    white-space: nowrap;
  }

  /* CSS border triangles */
  .Tooltip-Tip::before {
    content: " ";
    left: 40%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: 10%;
  }

  /* Absolute positioning */
  .Tooltip-Tip.top {
    /* top: calc(var(--tooltip-margin) * -1); */
  }
  /* CSS border triangles */
  .Tooltip-Tip.top::before {
    top: 100%;
    border-top-color: var(--tooltip-background-color);
  }

  /* Absolute positioning */
  .Tooltip-Tip.right {
    left: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .Tooltip-Tip.right::before {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--tooltip-background-color);
  }

  /* Absolute positioning */
  .Tooltip-Tip.bottom {
    bottom: calc(var(--tooltip-margin) * -1);
  }
  /* CSS border triangles */
  .Tooltip-Tip.bottom::before {
    bottom: 100%;
    border-bottom-color: var(--tooltip-background-color);
  }

  /* Absolute positioning */
  .Tooltip-Tip.left {
    left: auto;
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .Tooltip-Tip.left::before {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--tooltip-background-color);
  }

  .marker-cluster {
    position: absolute;
    z-index: 1999;
    background-clip: padding-box;
    border-radius: 20px;
    cursor: pointer;
    border: 1px solid #fff;

    &:hover {
      opacity: 0.8;
    }
  }

  .marker-cluster div {
    width: 25px;
    height: 25px;
    margin: 5px;
    text-align: center;
    border-radius: 15px;
  }

  .marker-cluster span {
    color: white;
    line-height: 25px;
    font-size: 13px;
  }
`;

export default GlobalStyle;
