// svgs
const searchMarkerPath =
  '<path d="M17,2C8.716,2,2,8.716,2,17s6.716,15,15,15s15-6.716,15-15 S25.284,2,17,2z M17,19c-1.104,0-2-0.896-2-2s0.896-2,2-2s2,0.896,2,2S18.104,19,17,19z" fill="000000" stroke="#ffffff" stroke-width="2"></path>';

const getSVG = (color) => {
  return `<path d="'M20,0C8.854,0,0,8.956,0,20c0,14.877,20,30,20,30s20-15.123,20-30C40,8.804,31.146,0,20,0z'" fill="${color}" stroke="#ffffff" stroke-width="4"></path>`;
};

const getSVGActive = (color) => {
  return `<path d="'M21,1C9.854,1,1,9.956,1,21c0,14.877,20,30,20,30 s20-15.123,20-30C41,9.804,32.146,1,21,1z M21,26c-2.761,0-5-2.239-5-5s2.239-5,5-5c2.76,0,5,2.239,5,5S23.76,26,21,26z'" fill="${color}" stroke="#ffffff" stroke-width="4"></path>`;
};

export const centerMap = (map, params) => {
  const { bounds, center, zoom } = params;

  if (!map) return;
  if (center) {
    map.panTo(center);
  }
  if (zoom) {
    map.setZoom(zoom);
  }
  if (bounds) {
      // map.fitBounds(bounds);
  }
}

export const getMapPadding = (isMobile) => {
  if (isMobile) {
    return {
      top: 0,
      bottom: 260,
      left: 0,
      right: 0,
    };
  }

  return {
    left: 420,
    top: 0,
    right: 0,
    bottom: 0
  }
}

export const fixCenterOffset = (map, latlng, offset) => {
  const { BMap } = window;
  const scale = Math.pow(2, map.getZoom());

  const offsetX = !isNaN(offset.x) ? offset.x : 0;
  const offsetY = !isNaN(offset.y) ? offset.y : 0;

  const pixelOffset = new BMap.Point(
    offsetX / scale || 0,
    offsetY / scale || 0
  );

  const worldCoordinateNewCenter = new BMap.Point(
    latlng.lng - pixelOffset.lng,
    latlng.lat + pixelOffset.lat
  );

  return worldCoordinateNewCenter;
}

export const fitBoundsWithPadding = (map, bounds, paddingXY) => {
  const { BMap } = window;
  const fZoom = Math.pow(2, map.getZoom());

  const paddings = getMapPadding(isMobile());

  ['left', 'top', 'right', 'bottom'].forEach((key) => {
    if (!isNaN(paddingXY[key])) {
      paddings[key] = paddingXY[key];
    }
  });


  const currentPointSW = bounds.getSouthWest();
  // const currentPointNE = bounds.getNorthEast();

  const paddingPointSW = new BMap.Point(
    typeof paddings.left == 'number' ? paddings.left / fZoom : 0,
    typeof paddings.bottom == 'number' ? paddings.bottom / fZoom : 0
  );

  const lng = currentPointSW.lng - paddingPointSW.lng;
  const lat = currentPointSW.lat - paddingPointSW.lat;
  const newPoint = new BMap.Point(lng, lat);

  bounds.extend(newPoint);

  return bounds;
}

export const getSearchMarkerIcon = (color) => {
  const { BMap } = window;
  const centerIcon = new BMap.Symbol(searchMarkerPath, {
    rotation: 0,
    fillColor: color,
    fillOpacity: 1,
    scale: 0.8,
  });

  return centerIcon;
};

export const getMarkerIcon = (color) => {
  const { BMap } = window;

  const marker = new BMap.Symbol(getSVG(color), {
    rotation: 0,
    fillColor: color,
    fillOpacity: 1,
    scale: 0.5,
    strokeWeight: 1,
    strokeColor: '#ffffff',
    anchor: new BMap.Size(20, 48)
  });

  return marker;
};

export const getHighlightedMarkerIcon = (color) => {
  return getMarkerIcon(color);
};

export const getActiveMarkerIcon = (color) => {
  const { BMap } = window;

  const marker = new BMap.Symbol(getSVGActive(color), {
    rotation: 0,
    fillColor: color,
    fillOpacity: 1,
    scale: 1,
    strokeWeight: 1,
    strokeColor: '#ffffff',
    anchor: new BMap.Size(15, 60)
  });

  return marker;
};


export const createGeoJSONFromSearchResults = (markers = []) => {

  return {
    type: 'FeatureCollection',
    features: markers.map(marker => ({
      type: 'Feature',
      properties: {
        id: marker.id,
      },
      geometry: {
        type: 'Point',
        coordinates: [marker.location.lng, marker.location.lat],
      },
    })),
  };
};

export const getClusterHTML = (feature, color = '#000000') => {
  return (
    `<div class="marker-cluster" style="background-color: ${color}">
      <div>
        <span>${feature.properties.point_count_abbreviated}</span>
      </div>
    </div>`
  )
}

export const isMobile = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return true;
  }
  else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return true;
  }
  return false;
};
