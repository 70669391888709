/**
 * @name MarkerClusterer for Google Maps v3
 * @author Luke Mahe
 * @fileoverview
 * The library creates and manages per-zoom-level clusters for large amounts of
 * markers.
 */

/**
 * @license
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

class MarkerClusterer {
  constructor(map, opt_markers, opt_options) {
    const { google } = window;
    const options = opt_options || {};

    this.extend(MarkerClusterer, google.maps.OverlayView);
    this.map_ = map;
    this.markers_ = [];
    this.clusters_ = [];
    this.sizes = [53, 56, 66, 78, 90];
    this.styles_ = [];
    this.ready_ = false;
    this.gridSize_ = options['gridSize'] || 60;
    this.minClusterSize_ = options['minimumClusterSize'] || 2;
    this.maxZoom_ = options['maxZoom'] || null;
    this.styles_ = options['styles'] || [];
    this.zoomOnClick_ =
      options['zoomOnClick'] !== undefined ? options['zoomOnClick'] : true;
    this.averageCenter_ =
      options['averageCenter'] !== undefined ? options['averageCenter'] : false;

    this.setupStyles_();
    this.setMap(map);

    this.prevZoom_ = this.map_.getZoom();

    google.maps.event.addListener(this.map_, 'zoom_changed', () => {
      // Determines maps type and prevent illegal zoom levels
      let zoom = this.map_.getZoom();
      const minZoom = this.map_.minZoom || 0;
      const maxZoom = Math.min(
        this.map_.maxZoom || 100,
        this.map_.mapTypes[this.map_.getMapTypeId()].maxZoom
      );
      zoom = Math.min(Math.max(zoom, minZoom), maxZoom);

      if (this.prevZoom_ !== zoom) {
        this.prevZoom_ = zoom;
        this.resetViewport();
      }
    });

    google.maps.event.addListener(this.map_, 'idle', () => {
      this.redraw();
    });

    if (
      opt_markers &&
      (opt_markers.length || Object.keys(opt_markers).length)
    ) {
      this.addMarkers(opt_markers, false);
    }
  }

  /**
   * Extends a objects prototype by anothers.
   *
   * @param {Object} obj1 The object to be extended.
   * @param {Object} obj2 The object to extend with.
   * @return {Object} The new extended object.
   * @ignore
   */
  extend(obj1, obj2) {
    return function (object) {
      for (var property in object.prototype) {
        this.prototype[property] = object.prototype[property];
      }
      return this;
    }.apply(obj1, [obj2]);
  }

  /**
   * Implementaion of the interface method.
   * @ignore
   */
  onAdd() {
    this.setReady_(true);
  }

  /**
   * Implementaion of the interface method.
   * @ignore
   */
  draw() {}

  /**
   * Sets up the styles object.
   *
   * @private
   */
  setupStyles_() {
    if (this.styles_.length) {
      return;
    }
    let size;
    for (var i = 0; i < this.sizes.length; i++) {
      size = this.sizes[i];
      this.styles_.push({
        height: size,
        width: size,
      });
    }
  }

  /**
   *  Fit the maps to the bounds of the markers in the clusterer.
   */
  fitMapToMarkers() {
    const { google } = window;
    const markers = this.getMarkers();
    const bounds = new google.maps.LatLngBounds();
    let i, marker;
    for (i = 0; i < markers.length; i++) {
      marker = markers[i];
      bounds.extend(marker.getPosition());
    }
    this.map_.fitBounds(bounds);
  }

  /**
   *  Sets the styles.
   *
   *  @param {Object} styles The style to set.
   */
  setStyles(styles) {
    this.styles_ = styles;
  }

  /**
   *  Gets the styles.
   *
   *  @return {Object} The styles object.
   */
  getStyles() {
    return this.styles_;
  }

  /**
   * Whether zoom on click is set.
   *
   * @return {boolean} True if zoomOnClick_ is set.
   */
  isZoomOnClick() {
    return this.zoomOnClick_;
  }

  /**
   * Whether average center is set.
   *
   * @return {boolean} True if averageCenter_ is set.
   */
  isAverageCenter() {
    return this.averageCenter_;
  }

  /**
   *  Returns the array of markers in the clusterer.
   *
   *  @return {Array.<google.maps.Marker>} The markers.
   */
  getMarkers() {
    return this.markers_;
  }

  /**
   *  Returns the number of markers in the clusterer
   *
   *  @return {Number} The number of markers.
   */
  getTotalMarkers() {
    return this.markers_.length;
  }

  /**
   *  Sets the max zoom for the clusterer.
   *
   *  @param {number} maxZoom The max zoom level.
   */
  setMaxZoom(maxZoom) {
    this.maxZoom_ = maxZoom;
  }

  /**
   *  Gets the max zoom for the clusterer.
   *
   *  @return {number} The max zoom level.
   */
  getMaxZoom() {
    return this.maxZoom_;
  }

  /**
   *  The function for calculating the cluster icon image.
   *
   *  @param {Array.<google.maps.Marker>} markers The markers in the clusterer.
   *  @param {number} numStyles The number of styles available.
   *  @return {Object} A object properties: 'text' (string) and 'index' (number).
   *  @private
   */
  calculator_(markers, numStyles) {
    const count = markers.length;
    let dv = count;
    let index = 0;
    while (dv !== 0) {
      dv = parseInt(dv / 10, 10);
      index++;
    }
    index = Math.min(index, numStyles);
    return {
      text: count,
      index: index,
    };
  }

  /**
   * Set the calculator function.
   *
   * @param {function(Array, number)} calculator The function to set as the
   *     calculator. The function should return a object properties:
   *     'text' (string) and 'index' (number).
   *
   */
  setCalculator(calculator) {
    this.calculator_ = calculator;
  }

  /**
   * Get the calculator function.
   *
   * @return {function(Array, number)} the calculator function.
   */
  getCalculator() {
    return this.calculator_;
  }

  /**
   * Add an array of markers to the clusterer.
   *
   * @param {Array.<google.maps.Marker>} markers The markers to add.
   * @param {boolean=} opt_nodraw Whether to redraw the clusters.
   */
  addMarkers(markers, opt_nodraw) {
    let i, marker;
    if (markers.length) {
      for (i = 0; i < markers.length; i++) {
        this.pushMarkerTo_(markers[i]);
      }
    } else if (Object.keys(markers).length) {
      for (marker in markers) {
        this.pushMarkerTo_(markers[marker]);
      }
    }
    if (!opt_nodraw) {
      this.redraw();
    }
  }

  /**
   * Pushes a marker to the clusterer.
   *
   * @param {google.maps.Marker} marker The marker to add.
   * @private
   */
  pushMarkerTo_(marker) {
    const { google } = window;
    marker.isAdded = false;
    if (marker['draggable']) {
      google.maps.event.addListener(marker, 'dragend', () => {
        marker.isAdded = false;
        this.repaint();
      });
    }
    this.markers_.push(marker);
  }

  /**
   * Adds a marker to the clusterer and redraws if needed.
   *
   * @param {google.maps.Marker} marker The marker to add.
   * @param {boolean=} opt_nodraw Whether to redraw the clusters.
   */
  addMarker(marker, opt_nodraw) {
    this.pushMarkerTo_(marker);
    if (!opt_nodraw) {
      this.redraw();
    }
  }

  /**
   * Removes a marker and returns true if removed, false if not
   *
   * @param {google.maps.Marker} marker The marker to remove
   * @return {boolean} Whether the marker was removed or not
   * @private
   */
  removeMarker_(marker) {
    const index = this.markers_.indexOf(marker);
    if (index === -1) {
      return false;
    }
    marker.setMap(null);
    this.markers_.splice(index, 1);
    return true;
  }

  /**
   * Remove a marker from the cluster.
   *
   * @param {google.maps.Marker} marker The marker to remove.
   * @param {boolean=} opt_nodraw Optional boolean to force no redraw.
   * @return {boolean} True if the marker was removed.
   */
  removeMarker(marker, opt_nodraw) {
    const removed = this.removeMarker_(marker);
    if (!opt_nodraw && removed) {
      this.resetViewport();
      this.redraw();
      return true;
    }
    return false;
  }

  /**
   * Removes an array of markers from the cluster.
   *
   * @param {Array.<google.maps.Marker>} markers The markers to remove.
   * @param {boolean=} opt_nodraw Optional boolean to force no redraw.
   */
  removeMarkers(markers, opt_nodraw) {
    // create a local copy of markers if required
    // (removeMarker_ modifies the getMarkers() array in place)
    const markersCopy =
      markers === this.getMarkers() ? markers.slice() : markers;
    let removed = false;
    let i, marker;
    for (i = 0; i < markersCopy.length; i++) {
      marker = markersCopy[i];
      removed = removed || this.removeMarker_(marker);
    }

    if (!opt_nodraw && removed) {
      this.resetViewport();
      this.redraw();
      return true;
    }
  }

  /**
   * Sets the clusterer's ready state.
   */
  setReady_(ready) {
    if (!this.ready_) {
      this.ready_ = ready;
      this.createClusters_();
    }
  }

  /**
   * Returns the number of clusters in the clusterer.
   */
  getTotalClusters() {
    return this.clusters_.length;
  }

  /**
   * Returns the google maps that the clusterer is associated with.
   */
  getMap() {
    return this.map_;
  }

  /**
   * Sets the google maps that the clusterer is associated with.
   */
  setMap(map) {
    this.map_ = map;
  }

  /**
   * Returns the size of the grid.
   */
  getGridSize() {
    return this.gridSize_;
  }

  /**
   * Sets the size of the grid.
   */
  setGridSize(size) {
    this.gridSize_ = size;
  }

  /**
   * Returns the min cluster size.
   */
  getMinClusterSize() {
    return this.minClusterSize_;
  }

  /**
   * Sets the min cluster size.
   *
   * @param {number} size The grid size.
   */
  setMinClusterSize(size) {
    this.minClusterSize_ = size;
  }

  /**
   * Extends a bounds object by the grid size.
   */
  getExtendedBounds(bounds) {
    const { google } = window;
    const projection = this.getProjection();

    // Turn the bounds into latlng.
    const tr = new google.maps.LatLng(
      bounds.getNorthEast().lat(),
      bounds.getNorthEast().lng()
    );
    const bl = new google.maps.LatLng(
      bounds.getSouthWest().lat(),
      bounds.getSouthWest().lng()
    );

    // Convert the points to pixels and the extend out by the grid size.
    const trPix = projection.fromLatLngToDivPixel(tr);
    trPix.x += this.gridSize_;
    trPix.y -= this.gridSize_;

    const blPix = projection.fromLatLngToDivPixel(bl);
    blPix.x -= this.gridSize_;
    blPix.y += this.gridSize_;

    // Convert the pixel points back to LatLng
    const ne = projection.fromDivPixelToLatLng(trPix);
    const sw = projection.fromDivPixelToLatLng(blPix);

    // Extend the bounds to contain the new bounds.
    bounds.extend(ne);
    bounds.extend(sw);

    return bounds;
  }

  /**
   * Determins if a marker is contained in a bounds.
   */
  isMarkerInBounds_(marker, bounds) {
    return bounds.contains(marker.getPosition());
  }

  /**
   * Clears all clusters and markers from the clusterer.
   */
  clearMarkers() {
    this.resetViewport(true);
    this.markers_ = [];
  }

  /**
   * Clears all existing clusters and recreates them.
   */
  resetViewport(opt_hide) {
    let i, marker, cluster;
    // Remove all the clusters
    for (i = 0; i < this.clusters_.length; i++) {
      cluster = this.clusters_[i];
      cluster.remove();
    }
    // Reset the markers to not be added and to be invisible.
    for (i = 0; i < this.markers_.length; i++) {
      marker = this.markers_[i];
      marker.isAdded = false;
      if (opt_hide) {
        marker.setMap(null);
      }
    }
    this.clusters_ = [];
  }

  /**
   * Repaint
   */
  repaint() {
    var oldClusters = this.clusters_.slice();
    this.clusters_.length = 0;
    this.resetViewport();
    this.redraw();

    // Remove the old clusters.
    // Do it in a timeout so the other clusters have been drawn first.
    setTimeout(() => {
      for (let i = 0; i < oldClusters.length; i++) {
        oldClusters[i].remove();
      }
    }, 0);
  }

  /**
   * Redraws the clusters.
   */
  redraw() {
    this.createClusters_();
  }

  /**
   * Calculates the distance between two latlng locations in km.
   */
  distanceBetweenPoints_(p1, p2) {
    if (!p1 || !p2) {
      return 0;
    }
    const R = 6371; // Radius of the Earth in km
    const dLat = ((p2.lat() - p1.lat()) * Math.PI) / 180;
    const dLon = ((p2.lng() - p1.lng()) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((p1.lat() * Math.PI) / 180) *
        Math.cos((p2.lat() * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  /**
   * Add a marker to a cluster, or creates a new cluster.
   */
  addToClosestCluster_(marker) {
    let distance = 40000; // Some large number
    let clusterToAddTo = null;
    let i, d, cluster, center;

    for (i = 0; i < this.clusters_.length; i++) {
      cluster = this.clusters_[i];
      center = cluster.getCenter();
      if (center) {
        d = this.distanceBetweenPoints_(center, marker.getPosition());
        if (d < distance) {
          distance = d;
          clusterToAddTo = cluster;
        }
      }
    }

    if (clusterToAddTo && clusterToAddTo.isMarkerInClusterBounds(marker)) {
      clusterToAddTo.addMarker(marker);
    } else {
      cluster = new Cluster(this);
      cluster.addMarker(marker);
      this.clusters_.push(cluster);
    }
  }

  /**
   * Creates the clusters.
   */
  createClusters_() {
    if (!this.ready_) {
      return;
    }
    const { google } = window;

    // Get our current maps view bounds.
    // Create a new bounds object so we don't affect the maps.
    const mapBounds = new google.maps.LatLngBounds(
      this.map_.getBounds().getSouthWest(),
      this.map_.getBounds().getNorthEast()
    );
    const bounds = this.getExtendedBounds(mapBounds);
    let i, marker;
    for (i = 0; i < this.markers_.length; i++) {
      marker = this.markers_[i];
      if (!marker.isAdded && this.isMarkerInBounds_(marker, bounds)) {
        this.addToClosestCluster_(marker);
      }
    }
  }
}

/**
 * A cluster that contains markers.
 */
class Cluster {
  constructor(markerClusterer) {
    this.markerClusterer_ = markerClusterer;
    this.map_ = markerClusterer.getMap();
    this.gridSize_ = markerClusterer.getGridSize();
    this.minClusterSize_ = markerClusterer.getMinClusterSize();
    this.averageCenter_ = markerClusterer.isAverageCenter();
    this.center_ = null;
    this.markers_ = [];
    this.bounds_ = null;
    this.clusterIcon_ = new ClusterIcon(
      this,
      markerClusterer.getStyles(),
      markerClusterer.getGridSize()
    );
  }

  /**
   * Determins if a marker is already added to the cluster.
   */
  isMarkerAlreadyAdded(marker) {
    return this.markers_.indexOf(marker) !== -1;
  }

  /**
   * Add a marker the cluster.
   */
  addMarker(marker) {
    if (this.isMarkerAlreadyAdded(marker)) {
      return false;
    }
    const { google } = window;

    if (!this.center_) {
      this.center_ = marker.getPosition();
      this.calculateBounds_();
    } else {
      if (this.averageCenter_) {
        const l = this.markers_.length + 1;
        const lat =
          (this.center_.lat() * (l - 1) + marker.getPosition().lat()) / l;
        const lng =
          (this.center_.lng() * (l - 1) + marker.getPosition().lng()) / l;
        this.center_ = new google.maps.LatLng(lat, lng);
        this.calculateBounds_();
      }
    }

    marker.isAdded = true;
    this.markers_.push(marker);

    const len = this.markers_.length;
    if (len < this.minClusterSize_ && marker.getMap() !== this.map_) {
      marker.setMap(this.map_);
    }

    if (len === this.minClusterSize_) {
      for (let i = 0; i < len; i++) {
        this.markers_[i].setMap(null);
      }
    }

    if (len >= this.minClusterSize_) {
      marker.setMap(null);
    }

    this.updateIcon();
    return true;
  }

  /**
   * Returns the marker clusterer that the cluster is associated with.
   */
  getMarkerClusterer() {
    return this.markerClusterer_;
  }

  /**
   * Returns the bounds of the cluster.
   */
  getBounds() {
    const { google } = window;
    const bounds = new google.maps.LatLngBounds(this.center_, this.center_);
    const markers = this.getMarkers();
    markers.forEach(marker => {
      bounds.extend(marker.getPosition());
    });
    return bounds;
  }

  /**
   * Removes the cluster
   */
  remove() {
    this.clusterIcon_.remove();
    this.markers_.length = 0;
    delete this.markers_;
  }

  /**
   * Returns the number of markers in the cluster.
   */
  getSize() {
    return this.markers_.length;
  }

  /**
   * Returns a list of the markers in the cluster.
   */
  getMarkers() {
    return this.markers_;
  }

  /**
   * Returns the center of the cluster.
   */
  getCenter() {
    return this.center_;
  }

  /**
   * Calculated the extended bounds of the cluster with the grid.
   */
  calculateBounds_() {
    const { google } = window;
    const bounds = new google.maps.LatLngBounds(this.center_, this.center_);
    this.bounds_ = this.markerClusterer_.getExtendedBounds(bounds);
  }

  /**
   * Determines if a marker lies in the clusters bounds.
   */
  isMarkerInClusterBounds(marker) {
    return this.bounds_.contains(marker.getPosition());
  }

  /**
   * Returns the maps that the cluster is associated with.
   */
  getMap() {
    return this.map_;
  }

  /**
   * Updates the cluster icon
   */
  updateIcon() {
    const zoom = this.map_.getZoom();
    const mz = this.markerClusterer_.getMaxZoom();

    if (mz && zoom > mz) {
      // The zoom is greater than our max zoom so show all the markers in cluster.
      this.markers_.forEach(marker => {
        marker.setMap(this.map_);
      });
      return;
    }

    if (this.markers_.length < this.minClusterSize_) {
      // Min cluster size not yet reached.
      this.clusterIcon_.hide();
      return;
    }

    const numStyles = this.markerClusterer_.getStyles().length;
    const sums = this.markerClusterer_.getCalculator()(
      this.markers_,
      numStyles
    );
    this.clusterIcon_.setCenter(this.center_);
    this.clusterIcon_.setSums(sums);
    this.clusterIcon_.show();
  }
}

/**
 * A cluster icon
 */
class ClusterIcon {
  constructor(cluster, styles, opt_padding) {
    const { google } = window;
    cluster.getMarkerClusterer().extend(ClusterIcon, google.maps.OverlayView);

    this.styles_ = styles;
    this.cluster_ = cluster;
    this.center_ = null;
    this.map_ = cluster.getMap();
    this.div_ = null;
    this.sums_ = null;
    this.visible_ = false;

    this.setMap(this.map_);
  }

  /**
   * Triggers the clusterclick event and zoom's if the option is set.
   */
  triggerClusterClick() {
    const { google } = window;
    const clusterBounds = this.cluster_.getBounds();
    const markerClusterer = this.cluster_.getMarkerClusterer();

    // Trigger the clusterclick event.
    google.maps.event.trigger(
      markerClusterer.map_,
      'clusterclick',
      this.cluster_
    );

    if (markerClusterer.isZoomOnClick()) {
      // Zoom into the cluster.
      this.map_.fitBounds(clusterBounds);
      this.map_.setCenter(clusterBounds.getCenter());
      if (this.map_.getZoom() > 16) {
        this.map_.setZoom(16);
      }
    }
  }

  /**
   * Adding the cluster icon to the dom.
   * @ignore
   */
  onAdd() {
    const { google } = window;
    this.div_ = document.createElement('div');
    if (this.visible_) {
      var pos = this.getPosFromLatLng_(this.center_);
      this.div_.style.cssText = this.createCss(pos);
      this.div_.innerHTML = this.sums_.text;
    }

    if (this.cssClassName_) {
      this.div_.className = this.cssClassName_;
    }

    const panes = this.getPanes();
    panes.overlayMouseTarget.appendChild(this.div_);

    google.maps.event.addDomListener(this.div_, 'click', () => {
      this.triggerClusterClick();
    });
  }

  /**
   * Returns the position to place the div dending on the latlng.
   */
  getPosFromLatLng_(latlng) {
    const pos = this.getProjection().fromLatLngToDivPixel(latlng);
    pos.x -= parseInt(this.width_ / 2, 10);
    pos.y -= parseInt(this.height_ / 2, 10);
    return pos;
  }

  /**
   * Draw the icon.
   */
  draw() {
    const { google } = window;
    if (this.visible_) {
      const pos = this.getPosFromLatLng_(this.center_);
      this.div_.style.top = pos.y + 'px';
      this.div_.style.left = pos.x + 'px';
      this.div_.style.zIndex = google.maps.Marker.MAX_ZINDEX + 1;
    }
  }

  /**
   * Hide the icon.
   */
  hide() {
    if (this.div_) {
      this.div_.style.display = 'none';
    }
    this.visible_ = false;
  }

  /**
   * Position and show the icon.
   */
  show() {
    if (this.div_) {
      const pos = this.getPosFromLatLng_(this.center_);
      this.div_.style.cssText = this.createCss(pos);
      this.div_.style.display = '';
    }
    this.visible_ = true;
  }

  /**
   * Remove the icon from the maps
   */
  remove() {
    this.setMap(null);
  }

  /**
   * Implementation of the onRemove interface.
   */
  onRemove() {
    if (this.div_ && this.div_.parentNode) {
      this.hide();
      this.div_.parentNode.removeChild(this.div_);
      this.div_ = null;
    }
  }

  /**
   * Set the sums of the icon.
   */
  setSums(sums) {
    this.sums_ = sums;
    this.text_ = sums.text;
    this.index_ = sums.index;
    if (this.div_) {
      this.div_.innerHTML = sums.text;
    }
    this.useStyle();
  }

  /**
   * Sets the icon to the the styles.
   */
  useStyle() {
    let index = Math.max(0, this.sums_.index - 1);
    index = Math.min(this.styles_.length - 1, index);
    const style = this.styles_[index];
    this.height_ = style['height'];
    this.width_ = style['width'];
    this.textColor_ = style['textColor'];
    this.backgroundColor_ = style['backgroundColor'];
    this.textSize_ = style['textSize'];
    this.cssClassName_ = style['cssClassName'];
  }

  /**
   * Sets the center of the icon.
   */
  setCenter(center) {
    this.center_ = center;
  }

  /**
   * Create the css text based on the position of the icon.
   */
  createCss(pos) {
    const style = [];

    const txtColor = this.textColor_ ? this.textColor_ : 'black';
    const txtSize = this.textSize_ ? this.textSize_ : 11;
    const bgColor = this.backgroundColor_ ? this.backgroundColor_ : 'white';

    style.push(
      'position:absolute; text-align:center; cursor:pointer; border-radius: 100%;'
    );
    style.push('top:' + pos.y + 'px; left:' + pos.x + 'px;');
    style.push('width:' + this.width_ + 'px; height:' + this.height_ + 'px;');
    style.push(
      'background-color:' +
        bgColor +
        '; color:' +
        txtColor +
        '; font-size:' +
        txtSize +
        'px;'
    );

    return style.join('');
  }
}

export default MarkerClusterer;
