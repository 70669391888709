import {
  addEvent,
  removeEvent,
  hasTouchEvents,
  getMousePosition,
  getTouchPosition,
  getTouchIdentifier,
} from './dom';

const minSwipeDist = 5;

const swipeEventTypes = {
  mouse: {
    start: 'mousedown',
    move: 'mousemove',
    end: 'mouseup',
  },
  touch: {
    start: 'touchstart',
    move: 'touchmove',
    end: 'touchend',
  },
};

export function swiper(el, options) {
  const doc = el.ownerDocument;
  const isTouch = hasTouchEvents();
  const eventTypes = isTouch ? swipeEventTypes.touch : swipeEventTypes.mouse;

  let isActive = false;
  let hasMoved = false;
  let startX, startY, currentX, currentY, absDistX, absDistY;
  let touchIdentifier = null;

  const trigger = (action, params) => {
    if (options && typeof options[action] === 'function') {
      options[action].apply(null, params);
    }
  };

  const handleSwipeStart = e => {
    touchIdentifier = getTouchIdentifier(e);
    const pos = isTouch
      ? getTouchPosition(e, touchIdentifier)
      : getMousePosition(e);

    isActive = true;
    hasMoved = false;
    startX = currentX = pos.pageX;
    startY = currentY = pos.pageY;
    absDistX = absDistY = 0;

    if (!isTouch) {
      e.preventDefault();
    }
    addEvent(doc, eventTypes.end, handleSwipeEnd, { once: true });
    addEvent(doc, eventTypes.move, handleSwipeMove, { passive: false });
    trigger('start', [startX, startY]);
  };

  const handleSwipeMove = e => {
    if (!isActive) return;
    const pos = isTouch
      ? getTouchPosition(e, touchIdentifier)
      : getMousePosition(e);

    if (!hasMoved) {
      absDistX += Math.abs(pos.pageX - currentX);
      absDistY += Math.abs(pos.pageY - currentY);
      if (absDistX > minSwipeDist || absDistY > minSwipeDist) {
        if (absDistX > absDistY || options.allowVertical) {
          hasMoved = true;
          startX = pos.pageX;
          startY = pos.pageY;
        } else {
          handleSwipeEnd();
        }
      }
    }
    currentX = pos.pageX;
    currentY = pos.pageY;

    if (isTouch && hasMoved) {
      e.preventDefault();
    }
    if (hasMoved) {
      trigger('move', [currentX - startX, currentY - startY]);
    }
  };

  const handleSwipeEnd = e => {
    if (!isActive) return;
    isActive = false;
    removeEvent(doc, eventTypes.end, handleSwipeEnd);
    removeEvent(doc, eventTypes.move, handleSwipeMove);
    trigger('end', [currentX - startX, currentY - startY]);
  };

  const init = () => {
    addEvent(el, eventTypes.start, handleSwipeStart);
    addEvent(el, eventTypes.end, handleSwipeEnd);
  };

  const remove = () => {
    isActive = false;
    removeEvent(el, eventTypes.start, handleSwipeStart);
    removeEvent(el, eventTypes.end, handleSwipeEnd);
    removeEvent(doc, eventTypes.move, handleSwipeMove);
    removeEvent(doc, eventTypes.end, handleSwipeEnd);
  };

  init();

  return remove;
}

export default swiper;
