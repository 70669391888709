export const apiBaseUrls = {
  EU: 'https://api-eu.global.commerce-connector.com/REST/2.0/',
  INT: 'https://api.commerce-connector.com/REST/2.0/',
};

export const apiEndpointDefaults = {
  LocalStore: {
    limit: 20,
    resultFields:
      'Id,Name,Description,ContactInfo,CustomFields,CustomerRestriction,CustomLocalStoreType,GeoInfo,AddressInfo,StoreType,Logos,OpeningHours,Open247,',
  },
};

export const countriesEU = [
  'AT',
  'BE',
  'CZ',
  'CH',
  'DE',
  'DK',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HU',
  'IE',
  'IT',
  'LU',
  'NL',
  'PL',
  'PT',
  'SE',
  'SK',
  'LU',
  'LT',
  'LV',
  'EE',
  'RO',
  'BG',
  'HR',
  'SI',
  'FI',
  'RU',
];
