import styled from 'styled-components';
import { Card, LocationContainer } from '../LocalStoreCard';

export const Wrapper = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  max-width: 1200px;
`;

export const Track = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: stretch;
  transition: transform 0.5s ease;
  transform: translateX(
    ${({ theme, slideIndex = 0 }) =>
      `${(theme.rtl ? slideIndex : -slideIndex) * 100}%`}
  );
`;

export const Slide = styled.div`
  flex: 1 0 auto;
  position: relative;
  width: 100%;
  padding: 10px 5px;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
`;

export const Slider = styled.div`
  width: 100%;
  // overflow: hidden;
  padding: 0 20px;
  & ${Slide} {
    pointer-events: ${({ isSwiping = false }) => (isSwiping ? 'none' : 'auto')};
  }
  & ${Card} {
    min-height: 176px;
  }
  & ${LocationContainer} {
    width: 70px;
  }
`;

export const Pagination = styled.div`
  font-size: 11px;
  line-height: 1;
  margin-top: 10px;
  text-align: center;
`;
