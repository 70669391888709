import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTheme } from '../../theme/hooks';
import { MapMarkerIcon } from '../../../components/Icon';
import { ListItem } from './AutocompleteListStyled';

const FeatureType = PropTypes.shape({
  id: PropTypes.string,
  place_name: PropTypes.string,
});

const FeatureListProps = {
  activeIndex: PropTypes.number,
  data: PropTypes.shape({
    type: PropTypes.string,
    query: PropTypes.arrayOf(PropTypes.string),
    features: PropTypes.arrayOf(FeatureType),
    attribution: PropTypes.string,
  }),
  onSelect: PropTypes.func,
  inputRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) }),
  ]),
};

const ListBox = styled.div.attrs(() => ({ role: 'listbox' }))`
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 4000;
  margin-top: 2px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #c8c8c8;
`;

const ListFooter = styled.div`
  padding: 5px;
  font-size: 11px;
  line-height: 13px;
  background-color: #fff;
`;

const FeatureList = ({ data, onSelect, activeIndex }) => {
  const theme = useTheme();

  const getAddress = item => {
    if (!item.address.length) {
      if (item.tag === '城市') {
        return item.name;
      } else {
        return `${item.province} ${item.city} ${item.name}`;
      }
    }

    return item.address;
  };

  const getLandmarkName = item => {
    return `${' '} ${item.name}`;
  };

  return (
    <ListBox>
      {data.result.map((item, index) => {
        return (
          item.location && (
            <ListItem
              key={`${item.address}-${index}`}
              onClick={() => onSelect(item)}
              active={index === activeIndex}>
              <MapMarkerIcon active size={16} color={theme.colors.highlight} />
              <div>
                <span>{getAddress(item)}</span>&nbsp;&nbsp;&nbsp;
                <span>{getLandmarkName(item)}</span>
              </div>
            </ListItem>
          )
        );
      })}
      <ListFooter>&copy; Baidu https://api.map.baidu.com/lbsapi/</ListFooter>
    </ListBox>
  );
};

FeatureList.propTypes = FeatureListProps;

const AutocompleteList = ({ data, onSelect, inputRef }) => {
  const [activeIndex, setActiveIndex] = React.useState(-1);
  React.useEffect(() => {
    const input = inputRef.current;
    if (data && input) {
      const numItems = data.result.length;
      const keyHandler = event => {
        switch (event.key) {
          case 'ArrowDown':
            setActiveIndex(prevIndex => Math.min(numItems - 1, prevIndex + 1));
            break;
          case 'ArrowUp':
            setActiveIndex(prevIndex => Math.max(0, prevIndex - 1));
            break;
          default:
            break;
        }
      };
      input.addEventListener('keydown', keyHandler);
      return () => {
        input.removeEventListener('keydown', keyHandler);
      };
    }
  }, [data, inputRef]);

  React.useEffect(() => {
    const input = inputRef.current;
    if (input && data && data.result[activeIndex]) {
      input.value = data.result[activeIndex].address.length
        ? data.result[activeIndex].address
        : data.result[activeIndex].name;
    }
  }, [data, inputRef, activeIndex]);

  return data ? (
    <FeatureList data={data} onSelect={onSelect} activeIndex={activeIndex} />
  ) : null;
};

AutocompleteList.propTypes = FeatureListProps;

export default AutocompleteList;
