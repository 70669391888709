import PropTypes from 'prop-types';

export const LocalStoreShape = PropTypes.shape({
  AddressInfo: PropTypes.arrayOf(
    PropTypes.shape({
      City: PropTypes.string,
      Country: PropTypes.string,
      County: PropTypes.string,
      Postcode: PropTypes.string,
      Street: PropTypes.string,
      Street2: PropTypes.string,
    })
  ),
  ContactInfo: PropTypes.arrayOf(
    PropTypes.shape({
      Email: PropTypes.string,
      Fax: PropTypes.string,
      Phone: PropTypes.string,
      Website: PropTypes.string,
    })
  ),
  CustomerRestriction: PropTypes.shape({
    CommercialCustomers: PropTypes.oneOf(['1', '0', null]),
    PrivateCustomers: PropTypes.oneOf(['1', '0', null]),
  }),
  CustomFields: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string,
      Name: PropTypes.string,
      Description: PropTypes.string,
      Values: PropTypes.arrayOf(
        PropTypes.shape({
          Position: PropTypes.string,
          Value: PropTypes.string,
        })
      ),
    })
  ),
  Description: PropTypes.string,
  GeoInfo: PropTypes.arrayOf(
    PropTypes.shape({
      Distance: PropTypes.string,
      Lat: PropTypes.string,
      Lng: PropTypes.string,
    })
  ),
  Id: PropTypes.string,
  Logos: PropTypes.arrayOf(
    PropTypes.shape({
      LogoLarge: PropTypes.string,
      LogoMedium: PropTypes.string,
      LogoSmall: PropTypes.string,
      LogoSquareLarge: PropTypes.string,
      LogoSquareMedium: PropTypes.string,
      LogoSquareSmall: PropTypes.string,
    })
  ),
  Name: PropTypes.string,
  OpeningHours: PropTypes.array,
  StoreType: PropTypes.string,
});

export const LocalStoresResultGroupsShape = PropTypes.shape({
  ResultGroupHeader: PropTypes.any,
  LocalStores: PropTypes.arrayOf(LocalStoreShape),
});

export const LocalStoresResponseShape = PropTypes.shape({
  getResult: PropTypes.arrayOf(LocalStoresResultGroupsShape),
});

export const LocalStoresResultShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  location: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  marker: PropTypes.any,
  shop: LocalStoreShape.isRequired,
});
