import PropTypes from 'prop-types';

export const searchPaginationModes = {
  INFINITE: 'infinite',
  PAGES: 'pages',
  SHOW_MORE: 'showMore',
};

export const searchActionTypes = {
  DETECT_LOCATION: 'detectLocation',
  LOCATION_DETECTION_ERROR: 'locationDetectionError',
  REQUEST_RESULTS: 'requestResults',
  RESPONSE_ERROR: 'responseError',
  SET_FILTERS: 'setFilters',
  SET_CUSTOM_FIELDS: 'setCustomFields',
  SET_PAGE: 'setPage',
  SET_PAGINATION_MODE: 'setPaginationMode',
  SET_RESULTS_DISPLAY_MODE: 'setResultsDisplayMode',
  SEARCH_LOCATION: 'searchLocation',
  SEARCH_RESULTS: 'searchResults',
  SELECT_RESULT: 'selectResult',
  INIT_STATE: 'initState',
  UPDATE_NUM_OF_FILTERED_RESULTS: 'updateNumOfFilteredResults',
  UPDATE_VISIBLE_FILTERS: 'updateVisibleFilters',
};

export const searchStateShape = {
  currentPage: PropTypes.number,
  detectingLocation: PropTypes.bool,
  filters: PropTypes.array,
  isPending: PropTypes.bool,
  itemsPerPage: PropTypes.number,
  locationDetectionError: PropTypes.any,
  paginationMode: PropTypes.string,
  numPages: PropTypes.number,
  numResults: PropTypes.number,
  numOfFilteredResults: PropTypes.number,
  responseError: PropTypes.any,
  resultsDisplayMode: PropTypes.oneOf(['map', 'list']),
  searchLocation: PropTypes.shape({
    address: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  searchResults: PropTypes.any,
  selectedResult: PropTypes.any,
  summary: PropTypes.any,
  visibleResults: PropTypes.any,
};
