import React from 'react';
import {
  SVGIcon,
  IconPropTypes,
  defaultIconSize,
  defaultIconColor,
} from '../SVGIcon';

const CloseThin = ({ color = defaultIconColor, size = defaultIconSize }) => {
  return (
    <SVGIcon
      width={size}
      height={size}
      viewBox="-17.5 -17.5 60 60"
      title="Close Icon"
      uid="cc-icon-close-thin">
      <polygon
        fill={color}
        points="25,24.141 24.141,25 12.5,13.361 0.861,25 0,24.141 11.639,12.5 0,0.861 0.861,0 12.5,11.639 24.141,0 25,0.861 13.362,12.5"
      />
    </SVGIcon>
  );
};

CloseThin.propTypes = IconPropTypes;

export default CloseThin;
