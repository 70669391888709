import { useMemo } from 'react';
import { useConfigValue, useTranslation } from '../hooks';
import { confKeys, displayModes, displayModeIds, textKeys } from '../constants';

export const getDisplayModeOptionsLocalized = (
  resultsDisplayMode,
  t,
  mapItems,
  listItems
) => {
  if (resultsDisplayMode.length === 1) {
    resultsDisplayMode = resultsDisplayMode[0];
  } else {
    resultsDisplayMode = displayModes.TABS;
  }
  switch (resultsDisplayMode) {
    case displayModes.LIST:
      return [
        {
          id: displayModeIds.LIST,
          label: t(textKeys.DISPLAY_MODE_LIST),
          itemsPerPage: listItems,
        },
      ];
    case displayModes.MAP:
      return [
        {
          id: displayModeIds.MAP,
          label: t(textKeys.DISPLAY_MODE_MAP),
          itemsPerPage: mapItems,
        },
      ];
    case displayModes.TABS:
    default:
      return [
        {
          id: displayModeIds.MAP,
          label: t(textKeys.DISPLAY_MODE_MAP),
          itemsPerPage: mapItems,
        },
        {
          id: displayModeIds.LIST,
          label: t(textKeys.DISPLAY_MODE_LIST),
          itemsPerPage: listItems,
        },
      ];
  }
};

export const getDisplayTranslations = (resultsDisplayMode, t) => {
  if (resultsDisplayMode.length === 1) {
    resultsDisplayMode = resultsDisplayMode[0];
  } else {
    resultsDisplayMode = displayModes.TABS;
  }
  switch (resultsDisplayMode) {
    case displayModes.LIST:
      return [
        {
          id: displayModeIds.LIST,
          label: t(textKeys.DISPLAY_MODE_LIST),
        },
      ];
    case displayModes.MAP:
      return [
        {
          id: displayModeIds.MAP,
          label: t(textKeys.DISPLAY_MODE_MAP),
        },
      ];
    case displayModes.TABS:
    default:
      return [
        {
          id: displayModeIds.MAP,
          label: t(textKeys.DISPLAY_MODE_MAP),
        },
        {
          id: displayModeIds.LIST,
          label: t(textKeys.DISPLAY_MODE_LIST),
        },
      ];
  }
};

export const useDisplayModesLocalized = (mapItems, listItems) => {
  const t = useTranslation();
  const configDisplayMode = useConfigValue(confKeys.RESULTS_DISPLAY_MODE);

  return useMemo(
    () =>
      getDisplayModeOptionsLocalized(configDisplayMode, t, mapItems, listItems),
    [configDisplayMode, listItems, mapItems, t]
  );
};

export const useDisplayNameLocalized = () => {
  const t = useTranslation();
  const configDisplayMode = useConfigValue(confKeys.RESULTS_DISPLAY_MODE);

  return useMemo(() => getDisplayTranslations(configDisplayMode, t), [
    configDisplayMode,
    t,
  ]);
};
