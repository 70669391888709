import styled from 'styled-components';

export const ListItem = styled.div.attrs(({ active = false }) => ({
  role: 'listitem',
  'aria-selected': !!active,
}))`
  background-color: ${({ active = false }) => (active ? '#eff0f4' : '#fff')};
  padding: 5px 10px 5px 5px;
  cursor: pointer;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  :not(:last-child) {
    border-bottom: 1px solid #eff0f4;
  };
  & svg {
    flex: 0 0 16px;
    margin-right: 5px;
    opacity: ${({ active = false }) => (active ? 1 : 0.5)};
  };
  div {
    display: flex;
    max-width: 97%;
    span {
      white-space: nowrap;
    }
    span:first-child {
      color: black;
    }
    span:last-child {
      color: #bbb;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      display: inline-block;
    }
  }
  &:hover {
    background-color: #eff0f4;
    & svg {
      opacity: 1;
    };
  };
`;

export const ListFooter = styled.div`
  padding: 5px;
  font-size: 11px;
  line-height: 13px;
  background-color: #fff;
`;