import { breakpointMobile } from '../../constants';
import styled from 'styled-components';
import { Row } from '../Layout';

export const Field = styled(Row)`
  align-items: center;
  justify-content: flex-start;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

export const Label = styled.label`
  font-size: 13px;
  font-weight: ${({ theme }) => theme.typography.main.fontWeight};
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 0;
  line-height: 1;
  @media (max-width: ${breakpointMobile}px) {
    flex-grow: 1;
  }
`;
