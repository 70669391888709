import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { confKeys, widgetModes } from '../../constants';
import {
  useIsMobile,
  useHasSearchResults,
  useConfigValue,
  useSearch,
} from '../../hooks';
import { ScrollContainer } from '../Layout';
import {
  LocalStoreList,
  listItemDisplayModes,
} from '../LocalStore/LocalStoreList';
import { MoreResults } from './MoreResults';

const ccid = 'search-results-list';

export const Container = styled.div`
  flex: 1 0 auto;
  margin: 0;
  width: 100%;
  overflow: visible;
  padding: ${({ withPadding }) => (withPadding ? '0 10px' : '0')};
  background-color: ${({ theme, withPadding }) =>
    withPadding ? theme.colors.background : 'transparent'};
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

export const PopupContainer = styled.div`
  flex: 1 1 auto;
  margin: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.colors.background};
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

export const Inner = styled.div`
  margin: 0 -10px;
  padding: ${({ withPadding }) => (withPadding ? '0 10px' : '0')};
  width: auto;
`;

const ResultsListInline = () => {
  const isMobile = useIsMobile();
  return (
    <Container data-ccid={`${ccid}-container`} withPadding>
      <Inner data-ccid={`${ccid}-inner`}>
        <LocalStoreList
          itemDisplayMode={
            isMobile ? listItemDisplayModes.CARD : listItemDisplayModes.LISTITEM
          }
        />
        <MoreResults />
      </Inner>
    </Container>
  );
};

const ResultsListPopup = () => {
  const isMobile = useIsMobile();
  const { visibleResults } = useSearch();
  const scrollContainerRef = useRef(null);
  const [scrollbarSize, setScrollbarSize] = useState(0);

  useEffect(() => {
    // Set scrollbar size
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer && visibleResults) {
      setScrollbarSize(
        scrollContainer.offsetWidth - scrollContainer.clientWidth
      );
    }
  }, [scrollContainerRef, visibleResults]);

  return (
    <PopupContainer>
      <ScrollContainer
        data-ccid={`${ccid}-container`}
        cropScrollbar={scrollbarSize}
        ref={scrollContainerRef}>
        <Inner data-ccid={`${ccid}-inner`} withPadding>
          <LocalStoreList
            itemDisplayMode={
              isMobile
                ? listItemDisplayModes.CARD
                : listItemDisplayModes.LISTITEM
            }
          />
          <MoreResults />
        </Inner>
      </ScrollContainer>
    </PopupContainer>
  );
};

export function ResultsList() {
  const widgetMode = useConfigValue(confKeys.WIDGET_MODE);
  const hasResults = useHasSearchResults();
  const Layout =
    widgetMode === widgetModes.POPUP ? ResultsListPopup : ResultsListInline;
  return hasResults && <Layout />;
}

export default ResultsList;
