import styled from 'styled-components';
import { Box, Container, Row } from '../../Layout';

export const Wrapper = styled(Row)`
  padding: 10px 0;
  font-size: 13px;
  align-items: flex-start;
`;

export const HoursIconContainer = styled(Box)`
  flex: 0 0 auto;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.textSecondary};
  padding-top: 1px;
  & > svg {
    margin: ${({ theme }) => (theme.rtl ? '0 0 0 6px' : '0 6px 0 0')};
  }
`;

export const ContentContainer = styled(Container)`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Cell = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`;

export const LabelCell = styled(Cell)`
  flex: 1 0 auto;
`;

export const DataCell = styled(Cell)`
  flex: 0 0 auto;
  padding: ${({ theme }) => (theme.rtl ? '0 6px 0 0' : '0 0 0 6px')};
`;

export const DataRow = styled(Row)`
  width: auto;
  max-width: 100%;
  flex: 0 0 auto;
`;

export const ExpandIconContainer = styled.span`
  flex: 0 0 auto;
  width: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: 2px;
  color: ${({ theme }) => theme.colors.text};
  & > svg {
    position: absolute;
  }
`;

export const Header = styled.div`
  cursor: pointer;
  & ${LabelCell} {
    font-family: ${({ theme }) => theme.typography.bold.fontFamily};
    font-weight: ${({ theme }) => theme.typography.bold.fontWeight};
  }
`;

export const Details = styled.div`
  display: inline-block;
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.textSecondary};
`;
