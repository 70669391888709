import React from 'react';
import styled from 'styled-components';
import { textKeys } from '../../constants';
import { useDetectLocation, useTranslation } from '../../hooks';
import { BaseButton } from '../Button';
import { AutoLocateCircleIcon } from '../Icon';

import {
  useTrackingContext,
  useDisplayModesLocalized,
  useResultsDisplayMode,
} from '../../hooks';

const Button = styled(BaseButton)`
  color: ${({ theme }) => theme.colors.highlight};
  height: 100%;
  width: 40px;
`;

const ccid = 'autolocate-icon-button';

export const AutoLocateIconButton = props => {
  const t = useTranslation();
  const detectLocation = useDetectLocation();
  const trackingContext = useTrackingContext();
  const { track } = trackingContext;
  const displayModeOptions = useDisplayModesLocalized();
  const [displayMode] = useResultsDisplayMode();

  const label = t(textKeys.LABEL_NEAR_ME);

  const handleLocationDetection = () => {
    detectLocation();

    const displayModeDetails = displayModeOptions
      .filter(mode => mode.id === displayMode)
      .map((mode, index) => {
        return {
          label: mode.label,
          position: index,
        };
      })[0];

    track('use_my_location_click', {
      displayed_tab_type: displayMode,
      displayed_tab_name: displayModeDetails.label,
      displayed_tab_position: displayMode === 'list' ? 1 : 0,
    });
  };

  return (
    <Button
      data-ccid={ccid}
      onClick={handleLocationDetection}
      title={label}
      aria-label="Detect Location"
      {...props}>
      <AutoLocateCircleIcon size={24} />
    </Button>
  );
};

export default AutoLocateIconButton;
