import React from 'react';
import { apiScriptUrl, apiStylesheetUrl } from './config';
import { useMapsApiKey } from '../hooks';

let runtimeJsLoaded = false;
let runtimeCssLoaded = false;

const loadScript = (() => {
  let loadPromise;
  return () => {
    if (loadPromise) {
      return loadPromise;
    }
    const scriptNode = document.head.querySelector(
      `script[src="${apiScriptUrl}"]`
    );
    if (scriptNode) {
      loadPromise = Promise.resolve();
      return loadPromise;
    }
    loadPromise = new Promise((resolve, reject) => {
      const scriptNode = document.createElement('script');
      scriptNode.src = apiScriptUrl;
      scriptNode.onload = () => {
        runtimeJsLoaded = true;
        resolve();
      };
      scriptNode.onerror = () => {
        reject();
      };
      document.head.appendChild(scriptNode);
    });
    return loadPromise;
  };
})();

const loadStylesheet = (() => {
  let loadPromise;
  return () => {
    if (loadPromise) {
      return loadPromise;
    }
    const domNode = document.head.querySelector(
      `link[href="${apiStylesheetUrl}"]`
    );
    if (domNode) {
      loadPromise = Promise.resolve();
      return loadPromise;
    }
    loadPromise = new Promise((resolve, reject) => {
      const node = document.createElement('link');
      node.rel = 'stylesheet';
      node.href = apiStylesheetUrl;
      node.onload = () => {
        runtimeCssLoaded = true;
        resolve();
      };
      node.onerror = () => {
        reject();
      };
      document.head.appendChild(node);
    });
    return loadPromise;
  };
})();

/**
 * withMapsApi HOC
 * @param Component
 * @returns {{}} The wrapped component
 */
const withMapsApi = function (Component) {
  const ComponentWithMapsApi = function (props) {
    const apiKey = useMapsApiKey();
    const [state, setState] = React.useState({
      jsLoaded: runtimeJsLoaded,
      cssLoaded: runtimeCssLoaded,
      pending: false,
      ready: runtimeJsLoaded && runtimeCssLoaded,
      error: undefined,
    });
    const { pending, ready, error, jsLoaded, cssLoaded } = state;
    const assetsLoaded = jsLoaded && cssLoaded;

    if (!ready && !error && !pending && !assetsLoaded) {
      setState(prevState => ({
        ...prevState,
        pending: true,
      }));

      if (!jsLoaded) {
        loadScript()
          .then(() => {
            const { mapboxgl } = window;
            mapboxgl.accessToken = apiKey;
            setState(prevState => ({
              ...prevState,
              jsLoaded: true,
            }));
          })
          .catch(err => {
            setState(prevState => ({
              ...prevState,
              error: err.message,
            }));
          });
      }

      if (!cssLoaded) {
        loadStylesheet()
          .then(() => {
            setState(prevState => ({
              ...prevState,
              cssLoaded: true,
            }));
          })
          .catch(err => {
            setState(prevState => ({
              ...prevState,
              error: err.message,
            }));
          });
      }
    }

    if (pending && assetsLoaded) {
      setState(prevState => ({
        ...prevState,
        pending: false,
        ready: true,
      }));
    }

    if (!ready) {
      if (error) {
        return <span>{error}</span>;
      }
      return <span />;
    }

    return <Component {...props} />;
  };

  ComponentWithMapsApi.propTypes = Component.propTypes;
  return ComponentWithMapsApi;
};

export default withMapsApi;
