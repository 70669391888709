import React from 'react';
import { SearchInputPropTypes } from '../types';
import { useMapsCountry, useMapsLanguage } from '../hooks';
import { useDetectLocation, useMountEffect } from '../../../hooks';

import withMapsApi from './withMapsApi';
import initAutoComplete from './utils/initAutoComplete';
import { useForwardGeocoding, useReverseGeocoding } from './hooks';

const SearchInput = ({
  as: InputComponent = 'input',
  className,
  placeholder = '',
  resetKey = 0,
  value,
  style,
  searchLocation,
  onFocus,
  onBlur,
  onChange,
  onSubmit,
  onLocationChange,
  onAutoDetectAddress,
  ...otherProps
}) => {
  const country = useMapsCountry();
  const language = useMapsLanguage();
  const getLocationByAddress = useForwardGeocoding();
  const getAddressByLocation = useReverseGeocoding();
  const detectLocation = useDetectLocation();
  const [locationData, setLocationData] = React.useState(null);

  const autocompleteRef = React.useRef();
  const inputRef = React.useRef();

  useMountEffect(detectLocation);

  React.useEffect(() => {
    if (locationData) {
      onLocationChange && onLocationChange(locationData);
    }
  }, [locationData, onLocationChange]);

  React.useEffect(() => {
    const input = inputRef.current;
    if (input) {
      if (searchLocation) {
        if (searchLocation.address) {
          input.value = searchLocation.address;
        } else if (searchLocation.lat && searchLocation.lng) {
          // Location was auto-detected
          // -> get address and inject into searchLocation silently
          // -> then call onAutoDetectAddress for tracking
          getAddressByLocation(searchLocation).then(addressData => {
            const input = inputRef.current;
            if (input && addressData && addressData.address) {
              input.value = addressData.address;
              searchLocation.address = addressData.address;
              searchLocation.city = addressData.city || '';
              searchLocation.country = addressData.country || '';
              searchLocation.zip = addressData.zip || '';
              onAutoDetectAddress(addressData);
            }
          });
        }
      } else {
        input.value = '';
        input.blur();
      }
    }
  }, [inputRef, searchLocation, onAutoDetectAddress, getAddressByLocation]);

  React.useEffect(() => {
    const input = inputRef.current;
    if (input && country) {
      if (autocompleteRef.current) {
        autocompleteRef.current.setOptions({
          country: country,
          language: language,
        });
      } else {
        autocompleteRef.current = initAutoComplete(input, {
          country: country,
          language: language,
          callback: place => {
            if (!place || !place.geometry || !place.geometry.location) return;

            const locationData = {
              address: place.formatted_address,
              type: place.types && place.types[0],
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };

            getAddressByLocation(locationData).then(addressData => {
              if (addressData && addressData.address) {
                input.value = addressData.address;
                locationData.address = addressData.address;
                locationData.city = addressData.city;
                locationData.country = addressData.country;
                locationData.post_code = addressData.zip;
              }
              setLocationData(locationData);
            });
          },
        });
      }
    }
  }, [inputRef, autocompleteRef, country, language, getAddressByLocation]);

  React.useEffect(() => {
    const autocomplete = autocompleteRef.current;
    return () => {
      if (autocomplete) {
        autocomplete.destroy();
      }
    };
  }, [autocompleteRef]);

  React.useEffect(() => {
    const input = inputRef.current;
    let timeout;
    if (input && resetKey > 0) {
      input.value = '';
      timeout = setTimeout(() => {
        input.focus();
      }, 10);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [inputRef, resetKey]);

  const handleBlur = React.useCallback(
    event => {
      if (
        event.target.value === '' &&
        searchLocation &&
        searchLocation.address
      ) {
        if (Date.now() - resetKey > 100) {
          event.target.value = searchLocation.address;
        }
      }
      onBlur && onBlur(event);
    },
    [searchLocation, resetKey, onBlur]
  );

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      const queryText = event.target.value;
      if (queryText.length > 2) {
        getLocationByAddress(queryText).then(place => {
          const input = inputRef.current;
          if (!input || !place || !place.geometry || !place.geometry.location)
            return;
          const locationData = {
            address: place.formatted_address,
            type: place.types && place.types[0],
            lat: place.geometry.location.lat,
            lng: place.geometry.location.lng,
          };
          setLocationData(locationData);
          input.value = locationData.address;
          input.blur();
        });
      }
      onSubmit && onSubmit(queryText);
    }
  };

  return (
    <InputComponent
      className={className}
      style={style}
      type="search"
      autoComplete="off"
      spellCheck="false"
      placeholder={placeholder}
      onKeyDown={handleKeyDown}
      onFocus={onFocus}
      onBlur={handleBlur}
      onChange={onChange}
      ref={inputRef}
      aria-autocomplete="list"
      role="combobox"
      {...otherProps}
    />
  );
};

SearchInput.propTypes = SearchInputPropTypes;

export default withMapsApi(SearchInput);
