export const groupObjects = (collection, property = 'groupId') => {
  const grouped = [];

  collection.forEach((filter, index) => {
    const compareKey = filter[property];
    const obj = grouped.filter(item => {
      const key = Object.keys(item)[0];

      if (compareKey === key) {
        return true;
      }

      return false;
    });

    if (!obj.length) {
      grouped.push({
        [filter[property]]: [filter.id],
      });
    } else {
      grouped.forEach((item, index) => {
        const key = Object.keys(item)[0];
        const compareKey = Object.keys(obj[0])[0];

        if (key === compareKey) {
          item[key].push(filter.id);
        }
      });
    }
  });

  return grouped;
};

export const calculateScrollAmount = (direction, ref) => {
  const { offsetWidth, scrollWidth, scrollLeft } = ref.current;
  if (direction === 'right') {
    const remainingPixels = scrollWidth - offsetWidth - scrollLeft;
    return Math.min(300, remainingPixels);
  }
  if (direction === 'left') {
    const remainingPixels = scrollWidth - offsetWidth;
    return Math.max(-300, -1 * remainingPixels);
  }
};

export const isVisible = (ele, container) => {
  const { left, right } = ele.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  return left >= containerRect.left && right <= containerRect.right;
};
