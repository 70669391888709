import styled from 'styled-components';
import { fadeInAnim } from '../../../styles/animations';
import { BaseButton } from './../../Button/BaseButton';
import { popupButtonStyles } from '../../../constants';

const getStyle = (styles, name) => {
  if (styles && typeof styles[name] === 'string') {
    return styles[name];
  }
};

const getBorderColor = ({ styles, theme }) => {
  return getStyle(styles, popupButtonStyles.BORDER) || theme.colors.highlight;
};

const getBackgroundColor = ({ styles, theme }) => {
  return (
    getStyle(styles, popupButtonStyles.BACKGROUND) || theme.colors.highlight
  );
};

const getTextColor = ({ styles, theme }) => {
  return getStyle(styles, popupButtonStyles.TEXT) || theme.colors.white;
};

export const ContactList = styled.div`
  position: relative;
  margin: 0;
  padding: 10px 0;
  max-width: 100%;
  animation: ${fadeInAnim} 0.3s ease;
  animation-fill-mode: forwards;
`;

export const ContactLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-decoration: none !important;
  border-bottom: transparent !important;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.textLink};
  :hover {
    color: ${({ theme }) => theme.colors.textLinkHover};
    text-decoration: none !important;
    border-bottom: transparent !important;
  }
  & > svg {
    flex: 0 0 auto;
    margin-top: 1px;
  }
`;

export const Button = styled(BaseButton)`
  height: 40px;
  padding: 0 20px;
  min-width: 130px;
  background: ${getBackgroundColor};
  transition: background-color 0.2s ease, border-color 0.2s ease;
  color: ${getTextColor};
  border: 1px solid ${getBorderColor};
  border-radius: 20px;
  font-family: ${({ theme }) => theme.typography.main.fontFamily};
  font-weight: ${({ theme }) => theme.typography.main.fontWeight};
  font-size: 12px;
  line-height: 16px;
  :disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const ContactIconLink = styled.a`
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 auto 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  text-decoration: none !important;
  border-bottom: transparent;
  color: ${({ theme }) => theme.colors.textLink};
  :hover {
    color: ${({ theme }) => theme.colors.textLinkHover};
    text-decoration: none !important;
    border-bottom: transparent !important;
  }
  :before {
    content: '';
    display: block;
    width: 29px;
    height: 29px;
    border-radius: 29px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  :last-of-type {
    margin-bottom: 0;
  }
  & > svg {
    flex: 0 0 auto;
  }
`;

export const ContactLinkText = styled.span`
  display: inline-block;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContactLinkTextRoute = styled.span`
  display: inline-block;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${getTextColor};
`;
