import { BaseButton } from './BaseButton';
import styled from 'styled-components';

export const TextButton = styled(BaseButton)`
  height: 40px;
  padding: 0 20px;
  min-width: 160px;
  transition: background-color 0.2s ease, border-color 0.2s ease,
    color 0.2s ease;
  border: 1px solid transparent;
  border-radius: 20px;
  font-family: ${({ theme }) => theme.typography.main.fontFamily};
  font-weight: ${({ theme }) => theme.typography.main.fontWeight};
  font-size: 14px;
  line-height: 16px;
  :disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const PrimaryButton = styled(TextButton)`
  border-color: ${({ theme }) => theme.colors.highlight};
  background-color: ${({ theme }) => theme.colors.highlight};
  color: ${({ theme }) => theme.colors.white};
  :hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.highlight};
  }
`;

export const SecondaryButton = styled(TextButton)`
  border-color: ${({ theme }) => theme.colors.highlight};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.highlight};
  :hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.highlight};
  }
`;
