import { useEffect, useState } from 'react';
import { popupSlideDuration } from './constants';

export const usePopupTransition = ({ initiallyOpened = false }) => {
  const [state, setState] = useState({
    hidden: !initiallyOpened,
    active: initiallyOpened,
    opened: initiallyOpened,
    ready: initiallyOpened,
  });
  const { opened } = state;

  useEffect(() => {
    let timeout;
    if (!opened) {
      setState(prevState => ({
        ...prevState,
        ready: false,
        active: false,
      }));
      timeout = setTimeout(() => {
        setState(prevState => ({
          ...prevState,
          hidden: true,
        }));
      }, popupSlideDuration);
    } else {
      setState(prevState => ({
        ...prevState,
        hidden: false,
      }));
      timeout = setTimeout(() => {
        setState(prevState => ({
          ...prevState,
          active: true,
        }));
        timeout = setTimeout(() => {
          setState(prevState => ({
            ...prevState,
            ready: true,
          }));
        }, popupSlideDuration);
      }, 50);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [opened]);

  return [state, setState];
};

export default usePopupTransition;
