import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  HoursIconContainer,
  ContentContainer,
  Header,
  LabelCell,
  DataCell,
  ExpandIconContainer,
  Details,
  DataRow,
} from './LocalStoreOpeningStyled';
import { useTranslation } from '../../../hooks';
import { LocalStoreShape, getOpeningHoursLocalized } from '../../../api';
import { CaretDownIcon, CaretUpIcon, HoursIcon } from '../../Icon';
import { Row } from '../../Layout';

const ccid = 'localstore-opening';

export const LocalStoreOpening = ({
  expanded,
  shop,
  currentTime,
  ...otherProps
}) => {
  const [isExpanded, setIsExpanded] = useState(
    typeof expanded === 'boolean' ? expanded : false
  );
  const t = useTranslation();

  useEffect(() => {
    if (typeof expanded === 'boolean') {
      setIsExpanded(expanded);
    }
  }, [expanded]);

  const {
    isOpenNow,
    labelOpenNow,
    hoursOpenNow,
    groupedOpeningHours,
  } = getOpeningHoursLocalized({ shop, t, currentTime });

  const ExpandIcon = isExpanded ? CaretUpIcon : CaretDownIcon;

  return (
    <Wrapper
      data-ccid={`${ccid}`}
      data-clickout-type="opening_hours_click"
      {...otherProps}>
      <HoursIconContainer data-ccid={`${ccid}-icon-container`}>
        <HoursIcon size={15} />
      </HoursIconContainer>
      <ContentContainer data-ccid={`${ccid}-content-container`}>
        <Header
          onClick={() => setIsExpanded(!isExpanded)}
          data-ccid={`${ccid}-header`}>
          <Row data-ccid={`${ccid}-header-row`}>
            <LabelCell openNow={isOpenNow} data-ccid={`${ccid}-header-label`}>
              {labelOpenNow}
            </LabelCell>
            {!isExpanded && hoursOpenNow && (
              <DataCell data-ccid={`${ccid}-header-hours`}>
                {hoursOpenNow}
              </DataCell>
            )}
            <ExpandIconContainer data-ccid={`${ccid}-expand-icon-container`}>
              <ExpandIcon size={20} />
            </ExpandIconContainer>
          </Row>
        </Header>
        {isExpanded && (
          <Details data-ccid={`${ccid}-details`}>
            {groupedOpeningHours.map(data => (
              <Row key={`row-${data.label}`} data-ccid={`${ccid}-details-row`}>
                <LabelCell data-ccid={`${ccid}-details-label`}>
                  {data.label}
                </LabelCell>
                <DataCell data-ccid={`${ccid}-details-hours`}>
                  {data.value.split('####').map((value, index) => (
                    <DataRow
                      key={`data-${data.label}-${value}`}
                      data-ccid={`${ccid}-details-hours__row${index}`}>
                      {value}
                    </DataRow>
                  ))}
                </DataCell>
              </Row>
            ))}
          </Details>
        )}
      </ContentContainer>
    </Wrapper>
  );
};

LocalStoreOpening.propTypes = {
  expanded: PropTypes.oneOf([undefined, true, false]),
  shop: LocalStoreShape.isRequired,
  currentTime: PropTypes.number,
};

export default LocalStoreOpening;
