import React from 'react';
import {
  SVGIcon,
  IconPropTypes,
  defaultIconSize,
  defaultIconColor,
} from '../SVGIcon';

const GlobeOutline = ({ color = defaultIconColor, size = defaultIconSize }) => {
  return (
    <SVGIcon
      width={size}
      height={size}
      viewBox="0 0 24 24"
      title="Globe Icon"
      uid="cc-icon-globe-outline">
      <path
        fill={color}
        d="M12,23.5C5.659,23.5,0.5,18.34,0.5,12C0.5,5.659,5.659,0.5,12,0.5c6.34,0,11.5,5.159,11.5,11.5
	C23.5,18.34,18.341,23.5,12,23.5z M8.883,16.729c0.646,3.655,2.043,5.481,3.118,5.481s2.472-1.934,3.118-5.481
	C13.075,16.944,10.926,16.944,8.883,16.729z M16.514,16.514c-0.43,2.257-1.075,4.086-1.934,5.375
	c3.545-0.966,6.343-3.764,7.309-7.309C20.6,15.438,18.771,16.083,16.514,16.514z M2.111,14.58c0.969,3.545,3.763,6.343,7.31,7.309
	c-0.859-1.182-1.505-3.118-1.936-5.375C5.229,16.083,3.401,15.438,2.111,14.58z M8.667,15.333c2.151,0.32,4.515,0.32,6.666,0
	c0.105-1.076,0.216-2.15,0.216-3.333c0-1.182-0.11-2.257-0.216-3.333c-2.15-0.321-4.515-0.321-6.666,0
	C8.562,9.743,8.454,10.818,8.454,12C8.454,13.183,8.562,14.257,8.667,15.333z M16.729,8.883c0.107,0.968,0.215,2.043,0.215,3.117
	c0,1.075-0.107,2.149-0.215,3.118c3.655-0.646,5.481-2.043,5.481-3.118C22.211,10.926,20.277,9.528,16.729,8.883z M7.271,8.883
	C3.616,9.528,1.79,10.926,1.79,12c0,1.075,1.934,2.473,5.482,3.118C7.164,14.149,7.164,13.075,7.164,12
	C7.164,10.926,7.164,9.851,7.271,8.883z M16.514,7.485c2.257,0.431,4.086,1.076,5.375,1.936c-0.966-3.547-3.764-6.342-7.309-7.31
	C15.438,3.4,16.084,5.229,16.514,7.485z M9.421,2.111c-3.547,0.968-6.341,3.763-7.31,7.31c1.183-0.859,3.118-1.505,5.375-1.936
	C7.916,5.229,8.562,3.4,9.421,2.111z M12,1.79c-1.075,0-2.472,1.934-3.118,5.481c2.043-0.216,4.192-0.216,6.235,0
	C14.473,3.724,13.075,1.79,12,1.79z"
      />
    </SVGIcon>
  );
};

GlobeOutline.propTypes = IconPropTypes;

export default GlobeOutline;
