import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  useHasSearchResults,
  useIsSearchPending,
  useResultsDisplayMode,
  useTrackingContext,
} from '../../hooks';
import { LayoutWrapper } from '../../components/Layout';
import { SearchField } from '../../components/SearchField';
import { Notifications } from '../../components/Notifications';
import { DisplayModeTabs } from '../../components/DisplayModeTabs';
import { breakpointMobile, displayModeIds } from '../../constants';
import { ResultsMap } from '../../components/SearchResults/ResultsMap';
import { ResultsList } from '../../components/SearchResults/ResultsList';
import { Spinner } from '../../components/Spinner';

const ccid = 'storefinder-inline';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
`;

const SearchContainer = styled(Container)`
  flex: 0 0 auto;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  @media (min-width: ${breakpointMobile}px) {
    padding-bottom: 10px;
  }
`;

const NoResultsContainer = styled(Container)`
  flex: 0 0 auto;
  margin: 0 auto;
  min-height: 40px;
`;

const ResultsContainer = styled(Container)`
  flex: 1 0 auto;
  @media (max-width: ${breakpointMobile - 1}px) {
    height: ${({ displayMode }) =>
      displayMode === 'map' ? 'calc(100vh - 180px)' : 'auto'};
    min-height: ${({ displayMode }) =>
      displayMode === 'map' ? '400px' : 'none'};
    max-height: ${({ displayMode }) =>
      displayMode === 'map' ? '100vh' : 'none'};
  }
  @media (min-width: ${breakpointMobile}px) {
    height: ${({ displayMode }) =>
      displayMode === 'map' ? 'calc(100vh - 90px)' : 'auto'};
    min-height: ${({ displayMode }) =>
      displayMode === 'map' ? '480px' : 'none'};
    max-height: ${({ displayMode }) =>
      displayMode === 'map' ? '660px' : 'none'};
  }
`;

export const InlineWidget = () => {
  const trackingContext = useTrackingContext();
  const { track } = trackingContext;
  const hasResults = useHasSearchResults();
  const isPending = useIsSearchPending();
  const [displayMode] = useResultsDisplayMode();

  const [openImpressionTriggered, setOpenImpressionTriggered] = useState(false);

  const isInViewport = useCallback(() => {
    // storefinder-inline-search-container
    const el = document.querySelector('[data-ccid]');
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    // Partially visible elements return true:
    const isVisible = elemTop < window.innerHeight && elemBottom >= 0;

    return isVisible;
  }, []);

  useEffect(() => {
    if (!openImpressionTriggered && isInViewport()) {
      setOpenImpressionTriggered(true);
      track('widget_open_impression', {
        // local_store_list: [],
        // page_number: 0
      });
    }
  }, [openImpressionTriggered, isInViewport, track]);

  const handleScroll = useCallback(() => {
    if (isInViewport()) {
      setOpenImpressionTriggered(true);
      track('widget_open_impression', {});
    }
  }, [track, isInViewport]);

  useEffect(() => {
    if (!openImpressionTriggered) {
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [openImpressionTriggered, handleScroll]);

  return (
    <LayoutWrapper data-ccid="layout-wrapper">
      <Container data-ccid={`${ccid}-container`}>
        <SearchContainer
          displayMode={displayMode}
          data-ccid={`${ccid}-search-container`}>
          <SearchField />
        </SearchContainer>
        <DisplayModeTabs />
        {!hasResults && (
          <NoResultsContainer data-ccid={`${ccid}-noresults-container`}>
            <Notifications />
            {isPending && <Spinner />}
          </NoResultsContainer>
        )}
        {hasResults && (
          <ResultsContainer
            displayMode={displayMode}
            data-ccid={`${ccid}-results-container`}>
            {displayMode !== displayModeIds.LIST && <ResultsMap />}
            {displayMode === displayModeIds.LIST && <ResultsList />}
          </ResultsContainer>
        )}
      </Container>
    </LayoutWrapper>
  );
};

export default InlineWidget;
