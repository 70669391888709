import PropTypes from 'prop-types';

export const LocationType = PropTypes.shape({
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
});

export const MapMarkerType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  location: LocationType,
});

export const MapPropTypes = {
  activeMarkerColor: PropTypes.string,
  activeMarkerId: PropTypes.string,
  className: PropTypes.string,
  clusterClassName: PropTypes.string,
  clusterMarkerColor: PropTypes.string,
  clusterTextColor: PropTypes.string,
  clusterTextSize: PropTypes.number,
  highlightedMarkerId: PropTypes.string,
  isMobile: PropTypes.bool,
  mapPadding: PropTypes.shape({
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
  }),
  mapTheme: PropTypes.string,
  markerColor: PropTypes.string,
  markers: PropTypes.arrayOf(MapMarkerType),
  onClickMarker: PropTypes.func,
  searchLocation: LocationType,
};

export const SearchInputPropTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  resetKey: PropTypes.number,
  value: PropTypes.string,
  searchLocation: PropTypes.shape({
    address: PropTypes.string,
  }),
  style: PropTypes.any,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onLocationChange: PropTypes.func.isRequired,
  onAutoDetectAddress: PropTypes.func,
};
