import React from 'react';
import {
  SVGIcon,
  IconPropTypes,
  defaultIconSize,
  defaultIconColor,
} from '../SVGIcon';

const ArrowLeft = ({ color = defaultIconColor, size = defaultIconSize }) => (
  <SVGIcon
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    title="Left"
    uid="cc-icon-arrow-left">
    <path
      fill={color}
      d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z"
    />
  </SVGIcon>
);

ArrowLeft.propTypes = IconPropTypes;

export default ArrowLeft;
