import { searchActionTypes, searchPaginationModes } from './types';
import { findResultItem } from './utils';

export const getDefaultState = () => ({
  currentPage: 0,
  detectingLocation: false,
  filters: [],
  visibleFilters: [],
  isPending: false,
  itemsPerPage: 10,
  locationDetectionError: null,
  paginationMode: searchPaginationModes.SHOW_MORE,
  numPages: 0,
  numResults: 0,
  numOfFilteredResults: 0,
  responseError: null,
  resultsDisplayMode: null,
  searchLocation: null,
  searchResults: null,
  selectedResult: null,
  summary: null,
  visibleResults: null,
  customFields: [],
  customAttrLogic: false,
});

// LOGIC FOR AND || OR SHOULD GO HERE
export const applyFilters = (items = [], filters = [], operator) => {
  let filteredItems = items;

  if (filters.length > 0) {
    const filterGroups = {};
    filters.forEach(filterItem => {
      const { groupId } = filterItem;
      if (groupId) {
        if (!filterGroups[groupId]) {
          filterGroups[groupId] = {
            groupId,
            groupFilters: [filterItem],
          };
        } else {
          filterGroups[groupId].groupFilters.push(filterItem);
        }
      }
    });

    if (operator === 'OR') {
      filteredItems = filteredItems.filter(({ shop }) => {
        const cases = [];
        // all conditions fulfiled in combination of filters
        Object.values(filterGroups).forEach(({ groupId, groupFilters }) => {
          let matchesFilterGroup = false;

          groupFilters.forEach(({ filter, id }) => {
            if (typeof filter === 'function') {
              const matchesFilter = filter.call(null, shop);

              if (matchesFilter) {
                matchesFilterGroup = true;
              }
            }
          });

          cases.push(matchesFilterGroup);
        });

        return cases.includes(true);
      });
    }

    if (operator === 'AND') {
      filteredItems = filteredItems.filter(({ shop }) => {
        const cases = [];
        // all conditions fulfiled in combination of filters
        Object.values(filterGroups).forEach(({ groupId, groupFilters }) => {
          let matchesFilterGroup = false;

          groupFilters.forEach(({ filter, id }) => {
            if (typeof filter === 'function') {
              const matchesFilter = filter.call(null, shop);

              if (matchesFilter) {
                matchesFilterGroup = true;
              }
            }
          });

          cases.push(matchesFilterGroup);
        });

        return cases.indexOf(false) === -1;
      });
    }
  }

  return filteredItems;
};

export const updateVisibleResults = state => {
  const {
    searchResults,
    filters,
    currentPage,
    itemsPerPage,
    paginationMode,
    customAttrLogic,
    // listItemsPerPage,
    // mapItemsPerPage,
  } = state;
  if (!searchResults) {
    return state;
  }
  let resulPerpage = 0;
  if (state.resultsDisplayMode === 'map') {
    resulPerpage = itemsPerPage;
  } else {
    resulPerpage = itemsPerPage;
  }

  const filteredResults = searchResults
    ? applyFilters(searchResults, filters, customAttrLogic)
    : [];
  const numResults = filteredResults.length;
  const startIndex =
    paginationMode === searchPaginationModes.PAGES
      ? currentPage * resulPerpage
      : 0;
  const visibleResults =
    paginationMode !== searchPaginationModes.INFINITE
      ? filteredResults.slice(startIndex, (currentPage + 1) * resulPerpage)
      : filteredResults;

  return {
    ...state,
    numPages: Math.ceil(numResults / resulPerpage),
    numResults: numResults,
    visibleResults: visibleResults,
  };
};

const getNumOfFilteredResults = (searchResults, filters, customAttrLogic) => {
  if (!filters || filters.length === 0)
    return searchResults ? searchResults.length : 0;
  return applyFilters(searchResults, filters, customAttrLogic).length;
};

export const searchReducer = (prevState, action) => {
  const { type } = action;
  switch (type) {
    case searchActionTypes.INIT_STATE:
      return updateVisibleResults({
        ...prevState,
        ...action.initialState,
      });
    case searchActionTypes.DETECT_LOCATION:
      return {
        ...prevState,
        detectingLocation: action.pending || false,
        locationDetectionError: null,
      };
    case searchActionTypes.LOCATION_DETECTION_ERROR:
      return {
        ...prevState,
        detectingLocation: false,
        locationDetectionError: action.error,
      };
    case searchActionTypes.SEARCH_LOCATION:
      return {
        ...prevState,
        detectingLocation: false,
        searchLocation: action.searchLocation || null,
      };
    case searchActionTypes.REQUEST_RESULTS:
      return {
        ...prevState,
        isPending: true,
        responseError: null,
      };
    case searchActionTypes.RESPONSE_ERROR:
      return {
        ...prevState,
        isPending: false,
        responseError: action.error || null,
      };
    case searchActionTypes.SEARCH_RESULTS:
      return updateVisibleResults({
        ...prevState,
        isPending: false,
        searchResults: action.searchResults || null,
        searchLocation: action.searchLocation || prevState.searchLocation,
        currentPage: 0,
        // itemsPerPage: 0,
        summary: action.summary,
      });
    case searchActionTypes.SET_CUSTOM_FIELDS:
      return {
        ...prevState,
        customFields: action.customFields,
      };
    case searchActionTypes.SELECT_RESULT:
      return {
        ...prevState,
        selectedResult: findResultItem(action.id, prevState.searchResults),
      };
    case searchActionTypes.SET_FILTERS:
      return updateVisibleResults({
        ...prevState,
        filters: action.filters || [],
      });
    case searchActionTypes.SET_PAGE:
      return updateVisibleResults({
        ...prevState,
        currentPage: action.page || 0,
        paginationMode:
          action.paginationMode || searchPaginationModes.SHOW_MORE,
        selectedResult: null,
      });
    case searchActionTypes.SET_PAGINATION_MODE:
      return updateVisibleResults({
        ...prevState,
        currentPage: action.page || 0,
        paginationMode:
          action.paginationMode || searchPaginationModes.SHOW_MORE,
        selectedResult: null,
      });
    case searchActionTypes.SET_RESULTS_DISPLAY_MODE:
      return {
        ...prevState,
        resultsDisplayMode: action.resultsDisplayMode || null,
        selectedResult: null,
        itemsPerPage:
          action.resultsDisplayMode === 'map'
            ? action.mapItemsPerPage
            : action.listItemsPerPage,
      };
    case searchActionTypes.UPDATE_NUM_OF_FILTERED_RESULTS:
      return {
        ...prevState,
        numOfFilteredResults: getNumOfFilteredResults(
          prevState.searchResults,
          action.filters,
          prevState.customAttrLogic
        ),
      };
    case searchActionTypes.UPDATE_VISIBLE_FILTERS:
      return {
        ...prevState,
        visibleFilters: action.visibleFilters,
      };
    default:
      // throw Error('Invalid action '+type);
      return prevState;
  }
};

export default searchReducer;
