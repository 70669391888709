import { useContext, useState, useEffect } from 'react';
import { ConfigContext } from './provider';
import { fixDisplayLanguage } from './utils';

export const useConfig = () => useContext(ConfigContext);

export const useConfigKey = key => {
  const config = useConfig();
  const [value, setValue] = useState(config[key]);
  return [value, setValue];
};

export const useConfigValue = key => {
  const config = useConfig();
  return config[key];
};

export const useDisplayLanguage = () => {
  const { displaylanguage, locale, language } = useConfig();
  if (displaylanguage) {
    return fixDisplayLanguage(displaylanguage);
  }
  if (locale) {
    return fixDisplayLanguage(locale);
  }
  return language;
};

export const useLocale = () => {
  const { locale, language, country } = useConfig();
  const [value, setValue] = useState({
    locale,
    language,
    country,
  });
  useEffect(() => {
    setValue({
      locale,
      language,
      country,
    });
  }, [locale, language, country]);
  return value;
};
