import defaultTheme from './defaultTheme';
import { confKeys } from '../../constants';

const mergeColors = (baseColors, customColors) => {
  let colors = {};
  if (baseColors) {
    colors = {
      ...baseColors,
    };
  }
  if (customColors) {
    colors = {
      ...colors,
      ...customColors,
    };
  }
  return colors;
};

const mergeTypography = (baseTypography, customTypography) => {
  let typography = {};
  if (baseTypography) {
    typography = {
      ...baseTypography,
    };
  }
  if (customTypography) {
    typography = {
      ...typography,
      ...customTypography,
    };
  }
  return typography;
};

export const mergeTheme = (baseTheme = {}, otherTheme = {}) => {
  const mergedTheme = {
    ...baseTheme,
    ...otherTheme,
  };
  if (mergedTheme.colors) {
    mergedTheme.colors = mergeColors(baseTheme.colors, otherTheme.colors);
  }
  if (mergedTheme.typography) {
    mergedTheme.typography = mergeTypography(
      baseTheme.typography,
      otherTheme.typography
    );
  }
  return mergedTheme;
};

export const createTheme = customTheme => mergeTheme(defaultTheme, customTheme);

export const injectSkinColor = (theme, skinColor) => {
  const mergedTheme = createTheme(theme);
  return {
    ...mergedTheme,
    colors: {
      ...mergedTheme.colors,
      highlight: skinColor || mergedTheme.colors.highlight,
      highlightActive: skinColor || mergedTheme.colors.highlightActive,
      mapMarker: skinColor || mergedTheme.colors.mapMarker,
    },
  };
};

/**
 * @param theme {object}
 * @param configColors {object|string}
 * @returns mergedTheme {object}
 */
export const injectThemeColors = (theme, configColors) => {
  if (typeof configColors === 'string') {
    return injectSkinColor(theme, configColors);
  }
  const mergedTheme = createTheme(theme);
  if (typeof configColors !== 'object') {
    return mergedTheme;
  }
  return {
    ...mergedTheme,
    colors: {
      ...mergedTheme.colors,
      highlight:
        configColors[confKeys.SKIN_COLOR] || mergedTheme.colors.highlight,
      highlightActive:
        configColors[confKeys.SKIN_ACTIVE_COLOR] ||
        configColors[confKeys.SKIN_COLOR] ||
        mergedTheme.colors.highlightActive,
      mapMarker:
        configColors[confKeys.MARKER_COLOR] ||
        configColors[confKeys.SKIN_COLOR] ||
        mergedTheme.colors.mapMarker,
    },
  };
};
