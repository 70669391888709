import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  position: relative;
  direction: ${({ theme }) => (theme.rtl === true ? 'rtl' : 'ltr')};
  margin: 0 auto;
  max-width: 1200px;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;

export const Box = styled.div`
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Row = styled.div`
  flex: 1 1 auto;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: stretch;
`;

export const Cell = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Container = styled.div`
  display: block;
  overflow: hidden;
  position: relative;
`;

export const ScrollContainer = styled.div`
  position: absolute;
  top: 0;
  left: ${({ theme, cropScrollbar = 0 }) =>
    theme.rtl ? `${-cropScrollbar}px` : 0};
  right: ${({ theme, cropScrollbar = 0 }) =>
    theme.rtl ? 0 : `${-cropScrollbar}px`};
  scrollbar-width: none;
  width: auto;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

export const Spacer = styled.div`
  display: block;
  flex: 0 0 ${({ size = 10 }) => `${size}px`};
`;

export const Textblock = styled.div`
  margin: 0;
  max-width: 100%;
  text-overflow: ellipsis;
`;
