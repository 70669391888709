import { textKeys } from '../../constants';

export const dateWeekdaysShort = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
export const orderedWeekdaysShort = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];

export function getOpeningHoursPerDay(openingHours) {
  const openingHoursPerDays = {};
  if (openingHours && openingHours.length) {
    openingHours.forEach(data => {
      const shortDay = data.Weekday.substr(0, 2).toLowerCase();
      if (!openingHoursPerDays[shortDay]) {
        openingHoursPerDays[shortDay] = [];
      }
      openingHoursPerDays[shortDay].push(data);
    });
  }
  return openingHoursPerDays;
}

/**
 * @param openingHoursPerDays
 * @param currentTime {number|string|null}
 * @returns {{isOpenNow: boolean, hoursOpenToday: null, hoursOpenNow: null}}
 */
export function getOpeningToday(openingHoursPerDays, currentTime = null) {
  const now = new Date();
  if (currentTime !== null) {
    switch (typeof currentTime) {
      case 'number':
        if (!Number.isNaN(currentTime) && currentTime > 0) {
          now.setTime(currentTime);
        }
        break;
      case 'string':
        const currentDate = new Date(currentTime);
        if (currentDate.getTime() > 0) {
          now.setTime(currentDate.getTime());
        }
        break;
      default:
        break;
    }
  }
  const currentDay = dateWeekdaysShort[now.getDay()];

  let isOpenNow = false;
  let hoursOpenToday = null;
  let hoursOpenNow = null;
  if (
    openingHoursPerDays[currentDay] &&
    openingHoursPerDays[currentDay].length
  ) {
    hoursOpenToday = openingHoursPerDays[currentDay]
      .map(data => {
        const openTimeArr = data.Opentime.split(':').map(time =>
          parseInt(time)
        );
        const closeTimeArr = data.Closetime.split(':').map(time =>
          parseInt(time)
        );
        const openingTime = new Date(now.getTime());
        const closingTime = new Date(now.getTime());
        openingTime.setHours(...openTimeArr);
        closingTime.setHours(...closeTimeArr);

        if (!isOpenNow) {
          isOpenNow = !!(
            now.getTime() >= openingTime.getTime() &&
            now.getTime() <= closingTime.getTime()
          );
          if (isOpenNow) {
            hoursOpenNow = `${data.Opentime} - ${data.Closetime}`;
          }
        }
        return `${data.Opentime} - ${data.Closetime}`;
      })
      .join(' ');
  }

  return {
    isOpenNow,
    hoursOpenToday,
    hoursOpenNow,
  };
}

export function getIsOpenNow(openingHours) {
  const now = new Date();
  const currentDay = dateWeekdaysShort[now.getDay()];
  const openingHoursPerDay = getOpeningHoursPerDay(openingHours);
  const todaysOpeningHours = openingHoursPerDay[currentDay];
  if (!todaysOpeningHours || !todaysOpeningHours.length) {
    return false;
  }
  let isOpenNow = false;
  todaysOpeningHours.map(data => {
    const openTimeArr = data.Opentime.split(':').map(time => parseInt(time));
    const closeTimeArr = data.Closetime.split(':').map(time => parseInt(time));
    const openingTime = new Date();
    const closingTime = new Date();
    openingTime.setHours(...openTimeArr);
    closingTime.setHours(...closeTimeArr);
    if (!isOpenNow) {
      isOpenNow = !!(
        now.getTime() >= openingTime.getTime() &&
        now.getTime() <= closingTime.getTime()
      );
    }
    return data;
  });
  return isOpenNow;
}

export function getOpeningHoursLocalized({ shop, t, currentTime }) {
  // 24/7 open stores
  const { Open247 } = shop;
  if (Open247) {
    return {
      isOpenNow: true,
      labelOpenNow: t(textKeys.LABEL_NOW_OPEN),
      hoursOpenNow: '00:00 - 23:59',
      groupedOpeningHours: [
        {
          label: `${t(textKeys[`WEEKDAYS_SHORT_MO`])} - ${t(
            textKeys[`WEEKDAYS_SHORT_SU`]
          )}`,
          value: '00:00 - 23:59',
        },
      ],
    };
  }
  // Create summary
  const openingHoursPerDays = getOpeningHoursPerDay(shop.OpeningHours);
  const { isOpenNow, hoursOpenNow } = getOpeningToday(
    openingHoursPerDays,
    currentTime
  );
  const labelOpenNow = t(
    isOpenNow ? textKeys.LABEL_NOW_OPEN : textKeys.LABEL_NOW_CLOSED
  );

  // Create detailed list
  const hoursOpenWeek = [];
  const groupedOpeningHours = [];

  orderedWeekdaysShort.forEach(day => {
    if (openingHoursPerDays[day]) {
      const hoursOpen = openingHoursPerDays[day].map(data => {
        return `${data.Opentime} - ${data.Closetime}`;
      });
      const labelI18Key = textKeys[`WEEKDAYS_SHORT_${day.toUpperCase()}`];
      hoursOpenWeek.push({
        label: t(labelI18Key),
        value: hoursOpen.join('####'),
      });
    }
  });

  let currentValue = null;
  let fromLabel = null;
  let toLabel = null;

  hoursOpenWeek.forEach(data => {
    if (!currentValue) {
      currentValue = data.value;
      fromLabel = data.label;
      toLabel = null;
    } else {
      if (data.value === currentValue) {
        toLabel = data.label;
      } else {
        groupedOpeningHours.push({
          label: toLabel ? `${fromLabel} - ${toLabel}` : fromLabel,
          value: currentValue,
        });
        currentValue = data.value;
        fromLabel = data.label;
        toLabel = null;
      }
    }
  });

  if (currentValue) {
    groupedOpeningHours.push({
      label: toLabel ? `${fromLabel} - ${toLabel}` : fromLabel,
      value: currentValue,
    });
    currentValue = null;
  }

  return {
    isOpenNow: isOpenNow,
    labelOpenNow: labelOpenNow,
    hoursOpenNow: hoursOpenNow,
    groupedOpeningHours: groupedOpeningHours,
  };
}
