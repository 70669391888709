import {
  countriesUsingCommaDecimalSeparator,
  factorMiles,
  countriesUsingMiles,
  distanceUnits,
} from '../constants';

export function kmToMiles(km) {
  return km * factorMiles;
}

export function milesToKm(mi) {
  return mi / factorMiles;
}

export function getDistanceUnit(country) {
  return country && countriesUsingMiles.includes(country.toUpperCase())
    ? distanceUnits.MILES
    : distanceUnits.KM;
}

export function formatNumber(value, numDigits, locale) {
  const countryCode =
    locale.length === 5 ? locale.replace('_', '-').split('-')[1] : locale;
  const decimalSeparator =
    countriesUsingCommaDecimalSeparator.indexOf(countryCode.toUpperCase()) ===
    -1
      ? '.'
      : ',';
  return value.toFixed(numDigits).replace('.', decimalSeparator);
}
