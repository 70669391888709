import React from 'react';
import PropTypes from 'prop-types';

export const AbstractConfigComponent = ({
  token,
  subid,
  trackingid,
  mapsApiKey,
  locale = 'en-US',
  displaylanguage,
  resultsDisplayMode = 'list',
  resultsDisplayModeOrder = ['map', 'list'],
  searchRadius = 50,
  itemsPerPage = 10,
  maxResults = 50,
  mapTheme = 'grey',
  skinColor = '#000000',
  ...otherProps
}) => {
  return <div {...otherProps} />;
};

AbstractConfigComponent.propTypes = {
  /**
   * The token which is required in LocalStore queries.
   */
  token: PropTypes.string,
  /**
   * A subid can optionally be set for LocalStore queries.
   */
  subid: PropTypes.string,
  /**
   * A trackingid can optionally be passed in for campaign tracking.
   * The value is transmitted with each LocalStore query request.
   */
  trackingid: PropTypes.string,
  /**
   * The API key used for map display and geocoding services.
   */
  mapsApiKey: PropTypes.string,
  /**
   * The Maps API provider.
   */
  mapsApiProvider: PropTypes.oneOf(['google', 'mapbox']),
  /**
   * The locale which is used for texts, in LocalStore queries, map and geocoding services.
   */
  locale: PropTypes.string,
  /**
   * The display language that is used for interface texts, map and geocoding services can optionally be overwritten.
   */
  displaylanguage: PropTypes.string,
  /**
   * How to display the search results.
   */
  resultsDisplayMode: PropTypes.oneOf(['tabs', 'list', 'map']),
  /**
   * How to display the search results.
   */
  resultsDisplayModeOrder: PropTypes.array,
  /**
   * The search radius in km for a given search location.
   */
  searchRadius: PropTypes.number,
  /**
   * How many list items should be shown on each page.
   */
  itemsPerPage: PropTypes.number,
  /**
   * Limit the results returned from a LocalStore query.
   */
  maxResults: PropTypes.number,
  /**
   * The map theme name.
   */
  mapTheme: PropTypes.oneOf(['grey', 'color']),
  /**
   * The theme skin color used for component styling, in hex format.
   */
  skinColor: PropTypes.string,
};
