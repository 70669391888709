import React, { useReducer, useCallback, createContext } from 'react';
import PropTypes from 'prop-types';

import { confKeys } from '../../constants';
import trackingReducer from './reducer';

import { getDeviceType } from '../../utils/deviceType';

import { useConfigValue } from '../../hooks';

export const TrackingContext = createContext({
  track: () => {},
});

export const TrackingProvider = ({ children }) => {
  // const anon_t = useConfigValue(confKeys.ANON_T);

  const defaultTrackingData = {
    data_locale: useConfigValue(confKeys.LOCALE),
    data_displaylanguage: useConfigValue(confKeys.DISPLAY_LANGUAGE),
    data_widgetid: useConfigValue(confKeys.WIDGET_ID),
    data_subid: useConfigValue(confKeys.SUB_ID) || '',
    data_subid_id: useConfigValue(confKeys.SUBID_ID) || 0,
    data_trackingid: useConfigValue(confKeys.TRACKING_ID) || '',
    view_id: useConfigValue(confKeys.VIEW_ID) || '',
    web_url: window.location.href,
    widget_size: 'default',
    productLine: 'gsf',
    t_utm_campaign: useConfigValue(confKeys.T_UTM_CAMPAIGN),
    t_utm_content: useConfigValue(confKeys.T_UTM_CONTENT),
    t_utm_medium: useConfigValue(confKeys.T_UTM_MEDIUM),
    t_utm_source: useConfigValue(confKeys.T_UTM_SOURCE),
    t_utm_term: useConfigValue(confKeys.T_UTM_TERM),
    device_type: getDeviceType(),
    // t_generic_tracking: useConfigValue(confKeys.T_UTM_TRACKING),
    [confKeys.GTM_TRACKING]: useConfigValue(confKeys.GTM_TRACKING),
  };

  // if (useConfigValue(confKeys.ANON_T)) {
  //   defaultTrackingData[confKeys.ANON_T] = anon_t;
  // }

  if (useConfigValue(confKeys.WIDGET_MODE) !== 'popup') {
    defaultTrackingData['inline_widget'] = true;
  }

  const [, dispatch] = useReducer(trackingReducer, {
    contextData: defaultTrackingData,
  });

  const track = useCallback((eventName, eventData) => {
    dispatch({
      type: 'TRACK',
      payload: {
        eventName,
        eventData,
      },
    });
  }, []);

  return (
    <TrackingContext.Provider value={{ track }}>
      {children}
    </TrackingContext.Provider>
  );
};

TrackingProvider.propTypes = {
  children: PropTypes.node,
};
