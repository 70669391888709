export const distanceUnits = {
  KM: 'km',
  MILES: 'mi',
  AUTO: 'automatic'
};

export const factorMiles = 0.62137119224;

export const countriesUsingMiles = [
  'LR', // Liberia
  'MM', // Myanmar
  'US', // United States
  'UK',
  'GB',
];

export const countriesUsingCommaDecimalSeparator = [
  'AM',
  'AR',
  'AT',
  'AZ',
  'BA',
  'BE',
  'BG',
  'BO',
  'BR',
  'BY',
  'CA',
  'CH',
  'CL',
  'CM',
  'CO',
  'CR',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EC',
  'EE',
  'ES',
  'FI',
  'FO',
  'FR',
  'GE',
  'GL',
  'HR',
  'HU',
  'ID',
  'IS',
  'IT',
  'KZ',
  'LB',
  'LT',
  'LU',
  'LV',
  'MA',
  'MD',
  'MK',
  'MO',
  'MZ',
  'NL',
  'NO',
  'PE',
  'PL',
  'PT',
  'PY',
  'RO',
  'RS',
  'RU',
  'SE',
  'SI',
  'SK',
  'TN',
  'TR',
  'UA',
  'UY',
  'UZ',
  'VE',
  'VN',
  'ZA',
];
