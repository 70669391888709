import styled from 'styled-components';
import { PinContainer } from '../LocalStorePin/LocalStorePinStyled';

const getThemeColor = ({ themeColor, theme }) => {
  return themeColor || theme.colors.highlight;
};

const cardShadow = '0 0 5px rgba(0, 0, 0, 0.1)';

export const Card = styled.li`
  flex: 1 1 auto;
  position: relative;
  display: block;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ active }) => (active ? cardShadow : 'none')};
  color: ${({ theme }) => theme.colors.text};
  margin: 0 0 10px !important;
  min-height: 120px;
  max-width: 100%;
  padding: 16px 0;
  font-size: 13px;
  line-height: 1.33;
  cursor: ${({ active = false }) => (active ? 'default' : 'pointer')};
  transition: box-shadow 0.2s ease;

  :hover {
    border-color: ${({ theme }) => theme.colors.border};
    box-shadow: ${cardShadow};
    & ${PinContainer} {
      color: ${({ theme }) => theme.colors.mapMarker} !important;
    }
  }
`;

export const LocationContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 80px;
`;

export const DetailsContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 10px 0;
  max-width: 100%;
  overflow: hidden;

  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    max-width: 170px;
  }
`;

export const ContactIconsContainer = styled.div`
  flex: 0 0 60px;
  width: 60px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`;

const CardTab = styled.div`
  display: ${({ active = false }) => (active ? 'block' : 'none')};
  position: absolute;
  overflow: hidden;
  pointer-events: none;
  &:before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 10px;
    background-color: ${getThemeColor};
  }
`;

export const CardTabSide = styled(CardTab)`
  left: ${({ theme }) => (theme.rtl ? 'auto' : '0')};
  right: ${({ theme }) => (theme.rtl ? '0' : 'auto')};
  top: -1px;
  bottom: -1px;
  height: auto;
  width: 5px;
  &:before {
    top: 0;
    left: ${({ theme }) => (theme.rtl ? 'auto' : '0')};
    right: ${({ theme }) => (theme.rtl ? '0' : 'auto')};
    width: 20px;
    height: 100%;
  }
`;

export const CardTabTop = styled(CardTab)`
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: auto;
  height: 5px;
  width: auto;
  &:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
  }
`;

export const ChevronIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);

  svg {
    width: 18px;
    height: 18px;

    &:first-child {
      position: relative;
      top: 13px;
    }

    path {
      fill: rgba(0, 0, 0, 0.25);
    }
  }
`;
