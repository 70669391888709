import styled from 'styled-components';

export const PinContainer = styled.div`
  color: ${({ theme, active }) =>
    active ? theme.colors.mapMarker : theme.colors.mediumGrey};
  font-size: 0;
  min-height: 36px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

export const Distance = styled.div`
  font-size: 11px;
  text-align: center;
`;
