/**
 * @type {Promise<{lat: number, lng: number}>|null}
 */
let dectionPromise = null;

export const locationDetectionErrors = {
  NOT_SUPPORTED: 'not_supported',
  ACCESS_REJECTED: 'access_rejected',
};

/**
 * @returns {Promise<{lat: number, lng: number}>}
 */
export const detectClientLocation = () => {
  if (dectionPromise !== null) {
    return dectionPromise;
  }
  dectionPromise = new Promise((resolve, reject) => {
    if (
      !navigator ||
      !navigator.geolocation ||
      typeof navigator.geolocation.getCurrentPosition !== 'function'
    ) {
      reject(locationDetectionErrors.NOT_SUPPORTED);
      return;
    }
    navigator.geolocation.getCurrentPosition(
      position => {
        if (
          position.coords &&
          typeof position.coords.latitude === 'number' &&
          typeof position.coords.longitude === 'number'
        ) {
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        } else {
          reject(locationDetectionErrors.ACCESS_REJECTED);
        }
        dectionPromise = null;
      },
      () => {
        reject(locationDetectionErrors.ACCESS_REJECTED);
        dectionPromise = null;
      }
    );
  });
  return dectionPromise;
};

export const getHasAddress = location => {
  if (!location) return false;
  if (location.address) return true;
  if (location.lat && location.lng) return true;
  return false;
};

export const getSearchMargin = (hasAddress, rtl) => {
  if (!hasAddress) return '0 auto';
  if (rtl) return '0 0 0 auto';
  return '0 auto 0 0';
};
