import React, { useCallback } from 'react';
import styled from 'styled-components';
import { breakpointMobile } from '../../constants';
import { BaseButton } from '../Button';
import { CloseThinIcon } from '../Icon/ui';
import { confKeys } from '../../constants';
import { useConfigValue } from '../../contexts/config';

const ccid = 'popup-close-button';

const getThemeColor = ({ themeColor, theme }) => {
  return themeColor || theme.colors.highlight;
};

const Button = styled(BaseButton)`
  position: absolute;
  z-index: 9400;
  width: 40px;
  height: 40px;
  background-color: ${getThemeColor};
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: ${breakpointMobile - 1}px) {
    bottom: 0;
    left: 50%;
    border-radius: 20px 20px 0 0;
    margin-left: -20px;
  }
  @media (min-width: ${breakpointMobile}px) {
    top: 0;
    left: ${({ theme }) => (theme.rtl ? 'auto' : '-40px')};
    right: ${({ theme }) => (theme.rtl ? '-40px' : 'auto')};
    border-radius: ${({ theme }) =>
      theme.rtl ? '0 20px 20px 0' : '20px 0 0 20px'};
  }
`;

export const PopupCloseButton = props => {
  const { onClose } = props;
  const themeColor = useConfigValue(confKeys.COLOR_SCHEME);

  const handleKeyDown = useCallback(
    event => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <Button
      data-ccid={`${ccid}`}
      themeColor={themeColor}
      {...props}
      onKeyDown={handleKeyDown}
      onClick={onClose}
      aria-label="close">
      <CloseThinIcon size={40} />
    </Button>
  );
};

export default PopupCloseButton;
