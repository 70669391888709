import React from 'react';
import { confKeys } from '../../../constants';
import { useConfig } from '../../../contexts/config';

import {
  SVGIcon,
  IconPropTypes,
  defaultIconSize,
  defaultIconColor,
} from '../SVGIcon';

const MapMarker = ({
  color = defaultIconColor,
  size = defaultIconSize,
  strokeColor = '#ffffff',
}) => {
  let pinColor = useConfig()[confKeys.MAP_PIN_COLOR];
  return (
    <SVGIcon
      width={size}
      height={size}
      viewBox="0 0 24 24"
      title="Map Marker Icon"
      uid="cc-icon-map-marker">
      <path
        fill={pinColor || color}
        stroke={strokeColor}
        strokeWidth={2}
        d="M11.999,1c-4.905,0-8.8,3.941-8.8,8.8c0,6.546,8.8,13.2,8.8,13.2s8.802-6.654,8.802-13.2
	C20.801,4.875,16.902,1,11.999,1z M11.999,12c-1.214,0-2.2-0.986-2.2-2.201c0-1.215,0.986-2.2,2.2-2.2
	c1.216,0,2.198,0.985,2.198,2.2C14.197,11.014,13.215,12,11.999,12z"
      />
    </SVGIcon>
  );
};

MapMarker.propTypes = IconPropTypes;

export default MapMarker;
