/**
 * @param {Node} el
 * @param {string} eventType
 * @param {function} handler
 * @param {*} options
 */
export const addEvent = (el, eventType, handler, options) => {
  if (!el) {
    return;
  }
  if (options === undefined) {
    options = true;
  }
  if (el.addEventListener) {
    el.addEventListener(eventType, handler, options);
  } else if (el.attachEvent) {
    el.attachEvent('on' + eventType, handler);
  } else {
    el['on' + eventType] = handler;
  }
};

/**
 * @param {Node} el
 * @param {string} eventType
 * @param {function} handler
 * @param {*} options
 */
export const removeEvent = (el, eventType, handler, options) => {
  if (!el) {
    return;
  }
  if (options === undefined) {
    options = true;
  }
  if (el.removeEventListener) {
    el.removeEventListener(eventType, handler, options);
  } else if (el.detachEvent) {
    el.detachEvent('on' + eventType, handler);
  } else {
    el['on' + eventType] = null;
  }
};

/**
 * @returns {boolean}
 */
export const hasTouchEvents = () => 'ontouchstart' in window;

const findInArray = (array, callback) => {
  for (let i = 0, length = array.length; i < length; i++) {
    if (callback.apply(callback, [array[i], i, array])) return array[i];
  }
};

/**
 * @param {TouchEvent} event
 * @param {number} identifier
 * @returns {{clientX: number, clientY: number}|null}
 */
export const getTouch = (event, identifier) => {
  return (
    (event.targetTouches &&
      findInArray(
        event.targetTouches,
        touch => identifier === touch.identifier
      )) ||
    (event.changedTouches &&
      findInArray(
        event.changedTouches,
        touch => identifier === touch.identifier
      ))
  );
};

/**
 * @param {TouchEvent} e
 * @returns {number}
 */
export const getTouchIdentifier = e => {
  if (e.targetTouches && e.targetTouches[0])
    return e.targetTouches[0].identifier;
  if (e.changedTouches && e.changedTouches[0])
    return e.changedTouches[0].identifier;
};

/**
 * @param e
 * @returns {{pageY: *, pageX: *}}
 */
export const getMousePosition = e => {
  return {
    pageX: e.pageX,
    pageY: e.pageY,
  };
};

export const getTouchPosition = (e, identifier) => {
  const touch = getTouch(e, identifier);
  if (touch) {
    return {
      pageX: touch.pageX,
      pageY: touch.pageY,
    };
  }
};
let elementMatchesFunc = '';

export const isFunction = func => {
  return (
    typeof func === 'function' ||
    Object.prototype.toString.call(func) === '[object Function]'
  );
};

export const elementMatches = (el, selector) => {
  if (!elementMatchesFunc) {
    elementMatchesFunc = findInArray(
      [
        'matches',
        'webkitMatchesSelector',
        'mozMatchesSelector',
        'msMatchesSelector',
        'oMatchesSelector',
      ],
      method => isFunction(el[method])
    );
  }

  if (!isFunction(el[elementMatchesFunc])) {
    return false;
  }

  return el[elementMatchesFunc](selector);
};

export const findElementRecursive = (element, selector, rootNode = null) => {
  if (!element) return null;
  if (elementMatches(element, selector)) return element;
  if (rootNode && element === rootNode) {
    return null;
  }
  return element.parentNode
    ? findElementRecursive(element.parentNode, selector)
    : null;
};

export const findClickoutType = (el, defaultType, rootNode) => {
  const clickoutTarget = findElementRecursive(
    el,
    '[data-clickout-type]:not([data-clickout-type=""])',
    rootNode
  );
  return clickoutTarget
    ? clickoutTarget.getAttribute('data-clickout-type')
    : defaultType;
};
