import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { LocalStoresResultShape } from '../../../api';
import { Row } from '../../Layout';
import {
  LocalStoreAddress,
  LocalStoreContactIcons,
  LocalStoreName,
  LocalStoreOpening,
  LocalStorePin,
  LocalStoreCustomAttr,
} from '../';
import {
  Card,
  CardTabTop,
  ContactIconsContainer,
  DetailsContainer,
  LocationContainer,
} from '../LocalStoreCard';
import { confKeys } from '../../../constants';
import { useConfigValue } from '../../../contexts/config';

import { CaretDownIcon } from '../../Icon';
import { ChevronIconContainer } from '../LocalStoreCard';

const ccid = 'localstore-slideritem';

export const LocalStoreSliderItem = ({
  hideContent = false,
  item = {},
  onClickItem,
  selected,
  expandOpeningOnSelect,
  showWhatsApp,
  newItemSelected,
}) => {
  const { id, shop } = item;
  const customAttributes = useConfigValue(confKeys.FILTER_CUSTOM_FIELDS);
  const attributesList = customAttributes
    ? customAttributes.custom_attributes_selection
    : [];

  const handleClickCard = useCallback(
    event => {
      if (onClickItem && id) {
        onClickItem(event, id);
      }
    },
    [id, onClickItem]
  );

  const isActive = !!selected;
  const hasOpeningHours =
    shop && shop.OpeningHours && shop.OpeningHours.length > 0;
  return (
    <Card
      data-ccid={ccid}
      data-itemid={id}
      role="listitem"
      onClick={handleClickCard}
      active={selected}>
      {!hideContent && shop && (
        <Row data-ccid={`${ccid}-inner`}>
          <LocationContainer data-ccid={`${ccid}-location-container`}>
            <LocalStorePin shop={shop} active={selected} />
          </LocationContainer>
          <DetailsContainer data-ccid={`${ccid}-details-container`}>
            <LocalStoreName shop={shop} cropped={true} active={selected} />
            <LocalStoreCustomAttr
              shop={shop}
              allowedAttributes={attributesList}
            />
            <LocalStoreAddress shop={shop} cropped={!isActive} />
            {hasOpeningHours && (
              <LocalStoreOpening
                shop={shop}
                expanded={isActive && expandOpeningOnSelect}
              />
            )}
          </DetailsContainer>
          <ContactIconsContainer>
            <LocalStoreContactIcons showWhatsApp={showWhatsApp} shop={shop} />
          </ContactIconsContainer>
        </Row>
      )}
      {!hideContent && <CardTabTop active={selected} />}
      {!isActive && newItemSelected && (
        <ChevronIconContainer>
          <CaretDownIcon />
          <CaretDownIcon />
        </ChevronIconContainer>
      )}
    </Card>
  );
};

LocalStoreSliderItem.propTypes = {
  index: PropTypes.number,
  item: LocalStoresResultShape.isRequired,
  onClickItem: PropTypes.func,
  selected: PropTypes.bool,
  expandOpeningOnSelect: PropTypes.bool,
  hideContent: PropTypes.bool,
};

export default LocalStoreSliderItem;
