import { getIsOpenNow } from './openingHours';
import {
  getCustomLocalStoreType,
  getCustomFieldValues,
} from './localStoreInfo';

export const isB2B = localStore =>
  localStore &&
  localStore.CustomerRestriction &&
  localStore.CustomerRestriction.CommercialCustomers === '1';

export const isB2C = localStore =>
  localStore &&
  localStore.CustomerRestriction &&
  localStore.CustomerRestriction.PrivateCustomers === '1';

export const isOpenNow = localStore =>
  localStore &&
  localStore.OpeningHours &&
  getIsOpenNow(localStore.OpeningHours);

export const getCustomFieldFilter = (fieldName, value) => {
  return localStore => {
    const customFieldValues = getCustomFieldValues(localStore, fieldName);

    return customFieldValues ? customFieldValues.indexOf(value) !== -1 : false;
  };
};

export const getCustomLocalStoreTypeFilter = storeTypes => {
  return localStore => {
    const storeType = getCustomLocalStoreType(localStore);
    return storeTypes.indexOf(storeType) !== -1;
  };
};
