import React from 'react';
import PropTypes from 'prop-types';
import { Spacer } from '../Layout';
import { PrimaryButton, SecondaryButton } from './ButtonStyled';
import { confKeys } from '../../constants';
import { useConfigValue } from '../../contexts/config';

export const buttonKinds = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const iconPositions = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const Button = ({
  children,
  icon: Icon,
  iconPosition = iconPositions.LEFT,
  kind = buttonKinds.PRIMARY,
  ...otherProps
}) => {
  const ButtonComponent =
    kind === buttonKinds.SECONDARY ? SecondaryButton : PrimaryButton;
  const themeColor = useConfigValue(confKeys.COLOR_SCHEME);

  return (
    <ButtonComponent themeColor={themeColor} {...otherProps}>
      {Icon && iconPosition === iconPositions.LEFT && (
        <>
          <Icon size={20} />
          <Spacer size={10} />
        </>
      )}
      {children}
      {Icon && iconPosition === iconPositions.RIGHT && (
        <>
          <Spacer size={10} />
          <Icon size={20} />
        </>
      )}
    </ButtonComponent>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.func,
  iconPosition: PropTypes.oneOf(Object.values(iconPositions)),
  kind: PropTypes.oneOf(Object.values(buttonKinds)),
};

export default Button;
