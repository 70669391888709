import styled from 'styled-components';

export const Checkbox = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
  appearance: none;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  height: 20px;
  width: 20px;
  /* outline: none; */
  position: relative;
  transition: border-color 0.2s ease;
  flex-shrink: 0;
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 4px;
    top: 4px;
    height: 10px;
    width: 10px;
    background-color: ${({ theme }) => theme.colors.highlight};
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  }
  &:checked:after {
    opacity: 1;
    transform: none;
  }
  &:not(:disabled):hover {
    border-color: ${({ theme }) => theme.colors.highlight};
  }
`;

export default Checkbox;
