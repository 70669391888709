export const defaultTheme = {
  typography: {
    main: {
      fontFamily: 'inherit',
      fontWeight: 'normal',
    },
    bold: {
      fontFamily: 'inherit',
      fontWeight: 'bold',
    },
  },
  colors: {
    text: '#000000',
    textSecondary: '#666666',
    background: '#f7f7f7',
    border: 'rgba(0,0,0,.15)', //'#c7c7c7'
    textLink: '#666666',
    highlight: '#000000',
    highlightActive: '#ee6452',
    textLinkHover: '#000000',
    spinner: '#666666',
    mapMarker: '#ee6452',
    black: '#000000',
    white: '#ffffff',
    lightGrey: '#efefef',
    lighterGrey: '#dadada',
    mediumGrey: '#b2b2b2',
    darkerGrey: '#808080',
    darkGrey: '#666666',
  },
};

export default defaultTheme;
