import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { markerBounceAnim } from '../../styles/animations';
import { useTheme } from '../../contexts/theme';
import { useMapsComponents } from '../../contexts/maps';
import { useConfig } from '../../contexts/config';
import {
  useSearchLocation,
  useSearchResults,
  useActiveSearchResult,
  useSetActiveSearchResult,
  useIsSearchPending
} from '../../contexts/search';
import { confKeys } from '../../constants';
import { Spinner } from '../../components/Spinner';

export const MapContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  & .cc-marker-cluster {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    background-color: ${({ theme }) => theme.colors.mapMarker};
  }
  & .cc-marker {
    cursor: pointer;
  }
  & .cc-marker-active {
    cursor: default;
  }
  & .cc-marker-highlighted {
    animation: ${markerBounceAnim} 0.6s infinite ease-out;
  }
`;

const markerClassNames = {
  marker: 'cc-marker',
  active: 'cc-marker-active',
  highlighted: 'cc-marker-highlighted',
};

export const Map = ({ mapPadding }) => {
  const theme = useTheme();
  const { mapTheme } = useConfig();
  const { Map: MapComponent } = useMapsComponents();
  const searchLocation = useSearchLocation();
  const searchResults = useSearchResults();
  const selectedItem = useActiveSearchResult();
  const setActiveItem = useSetActiveSearchResult();
  const markerPinColor = useConfig()[confKeys.MAP_PIN_COLOR];
  const isPending = useIsSearchPending();

  const handleClickMarker = useCallback(
    id => {
      setActiveItem(id);
    },
    [setActiveItem]
  );

  const activeMarkerId = selectedItem && selectedItem.id;

  return !isPending ? (
    <MapContainer data-ccid="map-container">
      {searchLocation && MapComponent && (
        <MapComponent
          searchLocation={searchLocation}
          markers={searchResults}
          mapPadding={mapPadding}
          mapTheme={mapTheme}
          markerColor={markerPinColor || theme.colors.mapMarker}
          markerClassNames={markerClassNames}
          activeMarkerColor={markerPinColor}
          activeMarkerId={activeMarkerId || undefined}
          clusterClassName="cc-marker-cluster"
          clusterTextColor={theme.colors.white}
          clusterMarkerColor={markerPinColor || theme.colors.mapMarker}
          onClickMarker={handleClickMarker}
        />
      )}
    </MapContainer>
  ) : <Spinner />;
};

Map.propTypes = {
  mapPadding: PropTypes.shape({
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
  }),
};

export default Map;
