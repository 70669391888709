import styled from 'styled-components';
import { Container } from '../../Layout';

export const OpeningsContainer = styled(Container)`
  flex: 0 0 30%;
  width: 30%;
  padding: 0 10px;
`;

export const ContactsContainer = styled(Container)`
  flex: 0 0 30%;
  width: 30%;
  padding: 0 10px;
`;
