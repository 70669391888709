import React from 'react';
import { apiScriptUrl } from './config';
import { useMapsApiKey } from '../hooks';

import { Spinner } from '../../../components/Spinner';

let runtimeJsLoaded = false;
let mapUrl = '';

const loadScript = (() => {
  let loadPromise;

    return () => {
      if (loadPromise) {
        return loadPromise;
      }

      const scriptNode = document.head.querySelector(`script[src="${mapUrl}"]`);

      if (scriptNode) {
        loadPromise = Promise.resolve();
        return loadPromise;
      }

      loadPromise = new Promise((resolve, reject) => {
        window.ccApiLoad = () => {
          resolve();
        };
        const scriptNode = document.createElement('script');
        scriptNode.src = mapUrl;
        scriptNode.onload = () => {
          runtimeJsLoaded = true;
        };

        scriptNode.onerror = () => {
          reject();
        };

        document.head.appendChild(scriptNode);
      });

      return loadPromise;
    };
})();

/**
 * withMapsApi HOC
 * @param Component
 * @returns {{}} The wrapped component
 */
const withMapsApi = function (Component) {
  const ComponentWithMapsApi = function (props) {
    const apiKey = useMapsApiKey();
    const [state, setState] = React.useState({
      jsLoaded: runtimeJsLoaded,
      pending: false,
      ready: runtimeJsLoaded,
      error: undefined,
    });

    const { pending, ready, error, jsLoaded } = state;
    const assetsLoaded = jsLoaded;

    if (!ready && !error && !pending && !assetsLoaded) {
      setState((prevState) => ({
        ...prevState,
        pending: true,
      }));

      if (!jsLoaded) {
        mapUrl = apiScriptUrl + '&ak=' + apiKey + '&&callback=ccApiLoad';
          loadScript()
            .then(() => {
              setState((prevState) => ({
                ...prevState,
                jsLoaded: true,
              }));
              })
              .catch((err) => {
                setState((prevState) => ({
                  ...prevState,
                  error: err.message,
                }));
              });
        }
    }

    if (pending && assetsLoaded) {
      setState((prevState) => ({
        ...prevState,
        pending: false,
        ready: true,
      }));
    }

    if (!ready) {
      if (error) {
        return <span>{error}</span>;
      }
      return <Spinner blockInteraction={true} />;
    }

    return <Component {...props} />;
  };

  ComponentWithMapsApi.propTypes = Component.propTypes;
  return ComponentWithMapsApi;
};

export default withMapsApi;
