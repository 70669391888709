import React, { useState, useEffect } from 'react';

import { confKeys, widgetModes } from '../../constants';
import { useConfigValue } from '../../contexts/config';
import { InlineWidget } from '../InlineWidget';
import { PopupWidget } from '../PopupWidget';
import GlobalStyle from '../../globalStyles';

import { CCAccessbility } from '../../utils/accessibilityHelper';

export const App = props => {
  const widgetMode = useConfigValue(confKeys.WIDGET_MODE);
  const [accessibilityIncluded, setAccessibilityIncluded] = useState(false);

  useEffect(() => {
    if (!accessibilityIncluded) {
      CCAccessbility();
      setAccessibilityIncluded(true);
    }
  }, [accessibilityIncluded]);

  return (
    <>
      <GlobalStyle></GlobalStyle>
      {widgetMode === widgetModes.POPUP ? (
        <PopupWidget {...props} />
      ) : (
        <InlineWidget {...props} />
      )}
    </>
  );
};

export default App;
