import React from 'react';
import PropTypes from 'prop-types';
import { PinContainer, Distance } from './LocalStorePinStyled';
import { LocalStoreShape, getShopDistance } from '../../../api';
import { confKeys, distanceUnits } from '../../../constants';
import { useConfigValue, useTheme } from '../../../hooks';
import {
  formatNumber,
  getDistanceUnit,
  kmToMiles,
} from '../../../utils/distance';
import { MapMarkerIcon, MapMarkerDotIcon } from '../../Icon';

const ccid = 'localstore-pin';

const getDistanceLocalized = ({ shop, unit, country }) => {
  const distanceKm = getShopDistance(shop);
  if (typeof distanceKm === 'number' && distanceKm > 0) {
    return `${formatNumber(
      unit === distanceUnits.MILES || unit === distanceUnits.AUTO
        ? kmToMiles(distanceKm)
        : distanceKm,
      2,
      country
    )} ${unit}`;
  }
  return '';
};

const getMarkerIcon = ({ active }) => {
  return active ? MapMarkerIcon : MapMarkerDotIcon;
};

export const LocalStorePin = ({ shop, active }) => {
  const theme = useTheme();
  const country = useConfigValue(confKeys.COUNTRY);
  const unit = getDistanceUnit(country);
  const distanceLocalized = getDistanceLocalized({ shop, unit, country });
  const MarkerIcon = getMarkerIcon({ active });

  return (
    <>
      <PinContainer
        active={active}
        data-ccid={ccid}
        data-clickout-type="store_distance_click"
        // aria-label={`${shop.name} ${getAddress() ? getAddress() : ''}`}
      >
        <MarkerIcon size={36} strokeColor={theme.colors.white} />
      </PinContainer>
      {distanceLocalized && (
        <Distance data-ccid={`${ccid}-distance`}>{distanceLocalized}</Distance>
      )}
    </>
  );
};

LocalStorePin.propTypes = {
  shop: LocalStoreShape.isRequired,
  active: PropTypes.bool,
};

export default LocalStorePin;
