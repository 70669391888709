import { rtlLanguages } from '../../constants';

export function mergeTranslation(sourceMessages, targetMessages) {
  const messages = {};
  if (sourceMessages) {
    Object.keys(sourceMessages).forEach(textKey => {
      const text = sourceMessages[textKey];
      if (typeof text === 'string' && text !== '') {
        messages[textKey] = text;
      }
    });
  }
  if (targetMessages) {
    Object.keys(targetMessages).forEach(textKey => {
      const text = targetMessages[textKey];
      if (typeof text === 'string' && text !== '') {
        messages[textKey] = text;
      }
    });
  }
  return messages;
}

export function mergeTranslations(sourceTranslations, targetTranslations) {
  const mergedTranslations = {};
  const addTranslation = translation => {
    if (!translation) {
      return;
    }
    Object.keys(translation).forEach(name => {
      if (typeof translation[name] === 'object') {
        mergedTranslations[name] = mergeTranslation(
          mergedTranslations[name],
          translation[name]
        );
      }
    });
  };
  addTranslation(sourceTranslations);
  addTranslation(targetTranslations);
  return mergedTranslations;
}

export function translate(textKey, messages) {
  if (
    messages &&
    typeof messages[textKey] === 'string' &&
    messages[textKey] !== ''
  ) {
    return messages[textKey];
  }
  // return textKey;
  return '';
}

export function isRTLLanguage(language) {
  return language
    ? rtlLanguages.includes(
        language.replace('_', '-').split('-')[0].toLowerCase()
      )
    : false;
}
