import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useActiveSearchResult,
  useHasSearchResults,
  useSearch,
} from '../../hooks';
import { ScrollContainer } from '../Layout';
import {
  LocalStoreList,
  listItemDisplayModes,
} from '../LocalStore/LocalStoreList';
import { MoreResults } from './MoreResults';

export const Container = styled.div`
  position: relative;
  flex: 1 0 auto;
  width: 380px;
  max-width: 100%;
  overflow: hidden;
`;

const Dimmer = styled.div`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;

export const ResultsListCropped = ({ dimmer = false, type }) => {
  const { visibleResults, filters } = useSearch();
  const selectedResult = useActiveSearchResult();
  const hasResults = useHasSearchResults();

  const scrollContainerRef = useRef(null);
  const [scrollbarSize, setScrollbarSize] = useState(0);

  useEffect(() => {
    // Set scrollbar size
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer && visibleResults) {
      setScrollbarSize(
        scrollContainer.offsetWidth - scrollContainer.clientWidth
      );
    }
  }, [scrollContainerRef, visibleResults]);

  useEffect(() => {
    // Scroll to top on init or if filters have changed
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer && hasResults && filters) {
      scrollContainer.scrollTo(0, 0);
      setScrollbarSize(
        scrollContainer.offsetWidth - scrollContainer.clientWidth
      );
    }
  }, [filters, hasResults]);

  useEffect(() => {
    // Scroll to selected item
    let timeout;
    const scrollContainer = scrollContainerRef.current;
    if (selectedResult && scrollContainer) {
      const item = scrollContainer.querySelector(
        `[data-itemid=${selectedResult.id}]`
      );
      if (item) {
        timeout = setTimeout(() => {
          const containerBounds = scrollContainer.getBoundingClientRect();
          const itemBounds = item.getBoundingClientRect();
          const relY = itemBounds.top - containerBounds.top - 10 + 1;
          scrollContainer.scrollBy({
            top: relY,
            left: 0,
            behavior: 'smooth',
          });
        }, 50);
      }
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [scrollContainerRef, selectedResult]);

  return (
    <Container data-ccid="results-list-cropped">
      <ScrollContainer
        id="scroll-container"
        data-ccid="results-scrollcontainer"
        ref={scrollContainerRef}
        cropScrollbar={scrollbarSize}>
        <LocalStoreList itemDisplayMode={listItemDisplayModes.CARD} />
        <MoreResults />
      </ScrollContainer>
      {dimmer && <Dimmer />}
    </Container>
  );
};

ResultsListCropped.propTypes = {
  dimmer: PropTypes.bool,
};

export default ResultsListCropped;
