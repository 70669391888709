import React from 'react';
import { MapPropTypes } from '../types';
import withMapsApi from './withMapsApi';
import {
  getMarkerIcon,
  getActiveMarkerIcon,
  getHighlightedMarkerIcon,
  createGeoJSONFromSearchResults,
  createGeoJSONFromSearchLocation,
} from './mapUtils';
import { useMapsCountry, useMapsLanguage } from '../hooks';

const updateShopMarkerIcon = (map, shopMarker, markerIcon) => {
  const element = shopMarker.marker.getElement();
  element.removeChild(element.childNodes[0]);
  element.appendChild(markerIcon.childNodes[0]);
};

const getMarkerBounds = (searchLocation, shopMarkers) => {
  const { mapboxgl } = window;
  const markerBounds = new mapboxgl.LngLatBounds();
  markerBounds.extend(
    new mapboxgl.LngLat(searchLocation.lng, searchLocation.lat)
  );
  shopMarkers.forEach(({ marker }) => {
    markerBounds.extend(marker.getLngLat());
  });
  return markerBounds;
};

const getMapPadding = (mapPadding = {}) => {
  return {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    ...mapPadding,
  };
};

const findShopMarker = (shopMarkers, markerId) => {
  return shopMarkers.filter(({ id }) => id === markerId)[0];
};

const updateMarkers = ({ map, shopMarkers }) => {
  const features = map.querySourceFeatures('localstores');
  const markerIds = features
    .filter(({ properties }) => !properties.cluster)
    .map(({ properties }) => properties.id);
  shopMarkers.forEach(shopMarker => {
    if (markerIds.indexOf(shopMarker.id) !== -1) {
      if (!shopMarker.onScreen) {
        shopMarker.onScreen = true;
        shopMarker.marker.addTo(map);
      }
    } else if (shopMarker.onScreen) {
      shopMarker.onScreen = false;
      shopMarker.marker.remove();
    }
  });
};

const Map = ({
  activeMarkerId,
  className,
  clusterMarkerColor,
  clusterTextColor,
  highlightedMarkerId,
  mapPadding,
  mapTheme,
  markerColor,
  markerClassNames = {},
  markers,
  onClickMarker,
  searchLocation,
}) => {
  const [mapReady, setMapReady] = React.useState(false);
  const language = useMapsLanguage();
  const country = useMapsCountry();
  const containerRef = React.useRef(null);
  const mapOptionsRef = React.useRef({
    country,
    language,
    markerColor,
    markerClassNames: {
      marker: '',
      activeMarker: '',
      highlightedMarker: '',
      ...markerClassNames,
    },
    onClickMarker,
    clusterMarkerColor,
    clusterTextColor,
    searchLocation,
    padding: getMapPadding(mapPadding),
    map: null,
    searchMarker: null,
    searchResults: [],
    shopMarkers: [],
  });

  /**
   * Side effect updates padding
   */
  React.useEffect(() => {
    mapOptionsRef.current.padding = getMapPadding(mapPadding);
    if (mapOptionsRef.current.map) {
      mapOptionsRef.current.map.setPadding(mapOptionsRef.current.padding);
    }
  }, [mapOptionsRef, mapPadding]);

  /**
   * Side effect updates locale
   */
  React.useEffect(() => {
    mapOptionsRef.current.country = country;
    mapOptionsRef.current.language = language;
  }, [country, language]);

  /**
   * Side effect updates searchLocation geoJSON
   */
  React.useEffect(() => {
    if (searchLocation) {
      mapOptionsRef.current.searchLocation = searchLocation;
      if (mapOptionsRef.current.map) {
        mapOptionsRef.current.map
          .getSource('searchlocation')
          .setData(createGeoJSONFromSearchLocation(searchLocation));
      }
    }
  }, [searchLocation]);

  /**
   * Side effect updates localstores geoJSON
   */
  React.useEffect(() => {
    if (markers) {
      mapOptionsRef.current.searchResults = markers;
      if (mapOptionsRef.current.map) {
        mapOptionsRef.current.map
          .getSource('localstores')
          .setData(createGeoJSONFromSearchResults(markers));
      }
    }
  }, [markers]);

  /**
   * Side effect initializes Mapbox Map
   */
  React.useEffect(() => {
    let cancelled = false;
    const container = containerRef.current;
    if (container && searchLocation && !mapOptionsRef.current.map) {
      const { mapboxgl } = window;
      const map = new mapboxgl.Map({
        container: container,
        center: [searchLocation.lng, searchLocation.lat],
        zoom: 12,
        maxZoom: 16,
        minZoom: 8,
        style:
          mapTheme === 'grey'
            ? 'mapbox://styles/mapbox/light-v10'
            : 'mapbox://styles/mapbox/streets-v11',
        hash: false,
      });

      const refresh = () => {
        if (cancelled) return;
        const { shopMarkers } = mapOptionsRef.current;
        updateMarkers({ map, shopMarkers });
      };

      map.on('load', () => {
        if (cancelled) {
          return;
        }

        const {
          clusterMarkerColor,
          clusterTextColor,
          padding,
          searchResults,
          shopMarkers,
        } = mapOptionsRef.current;

        map.setPadding(padding);
        mapOptionsRef.current.map = map;

        map.addSource('searchlocation', {
          type: 'geojson',
          data: createGeoJSONFromSearchLocation(searchLocation),
        });
        map.addSource('localstores', {
          type: 'geojson',
          data: createGeoJSONFromSearchResults(searchResults),
          cluster: true,
          clusterRadius: 30,
          clusterMaxZoom: 12,
        });
        map.addLayer({
          id: 'search-outer',
          interactive: false,
          type: 'circle',
          source: 'searchlocation',
          paint: {
            'circle-color': '#000000',
            'circle-radius': 10,
            'circle-stroke-color': '#ffffff',
            'circle-stroke-width': 2,
          },
        });
        map.addLayer({
          id: 'search-inner',
          interactive: false,
          type: 'circle',
          source: 'searchlocation',
          paint: {
            'circle-color': '#ffffff',
            'circle-radius': 3,
          },
        });
        map.addLayer({
          id: 'cluster-circle',
          interactive: true,
          type: 'circle',
          source: 'localstores',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': clusterMarkerColor,
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              15,
              2,
              20,
              10,
              24,
            ],
            'circle-stroke-color': clusterTextColor,
            'circle-stroke-width': 2,
          },
        });
        map.addLayer({
          id: 'cluster-label',
          interactive: false,
          type: 'symbol',
          source: 'localstores',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['Open Sans Semibold'],
            'text-size': 15,
          },
          paint: {
            'text-color': clusterTextColor,
          },
        });
        map.on('click', 'cluster-circle', ({ lngLat }) => {
          map.flyTo({
            center: lngLat,
            zoom: 15,
          });
        });
        map.on('data', ({ sourceId, isSourceLoaded }) => {
          if (sourceId !== 'localstores' || !isSourceLoaded) return;
          refresh();
        });
        map.on('move', refresh);
        map.on('moveend', refresh);
        map.on('idle', refresh);
        map.fitBounds(getMarkerBounds(searchLocation, shopMarkers));
        setMapReady(true);
      });
    }
    return () => {
      cancelled = true;
    };
  }, [containerRef, mapTheme, searchLocation]);

  /**
   * Side effect updates markers
   */
  React.useEffect(() => {
    if (markers) {
      const { mapboxgl } = window;
      const {
        map,
        markerColor,
        markerClassNames,
        onClickMarker,
        searchLocation,
        shopMarkers,
      } = mapOptionsRef.current;

      markers.forEach(marker => {
        const shopMarker = findShopMarker(shopMarkers, marker.id);
        if (shopMarker) {
          return;
        }
        const markerElement = getMarkerIcon({
          color: markerColor,
          className: markerClassNames.marker,
        });
        const mapboxMarker = new mapboxgl.Marker({
          anchor: 'bottom',
          element: markerElement,
        }).setLngLat([marker.location.lng, marker.location.lat]);
        const clickHandler = () => {
          onClickMarker(marker.id);
        };
        markerElement.addEventListener('click', clickHandler);
        shopMarkers.push({
          ...marker,
          marker: mapboxMarker,
          clickHandler,
        });
      });
      if (map && shopMarkers.length > 1) {
        map.fitBounds(getMarkerBounds(searchLocation, shopMarkers));
      }
    }
  }, [markers]);

  /**
   * Side effect sets active marker
   */
  React.useEffect(() => {
    if (mapReady) {
      const {
        map,
        markerColor,
        markerClassNames,
        searchLocation,
        shopMarkers,
      } = mapOptionsRef.current;
      if (map && searchLocation) {
        let activeShopMarker;
        shopMarkers.forEach(shopMarker => {
          if (shopMarker.id === activeMarkerId) {
            activeShopMarker = shopMarker;
            if (!shopMarker.isActive) {
              updateShopMarkerIcon(
                map,
                shopMarker,
                getActiveMarkerIcon({
                  color: markerColor,
                  className: markerClassNames.active,
                })
              );
              shopMarker.isActive = true;
            }
          } else if (shopMarker.isActive) {
            updateShopMarkerIcon(
              map,
              shopMarker,
              getMarkerIcon({
                color: markerColor,
                className: markerClassNames.marker,
              })
            );
            shopMarker.isActive = false;
          }
          return shopMarker;
        });
        if (activeShopMarker) {
          map.flyTo({
            center: [
              activeShopMarker.location.lng,
              activeShopMarker.location.lat,
            ],
            zoom: Math.max(map.getZoom(), 15),
          });
        } else {
          map.fitBounds(getMarkerBounds(searchLocation, shopMarkers));
        }
      }
    }
  }, [mapReady, activeMarkerId]);

  /**
   * Side effect highlights marker
   */
  React.useEffect(() => {
    const {
      map,
      markerColor,
      markerClassNames,
      searchLocation,
      shopMarkers,
    } = mapOptionsRef.current;
    if (map && searchLocation) {
      shopMarkers.forEach(shopMarker => {
        if (shopMarker.id === highlightedMarkerId) {
          if (!shopMarker.isActive) {
            if (!shopMarker.isHighlighted) {
              updateShopMarkerIcon(
                map,
                shopMarker,
                getHighlightedMarkerIcon({
                  color: markerColor,
                  className: markerClassNames.highlighted,
                })
              );
              shopMarker.isHighlighted = true;
            }
          }
        } else if (shopMarker.isHighlighted) {
          if (!shopMarker.isActive) {
            updateShopMarkerIcon(
              map,
              shopMarker,
              getMarkerIcon({
                color: markerColor,
                className: markerClassNames.marker,
              })
            );
          }
          shopMarker.isHighlighted = false;
        }
      });
    }
  }, [highlightedMarkerId]);

  return (
    <div
      className={className}
      ref={containerRef}
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
    />
  );
};

Map.propTypes = MapPropTypes;

export default withMapsApi(Map);
