import styled from 'styled-components';

export const BaseButton = styled.button`
  flex: 1 0 auto;
  appearance: none;
  background: transparent none;
  border: 0 none transparent;
  margin: 0;
  padding: 0;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  /* outline: none; */
  text-decoration: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
`;

export default BaseButton;
