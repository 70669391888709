import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mapListModeTypes } from '../../constants';
import { useHasSearchResults } from '../../hooks';
import { LocalStoreSlider } from '../LocalStore/LocalStoreSlider';
import { useMapListMode } from './hooks';
import { Map } from '../Map';
import { ResultsListCropped } from './ResultsListCropped';

const ccid = 'search-results-map';

const mapPaddingList = { left: 380 };
const mapPaddingSlider = { bottom: 160 };

const Wrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  overflow: hidden;
  position: relative;
  margin: 0;
  width: 100%;
`;

export const ListWrapper = styled(Wrapper)`
  flex-flow: row nowrap;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

export const SliderWrapper = styled(Wrapper)`
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

export const MapLayer = styled.div`
  position: relative;
  flex: 1 1 auto;
  width: 100%;
`;

export const ListLayer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 380px;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
`;

export const SliderLayer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
`;

const LayoutListInside = () => {
  return (
    <ListWrapper data-ccid={ccid}>
      <MapLayer
        data-ccid={`${ccid}-map-layer`}
        role="presentation"
        aria-hidden="true">
        <Map mapPadding={mapPaddingList} />
      </MapLayer>
      <ListLayer data-ccid={`${ccid}-list-layer`}>
        <ResultsListCropped type={'map'} />
      </ListLayer>
    </ListWrapper>
  );
};

const LayoutListOutside = () => {
  return (
    <ListWrapper data-ccid={ccid}>
      <ResultsListCropped type={'list'} dimmer />
      <MapLayer
        data-ccid={`${ccid}-map-layer`}
        role="presentation"
        aria-hidden="true">
        <Map />
      </MapLayer>
    </ListWrapper>
  );
};

const LayoutSliderInside = () => {
  return (
    <SliderWrapper data-ccid={ccid}>
      <MapLayer
        data-ccid={`${ccid}-map-layer`}
        role="presentation"
        aria-hidden="true">
        <Map mapPadding={mapPaddingSlider} />
      </MapLayer>
      <SliderLayer data-ccid={`${ccid}-slider-layer`}>
        <LocalStoreSlider />
      </SliderLayer>
    </SliderWrapper>
  );
};

const LayoutSliderOutside = () => {
  return (
    <SliderWrapper data-ccid={ccid}>
      <MapLayer
        data-ccid={`${ccid}-map-layer`}
        role="presentation"
        aria-hidden="true">
        <Map />
      </MapLayer>
      <LocalStoreSlider />
    </SliderWrapper>
  );
};

const resolveLayoutComponent = mode => {
  switch (mode) {
    case mapListModeTypes.LIST_INSIDE:
      return LayoutListInside;
    case mapListModeTypes.LIST_OUTSIDE:
      return LayoutListOutside;
    case mapListModeTypes.SLIDER_INSIDE:
      return LayoutSliderInside;
    case mapListModeTypes.SLIDER_OUTSIDE:
      return LayoutSliderOutside;
    default:
      return LayoutListInside;
  }
};

export const ResultsMap = ({ mode }) => {
  const hasResults = useHasSearchResults();
  const mapListMode = useMapListMode(mode);
  const LayoutComponent = resolveLayoutComponent(mapListMode);
  return hasResults ? <LayoutComponent /> : null;
};

ResultsMap.propTypes = {
  mode: PropTypes.oneOf([
    mapListModeTypes.LIST_INSIDE,
    mapListModeTypes.LIST_OUTSIDE,
    mapListModeTypes.SLIDER_INSIDE,
    mapListModeTypes.SLIDER_OUTSIDE,
  ]),
};

export default ResultsMap;
