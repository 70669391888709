const defaultMarkerPath =
  'M20,0C8.854,0,0,8.956,0,20c0,14.877,20,30,20,30s20-15.123,20-30C40,8.804,31.146,0,20,0z';
const activeMarkerPath =
  'M21,1C9.854,1,1,9.956,1,21c0,14.877,20,30,20,30 s20-15.123,20-30C41,9.804,32.146,1,21,1z M21,26c-2.761,0-5-2.239-5-5s2.239-5,5-5c2.76,0,5,2.239,5,5S23.76,26,21,26z';
const searchMarkerPath =
  'M17,2C8.716,2,2,8.716,2,17s6.716,15,15,15s15-6.716,15-15 S25.284,2,17,2z M17,19c-1.104,0-2-0.896-2-2s0.896-2,2-2s2,0.896,2,2S18.104,19,17,19z';

export const getSearchMarkerIcon = color => {
  const { google } = window;
  return {
    path: searchMarkerPath,
    fillColor: color || '#000000',
    fillOpacity: 1,
    strokeColor: '#ffffff',
    strokeWeight: 3,
    scale: 0.8,
    size: new google.maps.Size(40, 40),
    anchor: new google.maps.Point(20, 20),
  };
};

export const getMarkerIcon = color => {
  const { google } = window;
  return {
    path: defaultMarkerPath,
    fillColor: color || '#000000',
    fillOpacity: 1,
    strokeColor: '#ffffff',
    strokeWeight: 2,
    scale: 0.5,
    size: new google.maps.Size(40, 40),
    anchor: new google.maps.Point(20, 20),
  };
};

export const getHighlightedMarkerIcon = color => {
  return getMarkerIcon(color);
};

export const getActiveMarkerIcon = color => {
  const { google } = window;
  return {
    path: activeMarkerPath,
    fillColor: color || '#000000',
    fillOpacity: 1,
    strokeColor: '#ffffff',
    strokeWeight: 2,
    scale: 1,
    size: new google.maps.Size(40, 40),
    anchor: new google.maps.Point(20, 20),
  };
};
