export const textKeys = {
  CTA_APPLY_FILTER: 'cta_apply_filter',
  CTA_CLEAR_FILTER: 'cta_clear_filter',
  CTA_DISPLAY_RETAILER: 'cta_display_retailer',
  CTA_DISPLAY_RETAILERS: 'cta_display_retailers',
  CTA_MORE_RESULTS: 'cta_more_results',
  CTA_OPEN_POPUP: 'cta_open_popup',
  DISPLAY_MODE_LIST: 'list_view_name',
  DISPLAY_MODE_MAP: 'map_view_name',
  FILTER_B2B: 'filter_b2b',
  FILTER_B2C: 'filter_b2c',
  FILTER_OPEN_NOW: 'filter_open_now',
  FILTER_DISPLAY_ALL: 'filter_display_all',
  FILTER_NO_RESULTS: 'filter_no_results',
  FILTER_BACK_TO_RETAILERS: 'filter_back_to_retailers',
  LABEL_CLOSE: 'label_close',
  LABEL_FILTER: 'label_filter',
  LABEL_NEAR_ME: 'label_near_me',
  LABEL_NOW_CLOSED: 'label_now_closed',
  LABEL_NOW_OPEN: 'label_now_open',
  LABEL_ROUTE: 'label_route',

  LINK_LABEL_ROUTE_MAP: 'link_label_route_map',
  BUTTON_LABEL_ROUTE_MAP: 'button_label_route_map',
  LINK_LABEL_ROUTE_LIST: 'link_label_route_list',
  BUTTON_LABEL_ROUTE_LIST: 'button_label_route_list',

  NOTICE_API_ERROR: 'notice_apierror',
  NOTICE_GEOLOCATION_ERROR: 'notice_geolocation_error',
  NOTICE_GEOLOCATION_PROGRESS: 'notice_geolocation_progress',
  NOTICE_NO_LOCATION: 'notice_nolocation',
  NOTICE_NO_RESULTS: 'notice_noresults',
  POPUP_HEADER_TITLE: 'popup_title',
  POPUP_HEADER_DESCRIPTION: 'popup_description',
  SEARCH_PLACEHOLDER: 'search_placeholder',
  WEEKDAYS_SHORT_MO: 'weekdays_short_mo',
  WEEKDAYS_SHORT_TU: 'weekdays_short_tu',
  WEEKDAYS_SHORT_WE: 'weekdays_short_we',
  WEEKDAYS_SHORT_TH: 'weekdays_short_th',
  WEEKDAYS_SHORT_FR: 'weekdays_short_fr',
  WEEKDAYS_SHORT_SA: 'weekdays_short_sa',
  WEEKDAYS_SHORT_SU: 'weekdays_short_su',
};

export default textKeys;
