import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rotateAnim } from '../../styles/animations';
import { SpinnerIcon } from '../Icon/ui';

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1040;
  overflow: visible;
  pointer-events: ${({ blockInteraction }) =>
    blockInteraction ? 'auto' : 'none'};
`;

const IconContainer = styled.div`
  position: absolute;
  font-size: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.highlight};
  > svg {
    animation: ${rotateAnim} 1s linear infinite;
  }
`;

export const Spinner = ({ blockInteraction = false }) => {
  return (
    <Container blockInteraction={blockInteraction} className="spinner">
      <IconContainer>
        <SpinnerIcon size={36} />
      </IconContainer>
    </Container>
  );
};

Spinner.propTypes = {
  blockInteraction: PropTypes.bool,
};

export default Spinner;
