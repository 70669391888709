/**
 * Add outline when using keyboard
 * Remove outline when using mouse
 */

export const CCAccessbility = () => {
  let unfocusStyle = document.createElement('style');
  // think how to apply this only inside widget dialog...
  let styleText = `::-moz-focus-inner{ border:0 !important; }:focus{ outline: none !important; }`;

  window.unfocus = function () {
    document.getElementsByTagName('HEAD')[0].appendChild(unfocusStyle);

    document.addEventListener('mousedown', function () {
      unfocusStyle.innerHTML = styleText;
    });
    document.addEventListener('keydown', function () {
      // filter enter key....
      unfocusStyle.innerHTML = '';
    });
  };

  window.unfocus.style = function (style) {
    styleText += style;
  };

  window.unfocus();
};

// --- Uncomment this to see what is the active element
document.addEventListener('keyup', e => {
  // console.log(document.activeElement);
});
