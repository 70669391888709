import { displayModes, displayModeIds, textKeys } from '../../constants';

/**
 * @param id {string|null}
 * @param results {{id:string}[]|null}
 * @returns {{id:string}|null}
 */
export const findResultItem = (id, results) => {
  if (!id || !results || !results.length) {
    return null;
  }
  return results.reduce((prevItem, currentItem) => {
    return currentItem.id === id ? currentItem : prevItem;
  }, null);
};

export const getDisplayModeOptionsLocalized = (
  resultsDisplayMode,
  t,
  mapItems,
  listItems
) => {
  switch (resultsDisplayMode) {
    case displayModes.LIST:
      return [
        {
          id: displayModeIds.LIST,
          label: t(textKeys.DISPLAY_MODE_LIST),
          itemsPerPage: listItems,
        },
      ];
    case displayModes.MAP:
      return [
        {
          id: displayModeIds.MAP,
          label: t(textKeys.DISPLAY_MODE_MAP),
          itemsPerPage: mapItems,
        },
      ];
    case displayModes.TABS:
    default:
      return [
        {
          id: displayModeIds.MAP,
          label: t(textKeys.DISPLAY_MODE_MAP),
          itemsPerPage: mapItems,
        },
        {
          id: displayModeIds.LIST,
          label: t(textKeys.DISPLAY_MODE_LIST),
          itemsPerPage: listItems,
        },
      ];
  }
};
