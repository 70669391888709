import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BaseButton } from '../Button';
import { Container, Row } from '../Layout';
import { confKeys } from '../../constants';
import { useConfigValue } from '../../contexts/config';

const getThemeColor = ({ themeColor, theme }) => {
  return themeColor || theme.colors.highlight;
};

export const Wrapper = styled(Container)`
  flex: 0 0 auto;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

export const Menu = styled(Row)`
  justify-content: flex-start;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const Item = styled(BaseButton)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  font-size: 13px;
  font-family: ${({ theme }) => theme.typography.bold.fontFamily};
  font-weight: ${({ theme }) => theme.typography.bold.fontWeight};
  line-height: 1.2;
  text-transform: uppercase;
  height: 40px;
  padding: 0 16px;
  margin: 0;
  border: 0 none transparent;
  color: ${({ theme, selected = false }) =>
    selected ? theme.colors.text : theme.colors.mediumGrey};
  :hover {
    color: ${({ theme }) => theme.colors.text};
  }
  :after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    pointer-events: none;
    opacity: ${({ selected }) => (selected ? 1 : 0)};
    background-color: ${getThemeColor};
  }
`;

export const TabsMenu = ({
  ccid,
  items,
  onChange,
  selectedId,
  ...otherProps
}) => {
  const themeColor = useConfigValue(confKeys.COLOR_SCHEME);
  return (
    <Wrapper data-ccid={`${ccid}-container`} {...otherProps}>
      <Menu data-ccid={`${ccid}-tablist`} role="tablist">
        {items.map(item => (
          <Item
            themeColor={themeColor}
            data-ccid={`${ccid}-tab`}
            id={`${item.id}_tab`}
            key={item.id}
            role="tab"
            selected={selectedId && item.id === selectedId}
            aria-selected={selectedId && item.id === selectedId}
            onClick={() => onChange(item)}>
            {item.label}
          </Item>
        ))}
      </Menu>
    </Wrapper>
  );
};

TabsMenu.propTypes = {
  ccid: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  seletedId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TabsMenu;
