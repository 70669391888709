import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import MapsContext from './context';
import * as GoogleApi from './google';
import * as MapboxApi from './mapbox';
import * as BaiduApi from './baidu';
import { useMapsConfig } from './hooks';

const mapsComponents = {
  google: GoogleApi,
  mapbox: MapboxApi,
  baidu: BaiduApi,
};

const actions = {
  SET_API_PROVIDER: 'setApiProvider',
  SET_LOCALE: 'setLocale',
};

const mapsReducer = (prevState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.SET_API_PROVIDER:
      return {
        ...prevState,
        apiProvider: payload.apiProvider,
        apiKey: payload.apiKey,
        components: {
          ...payload.components,
        },
      };
    case actions.SET_LOCALE:
      if (
        payload.country !== prevState.country &&
        payload.language !== prevState.language
      ) {
        return {
          ...prevState,
          country: payload.country,
          language: payload.language,
        };
      }
      break;
    default:
      break;
  }
  return prevState;
};

export const MapsProvider = ({ children }) => {
  const { country, language, apiKey, apiProvider } = useMapsConfig();

  const [state, dispatch] = useReducer(mapsReducer, {
    country: country,
    language: language,
    apiKey: apiKey,
    apiProvider: apiProvider,
    components: apiProvider && apiKey ? mapsComponents[apiProvider] : null,
  });

  useEffect(() => {
    dispatch({
      type: actions.SET_LOCALE,
      payload: {
        country: country,
        language: language,
      },
    });
  }, [country, language]);

  useEffect(() => {
    if (apiKey && apiProvider && mapsComponents[apiProvider]) {
      dispatch({
        type: actions.SET_API_PROVIDER,
        payload: {
          apiProvider: apiProvider,
          apiKey: apiKey,
          components: mapsComponents[apiProvider],
        },
      });
    }
  }, [apiKey, apiProvider]);

  return <MapsContext.Provider value={state}>{children}</MapsContext.Provider>;
};

MapsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
