import React from 'react';
import { SearchInputPropTypes } from '../types';
import {
  useAutocomplete,
  useForwardGeocoding,
  useReverseGeocoding,
} from './hooks';
import AutocompleteList from './AutocompleteList';

const SearchInput = ({
  as: InputComponent = 'input',
  className,
  placeholder = '',
  resetKey = 0,
  value,
  style,
  searchLocation,
  onFocus,
  onBlur,
  onChange,
  onSubmit,
  onLocationChange,
  onAutoDetectAddress,
  ...otherProps
}) => {
  const getLocationByAddress = useForwardGeocoding();
  const getAddressByLocation = useReverseGeocoding();
  const getAutocompleteLocations = useAutocomplete();
  const [locationData, setLocationData] = React.useState(null);
  const [autocompleteResult, setAutocompleteResult] = React.useState(null);
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (locationData) {
      onLocationChange && onLocationChange(locationData);
    }
  }, [locationData, onLocationChange]);

  React.useEffect(() => {
    const input = inputRef.current;

    if (input) {
      if (searchLocation) {
        if (searchLocation.address) {
          input.value = searchLocation.address;
        } else if (searchLocation.lat && searchLocation.lng) {
          // Location was auto-detected
          // -> get address and inject into searchLocation silently
          // -> then call onAutoDetectAddress for tracking
          getAddressByLocation(searchLocation).then(addressData => {
            const input = inputRef.current;
            if (input && addressData && addressData.address) {
              searchLocation.address = addressData.address;
              searchLocation.city = addressData.city || '';
              searchLocation.country = addressData.country || '';
              searchLocation.zip = addressData.zip || '';
              input.value = searchLocation.address;
              if (onAutoDetectAddress) {
                onAutoDetectAddress(addressData);
              }
            }
          });
        }
      } else {
        input.value = '';
        input.blur();
      }
    }
  }, [inputRef, searchLocation, onAutoDetectAddress, getAddressByLocation]);

  React.useEffect(() => {
    const input = inputRef.current;
    let timeout;
    if (input && resetKey > 0) {
      input.value = '';
      setAutocompleteResult(null);
      timeout = setTimeout(() => {
        input.focus();
      }, 10);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [inputRef, resetKey]);

  const handleSelectAutocomplete = React.useCallback(feature => {
    if (feature) {
      setLocationData({
        lat: feature.center[1],
        lng: feature.center[0],
        address: feature.place_name,
      });
    }
    setAutocompleteResult(null);
  }, []);

  const handleChange = React.useCallback(
    event => {
      if (event.target.value.length > 1) {
        getAutocompleteLocations(event.target.value).then(result => {
          if (result && result.features && result.features.length > 0) {
            setAutocompleteResult(result);
          } else {
            setAutocompleteResult(null);
          }
        });
      } else {
        setAutocompleteResult(null);
      }
      onChange && onChange(event);
    },
    [getAutocompleteLocations, onChange]
  );

  const handleBlur = React.useCallback(
    event => {
      if (
        searchLocation &&
        searchLocation.address &&
        event.target.value === ''
      ) {
        if (Date.now() - resetKey > 100) {
          event.target.value = searchLocation.address;
        }
      }
      setTimeout(() => {
        setAutocompleteResult(null);
      }, 100);
      onBlur && onBlur(event);
    },
    [searchLocation, resetKey, onBlur]
  );

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      const queryText = event.target.value;
      if (queryText.length > 2) {
        setAutocompleteResult(null);
        getLocationByAddress(queryText).then(result => {
          const input = inputRef.current;
          if (input && result) {
            if (result.lat && result.lng && result.address) {
              setLocationData(result);
              input.value = result.address;
              input.blur();
            }
          }
        });
      }
      onSubmit && onSubmit(queryText);
    }
  };

  return (
    <React.Fragment>
      <InputComponent
        className={className}
        style={style}
        type="search"
        autoComplete="off"
        spellCheck="false"
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        onFocus={onFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        ref={inputRef}
        {...otherProps}
      />
      {autocompleteResult && (
        <AutocompleteList
          inputRef={inputRef}
          data={autocompleteResult}
          onSelect={handleSelectAutocomplete}
        />
      )}
    </React.Fragment>
  );
};

SearchInput.propTypes = SearchInputPropTypes;

export default SearchInput;
