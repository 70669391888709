export const apiGeocodingUrl =
  'https://maps.googleapis.com/maps/api/geocode/json';

export function getForwardGeocodingEndpoint({
  apiKey,
  country,
  language,
  address,
}) {
  return `${apiGeocodingUrl}?address=${encodeURIComponent(
    address
  )}&key=${apiKey}&language=${language}&components=country:${country}`;
}

export function getReverseGeocodingEndpoint({ apiKey, location, language }) {
  // return `${apiGeocodingUrl}?latlng=${location.lat},${location.lng}&key=${apiKey}&result_type=postal_code&language=${language}`;
  return `${apiGeocodingUrl}?latlng=${location.lat},${location.lng}&key=${apiKey}&language=${language}`;
}
