import React, { createContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { confKeys } from '../../constants';
import { useConfigValue } from '../config';
import {
  searchReducer,
  getDefaultState,
  updateVisibleResults,
} from './reducer';
import { searchActionTypes } from './types';
import { LocalStoresResponseShape } from '../../api';

export const SearchContext = createContext(getDefaultState());

export const SearchDispatchContext = createContext(() => {});

export const SearchProvider = ({ children, initialState }) => {

  const mapItemsPerPage = useConfigValue(confKeys.MAP_VIEW_RETAILERS_PER_PAGE);
  const listItemsPerPage = useConfigValue(
    confKeys.LIST_VIEW_RETAILERS_PER_PAGE
  );
  const customAttrSettings = useConfigValue(confKeys.FILTER_CUSTOM_FIELDS);

  let itemsPerPage = 10;

  const [state, dispatch] = useReducer(
    searchReducer,
    initialState,
    initialState => {
      return updateVisibleResults({
        ...getDefaultState(),
        itemsPerPage: itemsPerPage,
        listItemsPerPage,
        mapItemsPerPage,
        customAttrLogic: customAttrSettings
          ? customAttrSettings.custom_attributes_filter_logic
          : 'AND',
        ...initialState,
      });
    }
  );

  useEffect(() => {
    if (initialState) {
      dispatch({
        type: searchActionTypes.INIT_STATE,
        initialState: initialState,
      });
    }
  }, [initialState, dispatch, itemsPerPage]);

  return (
    <SearchDispatchContext.Provider value={dispatch}>
      <SearchContext.Provider value={state}>{children}</SearchContext.Provider>
    </SearchDispatchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
  responseData: LocalStoresResponseShape,
};
