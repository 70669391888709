import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Text = styled.span`
  display: block;
`;

export const IconContainer = styled.span`
  display: block;
  margin-bottom: 10px;
`;

export const Message = ({ content, icon }) => {
  const htmlText = content.replace(/<(?!(\/?a|br|\/?strong)\s*)[^>]+>/g, '');
  const isHtml = /</.test(htmlText);
  return (
    <>
      {icon && <IconContainer>{icon}</IconContainer>}
      {isHtml && <Text dangerouslySetInnerHTML={{ __html: htmlText }} />}
      {!isHtml && <Text>{htmlText}</Text>}
    </>
  );
};

Message.propTypes = {
  content: PropTypes.string.isRequired,
  icon: PropTypes.node,
};

export default Message;
