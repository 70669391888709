import React from 'react';
import { MoveFocusInside } from 'react-focus-lock';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakpointMobile, confKeys, displayModeIds } from '../../constants';
import {
  useConfigValue,
  useHasSearchResults,
  useIsSearchPending,
  useResultsDisplayMode,
} from '../../hooks';
import { DisplayModeTabs } from '../../components/DisplayModeTabs';
import { Notifications } from '../../components/Notifications';
import { SearchField } from '../../components/SearchField';
import { Spinner } from '../../components/Spinner';
import {
  ResultsMap,
  SliderLayer,
} from '../../components/SearchResults/ResultsMap';
import { ResultsList } from '../../components/SearchResults/ResultsList';
import {
  PopupCloseButton,
  PopupHeader,
  PopupTitlebar,
} from '../../components/Popup';
import { popupSlideDuration } from './constants';

const ccid = 'storefinder-popup-view';

const getContainerTransform = ({ active, theme }) => {
  if (active) {
    return 'none';
  }
  return theme.rtl ? 'translateX(-100%)' : 'translateX(100%)';
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: ${({ theme }) => (theme.rtl ? '0' : 'auto')};
  right: ${({ theme }) => (theme.rtl ? 'auto' : '0')};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: visible;
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  transform: ${getContainerTransform};
  transition: transform ${popupSlideDuration}ms
    ${({ active }) => (active ? 'ease-out' : 'ease-in')};
  @media (min-width: ${breakpointMobile}px) {
    width: 90vw;
    max-width: 960px;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  min-height: 60px;
  padding: 0 12px;
  background-color: ${({ theme }) => theme.colors.background};
  @media (min-width: ${breakpointMobile}px) {
    > div {
      max-width: 100%;
    }
  }
`;

export const ResultsContainer = styled.div`
  width: 100%;
  position: relative;
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: ${breakpointMobile - 1}px) {
    & ${SliderLayer} {
      bottom: 20px;
    }
  }
`;

const NoResultsContainer = styled.div`
  flex: 1 0 auto;
  width: 100%;
  min-height: 40px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.background};
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

export const PopupView = ({ active = false, ready = false, onClose }) => {
  const headerDescription = useConfigValue(confKeys.PRODUCT_DESCRIPTION);
  const headerTitle = useConfigValue(confKeys.PRODUCT_TITLE);
  const showHeader = headerDescription || headerTitle;
  const hasResults = useHasSearchResults();
  const isPending = useIsSearchPending();
  const [displayMode] = useResultsDisplayMode();

  return (
    <Container data-ccid={`${ccid}-container`} active={active}>
      <PopupTitlebar />
      {showHeader && <PopupHeader />}
      <SearchContainer data-ccid={`${ccid}-search-container`}>
        <SearchField />
      </SearchContainer>
      <DisplayModeTabs />
      {!hasResults && (
        <NoResultsContainer data-ccid={`${ccid}-noresults-container`}>
          <Notifications />
          {isPending && <Spinner />}
        </NoResultsContainer>
      )}
      {hasResults && (
        <ResultsContainer data-ccid={`${ccid}-results-container`}>
          {ready && displayMode === displayModeIds.MAP && <ResultsMap />}
          {ready && displayMode === displayModeIds.LIST && <ResultsList />}
          {(isPending || (active && !ready)) && <Spinner />}
        </ResultsContainer>
      )}
      <MoveFocusInside>
        <PopupCloseButton
          data-ccid={`${ccid}-close-button`}
          onClose={onClose}
        />
      </MoveFocusInside>
    </Container>
  );
};

PopupView.propTypes = {
  active: PropTypes.bool,
  ready: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PopupView;
