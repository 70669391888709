export const apiGeocodingUrl =
  'https://api.mapbox.com/geocoding/v5/mapbox.places/';
export const apiScriptUrl =
  'https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.js';
export const apiStylesheetUrl =
  'https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css';

function createUrlParams(data) {
  return Object.keys(data)
    .map(param => `${param}=${data[param]}`)
    .join('&');
}

export function getForwardGeocodingEndpoint({
  apiKey,
  country,
  language,
  address,
  autocomplete = false,
}) {
  const params = createUrlParams({
    access_token: apiKey,
    country: country,
    language: language,
    types: 'postcode,place,address',
    autocomplete: autocomplete,
    fuzzyMatch: !!autocomplete,
  });
  return `${apiGeocodingUrl}${encodeURIComponent(address)}.json?${params}`;
}

export function getReverseGeocodingEndpoint({ apiKey, location, language }) {
  const params = createUrlParams({
    access_token: apiKey,
    language: language,
    types: 'postcode,country',
  });
  return `${apiGeocodingUrl}${location.lng},${location.lat}.json?${params}`;
}
