const searchMarkerPath =
  'M17,2C8.716,2,2,8.716,2,17s6.716,15,15,15s15-6.716,15-15 S25.284,2,17,2z M17,19c-1.104,0-2-0.896-2-2s0.896-2,2-2s2,0.896,2,2S18.104,19,17,19z';
const defaultMarkerPath =
  'M20,0C8.854,0,0,8.956,0,20c0,14.877,20,30,20,30s20-15.123,20-30C40,8.804,31.146,0,20,0z';
const activeMarkerPath =
  'M21,1C9.854,1,1,9.956,1,21c0,14.877,20,30,20,30 s20-15.123,20-30C41,9.804,32.146,1,21,1z M21,26c-2.761,0-5-2.239-5-5s2.239-5,5-5c2.76,0,5,2.239,5,5S23.76,26,21,26z';

const svgNS = 'http://www.w3.org/2000/svg';

const createSvgIcon = ({
  viewBox = '0 0 40 40',
  width = '40',
  height = '40',
  className,
}) => {
  const svg = document.createElementNS(svgNS, 'svg');
  svg.setAttributeNS(null, 'display', 'block');
  svg.setAttributeNS(null, 'viewBox', viewBox);
  svg.setAttributeNS(null, 'width', width);
  svg.setAttributeNS(null, 'height', height);
  if (className) {
    svg.classList.add(className);
  }
  return svg;
};

const createSvgPath = pathProps => {
  const path = document.createElementNS(svgNS, 'path');
  Object.keys(pathProps).forEach(attr => {
    if (pathProps[attr] !== undefined) {
      path.setAttributeNS(null, attr, `${pathProps[attr]}`);
    }
  });
  return path;
};

export const getSearchMarkerIcon = ({ color = '#000000', className }) => {
  const svg = createSvgIcon({ width: 30, height: 30, className });
  svg.appendChild(
    createSvgPath({
      d: searchMarkerPath,
      fill: color,
      stroke: '#ffffff',
      'stroke-width': 4,
    })
  );
  return svg;
};

export const getMarkerIcon = ({ color = '#000000', className }) => {
  const svg = createSvgIcon({
    viewBox: '0 0 40 50',
    width: 20,
    height: 25,
    className,
  });
  svg.appendChild(
    createSvgPath({
      d: defaultMarkerPath,
      fill: color,
      stroke: '#ffffff',
      'stroke-width': 4,
    })
  );
  const icon = document.createElement('div');
  icon.appendChild(svg);
  return icon;
};

export const getActiveMarkerIcon = ({ color = '#000000', className }) => {
  const svg = createSvgIcon({
    viewBox: '0 0 40 50',
    width: 40,
    height: 50,
    className,
  });
  svg.appendChild(
    createSvgPath({
      d: activeMarkerPath,
      fill: color,
      stroke: '#ffffff',
      'stroke-width': 2,
    })
  );
  const icon = document.createElement('div');
  icon.appendChild(svg);
  return icon;
};

export const getHighlightedMarkerIcon = getMarkerIcon;

export const createGeoJSONFromSearchResults = (markers = []) => {
  return {
    type: 'FeatureCollection',
    features: markers.map(marker => ({
      type: 'Feature',
      properties: {
        id: marker.id,
      },
      geometry: {
        type: 'Point',
        coordinates: [marker.location.lng, marker.location.lat],
      },
    })),
  };
};

export const createGeoJSONFromSearchLocation = searchLocation => {
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [searchLocation.lng, searchLocation.lat],
        },
      },
    ],
  };
};
