export const apiGeocodingUrl = 'https://api.map.baidu.com/place/v2/suggestion';
export const apiScriptUrl = 'https://api.map.baidu.com/api?v=3.0';
export const apiReverseGeocodingUrl = 'https://api.map.baidu.com/reverse_geocoding/v3';

function createUrlParams(data) {
  return Object.keys(data)
    .map((param) => `${param}=${data[param]}`)
    .join('&');
}

export function getForwardGeocodingEndpoint({ apiKey, country, address, autocomplete = false }) {
  const params = createUrlParams({
    query: address,
    ak: apiKey,
    region: '全国',
    output: 'json',
  });

  return `${apiGeocodingUrl}?${params}`;
}

export function getReverseGeocodingEndpoint({ apiKey, location, language }) {
  const params = createUrlParams({
    ak: apiKey,
    output: 'json',
    coordtype: 'wgs84ll',
    location: `${location.lat},${location.lng}`,
  });

  return `${apiReverseGeocodingUrl}?${params}`;
}
