import { useState, useEffect } from 'react';
import { breakpointMobile, breakpointLarge } from '../constants';

const getIsMobile = () => {
  if (!window || !window.innerWidth) {
    return true;
  }
  return window.matchMedia
    ? window.matchMedia(`(max-width: ${breakpointMobile - 1}px)`).matches
    : window.innerWidth < breakpointMobile;
};

const getIsLarge = () => {
  if (!window || !window.innerWidth) {
    return false;
  }
  return window.matchMedia
    ? window.matchMedia(`(min-width: ${breakpointLarge}px)`).matches
    : window.innerWidth >= breakpointLarge;
};

/**
 * @returns {{isLarge: boolean, isMobile: boolean}}
 */
export function useBreakpoints() {
  const [state, setState] = useState({
    isMobile: getIsMobile(),
    isLarge: getIsLarge(),
  });
  useEffect(() => {
    let timeout;
    const { isMobile, isLarge } = state;

    const update = () => {
      const mobile = getIsMobile();
      const large = !mobile && getIsLarge();
      if (mobile !== isMobile || large !== isLarge) {
        setState({
          isMobile: mobile,
          isLarge: large,
        });
      }
    };

    const invalidate = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(update, 20);
    };

    window.addEventListener('resize', invalidate);

    return () => {
      window.removeEventListener('resize', invalidate);
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [state]);

  return state;
}

/**
 * @returns {boolean}
 */
export function useIsMobile() {
  const { isMobile } = useBreakpoints();
  return isMobile;
}

/**
 * @returns {boolean}
 */
export function useIsLarge() {
  const { isLarge } = useBreakpoints();
  return isLarge;
}
