import { useContext } from 'react';

import { TrackingContext } from './provider';

export const useTrackingContext = () => useContext(TrackingContext);

export const useTracking = (eventName, eventData) => {
  // const config = useConfig();
  // return config[key];
};
