import React, { useCallback, useState, useEffect } from 'react';
import FocusLock from 'react-focus-lock';
import PropTypes from 'prop-types';
import { confKeys } from '../../constants';
import { useConfigValue } from '../../contexts/config';
import { Overlay } from '../../components/Overlay';
import { PopupOpenButton } from '../../components/Popup';
import { PopupView } from './PopupView';
import { usePopupTransition } from './usePopupTransition';
import {
  useTrackingContext,
  useSearchResults,
  useSearchPages,
  useActiveSearchFilters,
  useVisibleFilters,
} from '../../hooks';

import { findElementRecursive } from '../../utils/dom';
import { groupObjects } from '../../utils/filters';

const ccid = 'storefinder-popup';

export const PopupWidget = ({ initiallyOpened = false }) => {
  const trackingContext = useTrackingContext();
  const searchResults = useSearchResults();
  const [currentPage] = useSearchPages();
  const { track } = trackingContext;

  const perPage = useConfigValue(confKeys.ITEMS_PER_PAGE);
  const button = useConfigValue(confKeys.POPUP_FIND_STORE_BUTTON);
  const widgetId = useConfigValue(confKeys.WIDGET_ID);
  const buttonMode = button && button['button_behaviour'];
  const [state, setState] = usePopupTransition({ initiallyOpened });
  const { hidden, active, ready } = state;
  const [showBtn, setShowBtn] = useState(false);

  const filters = useActiveSearchFilters();
  const visibleFilters = useVisibleFilters();

  useEffect(() => {
    if (button && button !== 'default') {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  }, [button]);

  useEffect(() => {
    const triggerElements = Array.prototype.slice.call(
      document.querySelectorAll('.cc-sf-button')
    );

    triggerElements.forEach(() => {
      const eventData = {
        button_host: 'brand',
        button_type: 'default',
      };

      track('lsf_button_impression', eventData);
    });
  }, [track]);

  const getStores = (currentPage, searchResults, perPage) => {
    const start = currentPage * perPage;
    const end = (currentPage + 1) * perPage;

    let results = searchResults ? searchResults.slice(start, end) : [];

    return results
      .map(store => store.shop)
      .map((store, index) => {
        return {
          local_store_id: store.Id,
          local_store_name: store.Name,
          local_store_position: currentPage * perPage + index,
          distance_to_store:
            store.GeoInfo && store.GeoInfo[0]
              ? parseFloat(store.GeoInfo[0].Distance)
              : 0,
          click_and_collect_displayed:
            store.OfferInfo && store.OfferInfo.ClickAndCollectLink,
        };
      });
  };

  const handleCloseTracking = useCallback(() => {
    track('widget_closed', {
      page_number: currentPage + 1,
      local_store_list: getStores(currentPage, searchResults, perPage),
      selected_filters: groupObjects(filters),
      available_filters: groupObjects(visibleFilters),
    });
  }, [currentPage, perPage, searchResults, track, filters, visibleFilters]);

  const close = useCallback(
    event => {
      if (event) {
        event.stopPropagation();
        event.preventDefault();

        setState(prevState => ({
          ...prevState,
          opened: false,
        }));
        handleCloseTracking();
      }
    },
    [handleCloseTracking, setState]
  );

  const open = useCallback(() => {
    track('widget_open_impression', {});

    setState(prevState => ({
      ...prevState,
      opened: true,
    }));
  }, [setState, track]);

  const handleClickDoc = useCallback(
    event => {
      const element = event.target;
      const triggerElement = findElementRecursive(element, '.cc-sf-button');
      let ccuid = null;

      if (element) {
        ccuid =
          element.getAttribute('data-ccuid') ||
          (element.parentElement &&
            element.parentElement.getAttribute('data-ccuid'));
      }

      if (window.cclog) {
        console.log('target', element);
        console.log('element clicked', triggerElement);
        console.log('ccuid', ccuid);
      }

      // if btn contains data-ccuid, multiple widgets are present!
      if (!!ccuid) {
        if (ccuid !== widgetId) {
          return false;
        }
      }

      if (triggerElement && event) {
        event.preventDefault();
        event.stopPropagation();

        open();

        track('lsf_button_click', {
          button_host: 'brand',
          button_type: 'default',
        });
      }
    },
    [open, track, widgetId]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickDoc);
    return () => {
      document.removeEventListener('click', handleClickDoc);
    };
  }, [handleClickDoc]);

  return (
    <>
      {showBtn && (
        <PopupOpenButton
          kind={buttonMode || null}
          opened={active}
          onClick={open}
        />
      )}
      {!hidden && (
        <Overlay id={`${ccid}-overlay`} opened={active} onClose={close}>
          <FocusLock returnFocus>
            <PopupView active={active} ready={ready} onClose={close} />
          </FocusLock>
        </Overlay>
      )}
    </>
  );
};

PopupWidget.propTypes = {
  initiallyOpened: PropTypes.bool,
};

export default PopupWidget;
