import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import defaultConfig from './defaultConfig';
import { fixLocaleData } from './utils';

export const ConfigContext = createContext(defaultConfig);

const createConfig = customConfig => {
  return fixLocaleData({
    ...defaultConfig,
    ...customConfig,
  });
};

export const ConfigProvider = ({ children, config: customConfig }) => {
  const [config, setConfig] = useState(createConfig(customConfig));
  // const [config2, setConfig2] = useState(createConfig2(customConfig));

  useEffect(() => {
    if (customConfig) {
      setConfig(createConfig(customConfig));
    }
  }, [customConfig]);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

ConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.any,
};
