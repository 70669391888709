export const displayModes = {
  TABS: 'tabs',
  LIST: 'list',
  MAP: 'map',
};

export const displayModeIds = {
  LIST: 'list',
  MAP: 'map',
};
