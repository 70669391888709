import styled from 'styled-components';
import { breakpointMobile, breakpointSmall } from '../../../constants';

export const Address = styled.div`
  margin: 10px 0 0;
  max-width: 260px;
  text-overflow: ellipsis;
  overflow: ${({ cropped = false }) => (cropped ? 'hidden' : 'visible')};
  white-space: ${({ cropped = false }) => (cropped ? 'nowrap' : 'wrap')};

  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    max-width: 180px;
  }

  @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    max-width: 180px;
  }
  @media (max-width: ${breakpointMobile}px) {
    max-width: 50vw;
  }
  @media (max-width: ${breakpointSmall}px) {
    max-width: 120px;
  }
`;
