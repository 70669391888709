import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  OpeningsContainer,
  ContactsContainer,
} from './LocalStoreListItemStyled';
import { LocalStoresResultShape } from '../../../api';
import { useIsLarge } from '../../../hooks';
import { Row } from '../../Layout';
import {
  LocalStoreAddress,
  LocalStoreContacts,
  LocalStoreName,
  LocalStoreOpening,
  LocalStorePin,
  LocalStoreCustomAttr,
} from '../';
import {
  Card,
  CardTabSide,
  DetailsContainer,
  LocationContainer,
} from '../LocalStoreCard';

import { confKeys } from '../../../constants';
import { useConfigValue } from '../../../contexts/config';

const ccid = 'localstore-listitem';

export const LocalStoreListItem = ({
  item,
  onClickItem,
  selected,
  expandOpeningOnSelect,
}) => {
  const { id, shop } = item;
  const isLarge = useIsLarge();
  const customAttributes = useConfigValue(confKeys.FILTER_CUSTOM_FIELDS);
  const attributesList = customAttributes
    ? customAttributes.custom_attributes_selection
    : [];
  const handleClickItem = useCallback(
    event => {
      if (onClickItem && id) {
        onClickItem(event, id);
      }
    },
    [id, onClickItem]
  );

  const isActive = !!selected;
  const hasOpeningHours = shop.OpeningHours && shop.OpeningHours.length > 0;
  const themeColor = useConfigValue(confKeys.COLOR_SCHEME);
  const showWhatsApp = useConfigValue(confKeys.SHOW_WHATSAPP);

  return (
    <Card
      data-ccid={ccid}
      data-itemid={id}
      role="listitem"
      onClick={handleClickItem}
      active={selected}>
      <Row data-ccid={`${ccid}-inner`}>
        <LocationContainer data-ccid={`${ccid}-location-container`}>
          <LocalStorePin shop={shop} active={selected} />
        </LocationContainer>
        <DetailsContainer data-ccid={`${ccid}-details-container`}>
          <LocalStoreName shop={shop} cropped={!isActive} />
          <LocalStoreCustomAttr
            shop={shop}
            allowedAttributes={attributesList}
          />
          <LocalStoreAddress shop={shop} cropped={!isActive} />
          {hasOpeningHours && !isLarge && (
            <LocalStoreOpening
              shop={shop}
              expanded={isActive && expandOpeningOnSelect}
            />
          )}
        </DetailsContainer>
        {hasOpeningHours && isLarge && (
          <OpeningsContainer>
            <LocalStoreOpening
              shop={shop}
              expanded={isActive && expandOpeningOnSelect}
            />
          </OpeningsContainer>
        )}
        <ContactsContainer>
          <LocalStoreContacts
            showWhatsApp={showWhatsApp}
            shop={shop}
            viewType={'list'}
          />
        </ContactsContainer>
      </Row>
      <CardTabSide themeColor={themeColor} active={selected} />
    </Card>
  );
};

LocalStoreListItem.propTypes = {
  index: PropTypes.number,
  item: LocalStoresResultShape.isRequired,
  onClickItem: PropTypes.func,
  selected: PropTypes.bool,
  expandOpeningOnSelect: PropTypes.bool,
};

export default LocalStoreListItem;
