import React from 'react';
import axios from 'axios';
import { MapsContext } from '../context';
import {
  getForwardGeocodingEndpoint,
  getReverseGeocodingEndpoint,
} from './config';

function parseAddress(place) {
  if (place) {
    const data = {
      address: place.formatted_address,
    };

    if (place.address_components instanceof Array) {
      place.address_components.forEach(({ types, short_name, long_name }) => {
        if (types.filter(type => type === 'route')[0]) {
          data.street = short_name;
        } else if (
          types.filter(type => type === 'locality' || type === 'postal_town')[0]
        ) {
          data.city = long_name;
        } else if (types.filter(type => type === 'country')[0]) {
          data.country = long_name;
          data.countryShortName = short_name;
        } else if (types.filter(type => type === 'postal_code')[0]) {
          data.zip = short_name;
        }
      });

      if (data.zip && data.city && data.country) {
        data.address = [[data.zip, data.city].join(' '), data.country].join(
          ', '
        );
      }
    }

    return data;
  }
}

export function useForwardGeocoding() {
  const { apiKey, country, language } = React.useContext(MapsContext);
  return React.useCallback(
    queryText => {
      const url = getForwardGeocodingEndpoint({
        apiKey,
        country,
        language,
        address: queryText,
      });
      return axios
        .get(url)
        .then(response => response.data)
        .then(data => {
          const { results } = data;
          return results && results[0];
        });
    },
    [apiKey, country, language]
  );
}

export function useReverseGeocoding() {
  const { apiKey, country, language } = React.useContext(MapsContext);
  return React.useCallback(
    latLng => {
      const url = getReverseGeocodingEndpoint({
        apiKey,
        country,
        language,
        location: latLng,
      });
      return axios
        .get(url)
        .then(response => response.data)
        .then(data => {
          const { results } = data;

          if (results && results[0]) {
            const address = parseAddress(results[0]);
            if (address) {
              return address;
            }
          }
        });
    },
    [apiKey, country, language]
  );
}
