/**
 * Centers maps via center, zoom or bounds
 * @param map
 * @param {{bounds, center, zoom}} params
 */
export function centerMap(map, params) {
  const { bounds, center, zoom } = params;
  if (!map) return;
  if (center) {
    map.panTo(center);
  }
  if (zoom) {
    map.setZoom(zoom);
  }
  if (bounds) {
    map.fitBounds(bounds);
  }
}

export function fixCenterOffset(map, latlng, offset) {
  const { google } = window;
  const scale = Math.pow(2, map.getZoom());
  const projection = map.getProjection();
  if (!projection) {
    return latlng;
  }
  const offsetX = !isNaN(offset.x) ? offset.x : 0;
  const offsetY = !isNaN(offset.y) ? offset.y : 0;
  const worldCoordinateCenter = projection.fromLatLngToPoint(latlng);
  const pixelOffset = new google.maps.Point(
    offsetX / scale || 0,
    offsetY / scale || 0
  );
  const worldCoordinateNewCenter = new google.maps.Point(
    worldCoordinateCenter.x - pixelOffset.x,
    worldCoordinateCenter.y + pixelOffset.y
  );
  return projection.fromPointToLatLng(worldCoordinateNewCenter);
}

export function toLatLng(location) {
  const { google } = window;
  const lat =
    typeof location.lat === 'function'
      ? location.lat()
      : parseFloat(location.lat);
  const lng =
    typeof location.lng === 'function'
      ? location.lng()
      : parseFloat(location.lng);
  return new google.maps.LatLng(lat, lng);
}

/**
 * see https://stackoverflow.com/questions/10339365/google-maps-api-3-fitbounds-padding-ensure-markers-are-not-obscured-by-overlai
 * @param {google.maps.Map} map
 * @param {google.maps.LatLngBounds} bounds
 * @param {{top, left, right, bottom}} paddingXY
 */
export function fitBoundsWithPadding(map, bounds, paddingXY) {
  const { google } = window;

  // map.fitBounds(bounds);
  const projection = map.getProjection();
  if (!projection) return;

  bounds = new google.maps.LatLngBounds(
    bounds.getSouthWest(),
    bounds.getNorthEast()
  );

  const fZoom = Math.pow(2, map.getZoom());
  const paddings = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };
  ['left', 'top', 'right', 'bottom'].forEach(key => {
    if (!isNaN(paddingXY[key])) {
      paddings[key] = paddingXY[key];
    }
  });

  let point1, point2, newPoint;

  // SW
  point1 = projection.fromLatLngToPoint(bounds.getSouthWest());
  point2 = new google.maps.Point(
    typeof paddings.left == 'number' ? paddings.left / fZoom : 0,
    typeof paddings.bottom == 'number' ? paddings.bottom / fZoom : 0
  );
  newPoint = projection.fromPointToLatLng(
    new google.maps.Point(point1.x - point2.x, point1.y + point2.y)
  );
  bounds.extend(newPoint);

  // NE
  point1 = projection.fromLatLngToPoint(bounds.getNorthEast());
  point2 = new google.maps.Point(
    typeof paddings.right == 'number' ? paddings.right / fZoom : 0,
    typeof paddings.top == 'number' ? paddings.top / fZoom : 0
  );
  newPoint = projection.fromPointToLatLng(
    new google.maps.Point(point1.x + point2.x, point1.y - point2.y)
  );
  bounds.extend(newPoint);

  return bounds;
}

export const getMarkerMaxZIndex = (() => {
  let topZIndex = 0;
  return () => {
    const { google } = window;
    if (topZIndex === 0) {
      topZIndex = google.maps.Marker.MAX_ZINDEX + 1;
    } else {
      topZIndex = topZIndex + 1;
    }
    return topZIndex;
  };
})();
