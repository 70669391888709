import React, { useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';
import { confKeys, textKeys } from '../../constants';
import {
  useConfig,
  useIsGeolocationPending,
  useTranslation,
  useMapsComponents,
  useSearchAction,
  useSearchLocation,
  useConfigValue,
  useFiltersEnabled,
  useTrackingContext,
  useResultsDisplayMode,
  useDisplayModesLocalized,
  usePrevious,
  useResultCount,
} from '../../hooks';
import { searchActionTypes } from '../../contexts/search';
import {
  searchLocalStores,
  createResultList,
  getResponseSummary,
  exportCustomFields,
} from '../../api';
import { Input } from '../Input';
import { AutoLocateIconButton } from '../AutoLocateButton';
import { SearchFilter } from '../SearchFilter';
import { Container, Field, IconButtonsContainer } from './styles';
import { getHasAddress } from '../../utils/geolocation';

const ccid = 'search-field';

export const SearchField = ({
  disableFilters = false,
  disableLocationDetection = false,
}) => {
  const config = useConfig();
  const showWhatsApp = useConfigValue(confKeys.SHOW_WHATSAPP);

  if (showWhatsApp) {
    const whatsAppCustomAttribute = 'CustomContactViaWhatsapp';

    // if custom attributes are not selected, add array with a single whatsapp attribute
    if (!config?.customAttributes) {
      config.customAttributes = {
        custom_attributes_selection: [whatsAppCustomAttribute],
      };
    }

    const customAttributesSelection =
      config?.customAttributes?.custom_attributes_selection;

    // if custom attributes are selected, add whatsapp to an existing array
    if (
      customAttributesSelection &&
      !customAttributesSelection.includes(whatsAppCustomAttribute)
    ) {
      config.customAttributes.custom_attributes_selection = [
        ...customAttributesSelection,
        whatsAppCustomAttribute,
      ];
    }
  }

  const trackingContext = useTrackingContext();
  const { track } = trackingContext;
  const t = useTranslation();
  const searchLocation = useSearchLocation();

  const dispatch = useSearchAction();
  const isGeolocationPending = useIsGeolocationPending();
  const autoLocateEnabled = useConfigValue(confKeys.AUTO_LOCATE_BTN);
  const filtersEnabled = useFiltersEnabled();
  const { SearchInput } = useMapsComponents();
  const [displayMode] = useResultsDisplayMode();
  const displayModeOptions = useDisplayModesLocalized();
  const prevLocation = usePrevious({ searchLocation });
  const resultsCount = useResultCount();

  useEffect(() => {
    if (prevLocation && prevLocation.searchLocation) {
      const prevAddress = prevLocation.searchLocation.address;
      const newAddress = searchLocation.address;

      if (prevAddress !== newAddress) {
        dispatch({
          type: searchActionTypes.SET_FILTERS,
          filters: [],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchLocation]);

  useEffect(() => {
    if (searchLocation) {
      if (config.disableApiRequests) {
        return;
      }
      dispatch({
        type: searchActionTypes.REQUEST_RESULTS,
      });

      searchLocalStores({
        ...config,
        location: searchLocation,
      })
        .then(data => {
          dispatch({
            type: searchActionTypes.SEARCH_RESULTS,
            summary: getResponseSummary(data),
            searchResults: createResultList(data),
          });

          dispatch({
            type: searchActionTypes.SET_CUSTOM_FIELDS,
            customFields: exportCustomFields(createResultList(data)),
          });
        })
        .catch(error => {
          dispatch({
            type: searchActionTypes.RESPONSE_ERROR,
            error,
          });
        });
    }
  }, [dispatch, config, searchLocation]);

  const getDisplayTabsDetails = useCallback(() => {
    const displayModeDetails = displayModeOptions
      .filter(mode => mode.id === displayMode)
      .map((mode, index) => {
        return {
          label: mode.label,
          position: index,
        };
      })[0];

    return {
      displayMode,
      ...displayModeDetails,
    };
  }, [displayMode, displayModeOptions]);

  const handleSearchLocationChange = useCallback(
    ({ address, type, lat, lng, city, country, post_code }) => {
      const emptyValue = 'n/a';

      const payload = {
        post_code: post_code || emptyValue,
        city: city || emptyValue,
        country: country || emptyValue,
      };

      if (getDisplayTabsDetails()) {
        const tabs = getDisplayTabsDetails();

        payload.displayed_tab_type = tabs.displayMode;
        payload.displayed_tab_name = tabs.label;
        payload.displayed_tab_position = tabs.position;
      }

      track('post_code_lookup', payload);

      dispatch({
        type: searchActionTypes.SEARCH_LOCATION,
        searchLocation: {
          address,
          type,
          lat,
          lng,
        },
      });
    },
    [dispatch, getDisplayTabsDetails, track]
  );

  // Handle automatically detected address
  const handleAutoDetectAddress = useCallback(
    addressData => {
      if (!addressData) {
        return;
      }
      const emptyValue = 'n/a';

      const payload = {
        post_code: addressData.zip || emptyValue,
        city: addressData.city || emptyValue,
        country: addressData.country || emptyValue,
      };

      if (getDisplayTabsDetails()) {
        const tabs = getDisplayTabsDetails();

        payload.displayed_tab_type = tabs.displayMode;
        payload.displayed_tab_name = tabs.label;
        payload.displayed_tab_position = tabs.position;
      }

      setTimeout(() => {
        track('automatic_post_code_lookup', payload);
      }, 200);
    },
    [getDisplayTabsDetails, track]
  );

  const handleChangeFilters = useCallback(
    filters => {
      dispatch({
        type: searchActionTypes.SET_FILTERS,
        filters: filters || [],
      });
    },
    [dispatch]
  );

  const inputPlaceholder = t(
    isGeolocationPending
      ? textKeys.NOTICE_GEOLOCATION_PROGRESS
      : textKeys.SEARCH_PLACEHOLDER
  );

  const shouldDisplayFilters =
    !disableFilters &&
    filtersEnabled &&
    getHasAddress(searchLocation) &&
    resultsCount !== 0;

  return (
    <Container
      data-ccid={`${ccid}-container`}
      hasAddress={getHasAddress(searchLocation)}>
      <Field data-ccid={`${ccid}`}>
        {SearchInput && (
          <SearchInput
            data-ccid={`${ccid}-input`}
            as={Input}
            placeholder={inputPlaceholder}
            onLocationChange={handleSearchLocationChange}
            onAutoDetectAddress={handleAutoDetectAddress}
            searchLocation={searchLocation}
            aria-label={t(textKeys.SEARCH_PLACEHOLDER)}
          />
        )}
        {!SearchInput && (
          <Input
            data-ccid={`${ccid}-input`}
            placeholder={inputPlaceholder}
            aria-label={t(textKeys.SEARCH_PLACEHOLDER)}
          />
        )}
        <IconButtonsContainer>
          {!disableLocationDetection && autoLocateEnabled && (
            <AutoLocateIconButton />
          )}
        </IconButtonsContainer>
      </Field>
      {shouldDisplayFilters && <SearchFilter onChange={handleChangeFilters} />}
    </Container>
  );
};

SearchField.propTypes = {
  disableFilters: PropTypes.bool,
  disableLocationDetection: PropTypes.bool,
};

export default SearchField;
