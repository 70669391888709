import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import defaultTheme from './defaultTheme';
import { themeShape } from './types';
import { createTheme } from './utils';
import { useIsRtlLanguage } from '../i18n';

export const ThemeContext = createContext(defaultTheme);

export const ThemeProvider = ({ children, theme: customTheme }) => {
  const rtl = useIsRtlLanguage();
  const [theme, setTheme] = useState({
    ...createTheme(customTheme),
    rtl,
  });

  useEffect(() => {
    if (customTheme) {
      setTheme(prevState => ({
        ...prevState,
        ...createTheme(customTheme),
      }));
    }
  }, [customTheme]);

  useEffect(() => {
    setTheme(prevState => ({
      ...prevState,
      rtl: rtl,
    }));
  }, [rtl]);

  return (
    <ThemeContext.Provider value={theme}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape(themeShape),
};

export const withTheme = Component => {
  return props => {
    const theme = useContext(ThemeContext);
    return <Component theme={theme || defaultTheme} {...props} />;
  };
};
