export const confKeys = {
  // Settings that can be defined in data-* script tag attributes
  TOKEN: 'token',
  SUB_ID: 'subid',
  SUBID_ID: 'subid_id',
  LOCALE: 'locale',
  LANGUAGE: 'language',
  COUNTRY: 'country',
  DISPLAY_LANGUAGE: 'displaylanguage',
  TRACKING_ID: 'trackingid',
  WIDGET_ID: 'widgetid',
  GTM_TRACKING: 'eventgtm',
  ANON_T: 'anon_t',
  IS_MULTIPLE: 'ismultiple',
  WIDGETS: 'widgets',
  VIEW_ID: 'viewid',
  TARGET_CONTAINER: 'target',

  // ADITIONAL TRACKING PARAMATERS
  T_UTM_SOURCE: 'utm_source',
  T_UTM_TERM: 'utm_term',
  T_UTM_MEDIUM: 'utm_medium',
  T_UTM_CONTENT: 'utm_content',
  T_UTM_CAMPAIGN: 'utm_campaign',
  T_UTM_TRACKING: 'generic_tracking',
  DEVICE_TYPE: 'device_type',
  API_TOKEN: 'apiToken',
  ANONYMOUS_TOKEN: 'anonymousToken',

  // Default settings can be changed in the widget configuration
  AUTO_LOCATE_BTN: 'autoLocateBtn',
  BRAND_LOGO: 'brandLogo',
  FILTER_CUSTOMER_TYPE: 'filterCustomerType',
  FILTER_OPEN_NOW: 'filterOpenNow',
  FILTER_CUSTOM_FIELDS: 'customAttributes',
  ITEMS_PER_PAGE: 'itemsPerPage',
  MAP_LIST_MODE: 'mapListMode',
  MAP_THEME: 'mapTheme',
  MAP_PIN_COLOR: 'mapPinColor',
  MAP_VIEW_NAME: 'mapViewName',
  MAP_VIEW_ROUTE_TO_BUTTON: 'mapViewRouteTo',
  MAP_VIEW_RETAILERS_PER_PAGE: 'mapItemsPerPage',
  LIST_VIEW_RETAILERS_PER_PAGE: 'listItemsPerPage',
  LIST_VIEW_NAME: 'listViewName',
  LIST_VIEW_ROUTE_TO_BUTTON: 'listViewRouteTo',
  MAPS_API_KEY: 'mapsApiKey',
  MAPS_API_PROVIDER: 'mapsApiProvider',
  DISTANCE_UNIT: 'distanceUnit',
  MARKER_COLOR: 'markerColor',
  MAX_RESULTS: 'maxResults',
  RESULTS_DISPLAY_MODE: 'resultsDisplayMode',
  SEARCH_PAGINATION_MODE: 'searchPaginationMode',
  SEARCH_RADIUS: 'searchRadius',
  COLOR_SCHEME: 'colorScheme',
  SKIN_COLOR: 'skinColor',
  SKIN_ACTIVE_COLOR: 'skinActiveColor',
  SHOW_WHATSAPP: 'show_whatsapp',
  WIDGET_MODE: 'widgetMode',
  PRODUCT_DESCRIPTION: 'productDescription',
  PRODUCT_DESCRIPTION_POSITION: 'productDescriptionPosition',
  PRODUCT_TITLE: 'productTitle',

  // Popup configuration
  POPUP_SIZE: 'popupSize',
  POPUP_BUTTON_MODE: 'popupButtonMode',
  POPUP_BUTTON_STYLES: 'popupButtonStyles',
  POPUP_BRAND_IMAGE: 'popupBrandImage',
  POPUP_HEADER: 'popupHeader',
  POPUP_HEADER_ALIGNMENT: 'popupHeaderAlignment',
  POPUP_FIND_STORE_BUTTON: 'findStoreButton',

  // Override translations or theme in the widget configuration
  MESSAGES: 'messages',
  THEME: 'theme',

  // Debug flags
  DISABLE_API_REQUESTS: 'disableApiRequests',
  DISABLE_FILTERS: 'disableFilters',
  DISABLE_LOCATION_DETECTION: 'disableLocationDetection',
  DISABLE_LOCATION_SEARCH: 'disableLocationSearch',
};
