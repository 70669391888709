import React from 'react';
import { MapsContext } from '../context';
import {
  getForwardGeocodingEndpoint,
  getReverseGeocodingEndpoint,
} from './config';

function parseForwardGeocodingResult(result) {
  if (result && result.features && result.features.length > 0) {
    const data = {};
    result.features.forEach(feature => {
      const addressParts = feature.place_name.split(', ');
      switch (feature.place_type[0]) {
        case 'postcode':
          if (!data.zip) {
            data.lat = feature.center[1];
            data.lng = feature.center[0];
            data.zip = feature.text;
            data.city = addressParts[1];
            data.country = addressParts[addressParts.length - 1];
            data.address = feature.place_name;
          }
          break;
        default:
          if (!data.address) {
            data.lat = feature.center[1];
            data.lng = feature.center[0];
            data.country = addressParts[addressParts.length - 1];
            data.address = feature.place_name;
          }
          break;
      }
    });
    return data;
  }
}

function parseReverseGeocodingResult(result) {
  if (result && result.features && result.features.length > 0) {
    const data = {};
    result.features.forEach(feature => {
      switch (feature.place_type[0]) {
        case 'postcode':
          const addressParts = feature.place_name.split(', ');
          data.zip = feature.text;
          data.city = addressParts[1];
          data.country = addressParts[addressParts.length - 1];
          data.address = feature.place_name;
          break;
        case 'country':
          data.country = feature.text;
          break;
        default:
          break;
      }
    });
    return data;
  }
}

export function useForwardGeocoding() {
  const { apiKey, country, language } = React.useContext(MapsContext);
  return React.useCallback(
    queryText => {
      const url = getForwardGeocodingEndpoint({
        apiKey,
        country,
        language,
        address: queryText,
        autocomplete: false,
      });
      return fetch(url)
        .then(response => response.json())
        .then(result => parseForwardGeocodingResult(result));
    },
    [apiKey, country, language]
  );
}

export function useReverseGeocoding() {
  const { apiKey, country, language } = React.useContext(MapsContext);
  return React.useCallback(
    location => {
      const url = getReverseGeocodingEndpoint({
        apiKey,
        country,
        language,
        location: location,
      });
      return fetch(url)
        .then(response => response.json())
        .then(result => parseReverseGeocodingResult(result));
    },
    [apiKey, country, language]
  );
}

export function useAutocomplete() {
  const { apiKey, country, language } = React.useContext(MapsContext);
  return React.useCallback(
    queryText => {
      const url = getForwardGeocodingEndpoint({
        apiKey,
        country,
        language,
        address: queryText,
        autocomplete: true,
      });
      return fetch(url).then(response => response.json());
    },
    [apiKey, country, language]
  );
}
