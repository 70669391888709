import { useContext } from 'react';
import { I18nContext } from './provider';

export const useTranslation = () => {
  const { translate } = useContext(I18nContext);
  return translate;
};

export const useIsRtlLanguage = () => {
  const { rtl } = useContext(I18nContext);
  return rtl;
};
