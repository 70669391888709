import axios from 'axios';

export const loadWidgetConfig = async widgetId => {
  return axios
    .get(`${process.env.REACT_APP_CONFIGURATION_URL}${widgetId}.json`)
    .then(response => response.data);
};

export const getSubidId = async options => {
  const baseUrl = 'https://api-eu.global.commerce-connector.com/REST/2.0/';
  const endpoint = 'Subid';
  const finalUrl = `${baseUrl}${endpoint}?F_SubId=${options.subid}&context=local&token=${options.token}&Country=${options.country}`;
  if(options.subid) {
    return axios
    .get(`${finalUrl}`, { timeout: 500 })
    .then(response => response.data);
  }
  throw Error('No subID'); 
  
};

export const loadConfigurationTranslations = async widgetId => {
  const translationsId = widgetId.split('_')[1];
  const jsonUrl = `${process.env.REACT_APP_CONFIGURATION_URL}${translationsId}.json`;

  return axios.get(jsonUrl).then(response => response.data);
};

export const loadGlobalTranslations = async widgetId => {
  const translationsId = widgetId.split('_')[0];
  const jsonUrl = `${process.env.REACT_APP_GLOBAL_TRANSLATIONS_URL}${translationsId}.json`;

  return axios.get(jsonUrl).then(response => response.data);
};

// export const loadAnonT = async () => {
//   const url = 'https://services.global.commerce-connector.com/anon-t?source=gsf';

//   return axios.get(url, { timeout: 500 }).then(response => response.data);
// }
