import PropTypes from 'prop-types';

export const themeColorsShape = {
  text: PropTypes.string,
  textSecondary: PropTypes.string,
  border: PropTypes.string,
  textLink: PropTypes.string,
  textLinkHover: PropTypes.string,
  spinner: PropTypes.string,
  mapMarker: PropTypes.string,
  highlight: PropTypes.string,
  highlightActive: PropTypes.string,
};

export const themeFontShape = {
  fontFamily: PropTypes.string,
  fontWeight: PropTypes.string,
};

export const themeTypographyShape = {
  main: PropTypes.shape(themeFontShape),
  bold: PropTypes.shape(themeFontShape),
};

export const themeShape = {
  rtl: PropTypes.bool,
  typography: PropTypes.shape(themeTypographyShape),
  colors: PropTypes.shape(themeColorsShape),
};

export const themePropTypes = PropTypes.shape(themeShape);
