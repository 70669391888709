const apiBaseUrl =
  'https://maps.googleapis.com/maps/api/js?libraries=geometry,places';
const callbackFunctionName = 'CCGoogleMapsCallback';

export const GoogleMapsApi = (() => {
  const init = ({ apiKey, country, language }) => {
    const promise = new Promise((resolve, reject) => {
      window[callbackFunctionName] = () => {
        GoogleMapsApi.isLoaded = true;
        resolve();
      };
      let apiUrl = `${apiBaseUrl}&callback=${callbackFunctionName}`;
      if (apiKey) {
        apiUrl += '&key=' + apiKey;
      } else {
        reject('Invalid googleMaps API configuration');
        return;
      }
      if (country) {
        apiUrl += `&region=${country}`;
      }
      if (language) {
        apiUrl += `&language=${language}`;
      }

      const script = document.createElement('script');
      script.async = true;
      script.defer = true;
      script.src = apiUrl;
      script.onerror = function () {
        reject('Could not load GoogleMaps API');
      };
      document.head.appendChild(script);
    });

    GoogleMapsApi.init = () => promise;
    return promise;
  };
  return {
    init,
    isLoaded: false,
  };
})();

export default GoogleMapsApi;
