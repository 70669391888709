import styled from 'styled-components';
import { BaseButton, Button } from '../Button';
import { breakpointMobile } from '../../constants';

export const DropdownWrapper = styled.div`
  display: inline-block;
  position: relative;
  max-width: 100%;
  margin-right: 16px;

  .Tooltip-Tip {
    left: 50% !important;
  }
  @media (max-width: ${breakpointMobile}px) {
    margin-right: 8px;
  }
`;

export const DropdownLabel = styled.span`
  display: inline-block;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 4px;
  cursor: pointer;
  font-size: 13px;
`;

export const StyledSelect = styled.ul`
  position: absolute;
  display: inline-block;
  left: 0;
  display: inline-block;
  top: 100%;
  width: 250px;
  margin-top: 4px;
  padding-left: 0;
  z-index: 1014;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
  border-radius: 4px;
  background-color: #fff;
  list-style: none !important;
  overflow-y: auto;
  max-height: 500px;

  @media (max-width: ${breakpointMobile}px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    margin: 0;
    padding-bottom: 60px;
  }

  ${({ theme }) =>
    theme.rtl &&
    `
    padding: 9px;
  `}
`;

export const StyledOption = styled.li`
  color: ${props => (props.selected ? 'lightgrey' : 'black')};
  cursor: pointer;
`;

export const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 13px;
  font-weight: 400;
  /* line-height: 16px; */
  border: ${({ open, themeColor }) =>
    open ? `1px solid ${themeColor}` : '1px solid #c8c8c8'};
  height: 40px;
  padding: 4px;
  cursor: pointer;
  user-select: none;
  border-radius: 20px;
  padding: 4px 4px 4px 8px;
  background: white;

  > input[type='checkbox'] {
    margin: 0 !important;
  }
  @media (max-width: ${breakpointMobile}px) {
    height: 26px;
  }
`;

export const DropdownButtonText = styled('span')`
  display: inline-block;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;

export const MobileLabel = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 12px 0 0 8px;
`;

export const Item = styled.li`
  padding: 5px 10px;
  margin-top: 0.5em !important;
  margin-left: 0 !important;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const CloseButton = styled(BaseButton)`
  color: ${({ theme }) => theme.colors.highlight};
  position: absolute;
  top: 0;
  left: ${({ theme }) => (theme.rtl ? '0' : 'auto')};
  right: ${({ theme }) => (theme.rtl ? 'auto' : '0')};
  cursor: pointer;
  z-index: 1015;
  > svg {
    height: 18px;
    width: 18px;
    margin: ${({ theme }) => (!theme.rtl ? '0 0 6px 6px' : '0 6px 6px 0')};
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-top: 1px solid #c8c8c8;
  background-color: white;
  @media (max-width: ${breakpointMobile}px) {
    position: fixed;
    bottom: 0;
    left: 0;
    min-width: 100vw;
  }
`;

export const ActionButton = styled(Button)`
  min-height: auto;
  height: 24x;
  font-size: 13px;
  padding: 0 8px;
  word-break: break-word;
  max-width: 110px;
  min-width: auto;
  @media (max-width: ${breakpointMobile}px) {
    max-width: 100%;
  }
`;

export const GoBack = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid #c8c8c8;
`;
export const GoBackText = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const IconButton = styled.div`
  > svg {
    margin: 12px 8px 0 8px;
  }
`;

export const SelectWrapper = styled.div`
  position: fixed;
  left: ${({ left }) => `${left}px`};
  top: ${({ top }) => `${top}px`};
  z-index: 10;
`;

export const Badge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: ${({ theme }) => (theme.rtl ? 'auto' : '-4px')};
  left: ${({ theme }) => (theme.rtl ? '-4px' : 'auto')};
  top: 0px;
  height: 20px;
  min-width: 20px;
  background-color: ${({ themeColor }) => themeColor};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  font-size: 11px;
  font-family: ${({ theme }) => theme.typography.bold.fontFamily};
  font-weight: ${({ theme }) => theme.typography.bold.fontWeight};
  padding: 2px;
  @media (max-width: ${breakpointMobile}px) {
    height: 16px;
    min-width: 16px;
  }
`;
