import { useMemo } from 'react';
import {
  // isB2B, isB2C,
  isOpenNow,
  getCustomFieldFilter,
} from '../api/utils';
import { confKeys, textKeys } from '../constants';
import { useConfigValue } from '../contexts/config';
import { useTranslation } from '../contexts/i18n';
import { applyFilters, useSearch, useCustomFields } from '../contexts/search';

export const useFiltersEnabled = () => {
  const filterOpenNow = useConfigValue(confKeys.FILTER_OPEN_NOW);
  const filterCustomFields = useConfigValue(confKeys.FILTER_CUSTOM_FIELDS);
  const displayCustomFields =
    filterCustomFields?.custom_attributes_selection?.length > 0;
  return filterOpenNow || displayCustomFields;
};

export const useAvailableFiltersLocalized = () => {
  const filterCustomerType = useConfigValue(confKeys.FILTER_CUSTOMER_TYPE);
  const filterOpenNow = useConfigValue(confKeys.FILTER_OPEN_NOW);
  const filterCustomFields = useConfigValue(confKeys.FILTER_CUSTOM_FIELDS);
  const customFieldsAPI = useCustomFields();
  const t = useTranslation();

  return useMemo(() => {
    const selectedAttributes = filterCustomFields
      ? filterCustomFields.custom_attributes_selection
      : [];

    const filters = [];
    if (filterCustomerType) {
      // filters.push({
      //   groupId: 'customerType',
      //   id: 'customerType_B2B',
      //   label: t(textKeys.FILTER_B2B),
      //   filter: isB2B,
      // });
      // filters.push({
      //   groupId: 'customerType',
      //   id: 'customerType_B2C',
      //   label: t(textKeys.FILTER_B2C),
      //   filter: isB2C,
      // });
    }
    if (filterOpenNow) {
      filters.push({
        groupId: 'openNow',
        id: 'openNow',
        label: t(textKeys.FILTER_OPEN_NOW),
        name: t(textKeys.FILTER_OPEN_NOW),
        filter: isOpenNow,
      });
    }

    if (customFieldsAPI && customFieldsAPI.length) {
      customFieldsAPI.forEach(({ key, values, name }) => {
        if (selectedAttributes.includes(key)) {
          values.forEach(value => {
            filters.push({
              groupId: `customField_${key}`,
              id: `customField_${key}_${value}`,
              label: t(`filter_${key}_value_${value}`) || value,
              filter: getCustomFieldFilter(key, value),
              name: name,
            });
          });
        }
      });
    }
    return filters;
  }, [
    filterCustomFields,
    filterCustomerType,
    filterOpenNow,
    customFieldsAPI,
    t,
  ]);
};

export const useDisabledFilters = filters => {
  const { searchResults, customFields } = useSearch();
  const customFieldsLogic = customFields.custom_attributes_filter_logic
    ? customFields.custom_attributes_filter_logic
    : 'AND';

  return useMemo(() => {
    if (!searchResults || searchResults.length === 0) {
      return [];
    }
    if (!filters || filters.length === 0) {
      return [];
    }

    return filters.filter(filter => {
      const filteredResults = applyFilters(
        searchResults,
        [filter],
        customFieldsLogic
      );
      return filteredResults.length === 0;
    });
  }, [customFieldsLogic, filters, searchResults]);
};
