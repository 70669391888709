import React from 'react';
import PropTypes from 'prop-types';
import { Field, Label } from './CheckboxFieldStyled';
import { Spacer } from '../Layout';
import Checkbox from './Checkbox';

export const CheckboxField = ({
  checked,
  disabled,
  id,
  label,
  onChange,
  value,
}) => {
  return (
    <Field disabled={disabled}>
      <Checkbox
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        value={value}
      />
      <Spacer size={10} />
      <Label htmlFor={id}>{label}</Label>
    </Field>
  );
};

CheckboxField.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default CheckboxField;
