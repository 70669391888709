export const getDeviceType = () => {
  const ua = navigator.userAgent;
  const regex = {
    mobile: /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/,
    tablet: /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i,
  };

  if (regex.tablet.test(ua)) {
    return 'tablet';
  }

  if (regex.mobile.test(ua)) {
    return 'mobile';
  }

  return 'desktop';
};
