import { confKeys } from '../../constants';

const apiUrl = `${process.env.REACT_APP_EVENT_URL || ''}`;
const apiEnabled = true;

async function sendToApiGateway(url, data) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  });

  return response.json();
}

const addTimestamp = data => {
  const timestamp = new Date().getTime();
  return { client_timestamp: timestamp, ...data };
};

const removeNulls = data => {
  Object.keys(data).forEach(key => data[key] == null && delete data[key]);

  return data;
};

const trackEvent = (type, data) => {
  const updatedData = removeNulls(data);

  if (apiUrl && apiEnabled && updatedData) {
    sendToApiGateway(apiUrl, addTimestamp(updatedData))
      .then(response => {})
      .catch(err => {
        console.warn('sendToApiGateway ERROR', err);
      });
  }

  // Call external CCSFEventTrackingCallback function
  const { CCSFEventTrackingCallback } = window;

  if (typeof CCSFEventTrackingCallback === 'function') {
    try {
      // delete data.anon_t;
      CCSFEventTrackingCallback(type, addTimestamp(data));
    } catch (err) {
      console.warn(err);
    }
  }

  // Add support for Google Tag manager for those who enabled it through data-eventgtm
  if (data[confKeys.GTM_TRACKING] === 'true') {
    // delete data.anon_t;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'CommerceConnectorWidget',
      eventType: type,
      eventContext: addTimestamp(data),
    });
  }
};

export default trackEvent;
