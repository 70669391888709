import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  Track,
  Slide,
  Slider,
  Pagination,
} from './LocalStoreSliderStyled';
import {
  useSearchResults,
  useActiveSearchResult,
  useSetActiveSearchResult,
  useSearchPages,
  useTheme,
  useSearch,
  useHasSearchResults,
  useResultsDisplayMode,
  useDisplayModesLocalized,
  useConfigValue,
  useTrackingContext,
} from '../../../hooks';
import { confKeys } from '../../../constants';
import { swiper } from '../../../utils/swiper';
import { LocalStoreSliderItem } from './LocalStoreSliderItem';

import { findClickoutType } from '../../../utils/dom';

const ccid = 'localstore-slider';

export const LocalStoreSlider = ({ pagination }) => {
  const searchResults = useSearchResults();
  const selectedItem = useActiveSearchResult();
  const setSelectedItem = useSetActiveSearchResult();
  const { filters } = useSearch();
  const hasResults = useHasSearchResults();
  const sliderRef = useRef(null);
  const selectionRef = useRef({ item: null });
  const { rtl } = useTheme();
  const [currentPage, setCurrentPage, numPages] = useSearchPages();
  const [slideIndex, setSlideIndex] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
  const [displayMode] = useResultsDisplayMode();
  const [newItemSelected, setNewItemSelected] = useState(false);
  const displayModeOptions = useDisplayModesLocalized();
  const perPage = useConfigValue(confKeys.ITEMS_PER_PAGE);
  const showWhatsApp = useConfigValue(confKeys.SHOW_WHATSAPP);
  const trackingContext = useTrackingContext();
  const { track } = trackingContext;

  useEffect(() => {
    if (hasResults && filters) {
      setSlideIndex(0);
    }
  }, [hasResults, filters]);

  useEffect(() => {
    if (searchResults && selectedItem) {
      selectionRef.current.item = selectedItem;
      setSlideIndex(Math.max(0, searchResults.indexOf(selectedItem)));
    } else {
      selectionRef.current.item = null;
    }
  }, [selectedItem, searchResults]);

  useEffect(() => {
    setNewItemSelected(true);
  }, [selectedItem]);

  useEffect(() => {
    let timeout;
    if (searchResults && searchResults[slideIndex]) {
      setIsSwiping(true);
      timeout = setTimeout(() => {
        setIsSwiping(false);
        if (selectionRef.current.item) {
          timeout = setTimeout(() => {
            setSelectedItem(searchResults[slideIndex].id);
          }, 250);
        }
      }, 250);
    } else {
      setIsSwiping(false);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [searchResults, slideIndex, setSelectedItem]);

  useEffect(() => {
    if (sliderRef.current && searchResults) {
      let hasSwiped = false;

      const handleSwipe = dir => {
        if (hasSwiped) return;
        const index = slideIndex + dir;
        if (searchResults[index]) {
          hasSwiped = true;
          setSlideIndex(index);
        }
      };

      return swiper(sliderRef.current, {
        start: () => {
          hasSwiped = false;
          setNewItemSelected(false);
        },
        move: offsetX => {
          if (!hasSwiped && Math.abs(offsetX) > 20) {
            const swipeOffset = rtl ? offsetX : -offsetX;
            handleSwipe(swipeOffset > 0 ? 1 : -1);
          }
        },
      });
    }
  }, [sliderRef, searchResults, setSelectedItem, slideIndex, rtl]);

  useEffect(() => {
    if (searchResults && slideIndex === searchResults.length - 1) {
      if (currentPage < numPages - 1) {
        setCurrentPage(currentPage + 1);
      }
    }
  }, [slideIndex, searchResults, numPages, currentPage, setCurrentPage]);

  const getStores = (currentPage, searchResults, perPage) => {
    const start = currentPage * perPage;
    const end = (currentPage + 1) * perPage;

    let results = searchResults.slice(start, end);

    return results
      .map(store => store.shop)
      .map((store, index) => {
        return {
          local_store_id: store.Id,
          local_store_name: store.Name,
          local_store_position: currentPage * perPage + index,
          distance_to_store:
            store.GeoInfo && store.GeoInfo[0]
              ? parseFloat(store.GeoInfo[0].Distance)
              : 0,
          click_and_collect_displayed:
            store.OfferInfo && store.OfferInfo.ClickAndCollectLink,
        };
      });
  };

  const getStoreTrackingData = useCallback((item, listIndex) => {
    return {
      local_store_id: item.id.split('-')[1],
      local_store_name: item.shop.Name,
      local_store_position: listIndex,
      distance_to_store:
        item.shop.GeoInfo && item.shop.GeoInfo[0]
          ? parseFloat(item.shop.GeoInfo[0].Distance)
          : 0,
    };
  }, []);

  const handleClickCard = useCallback(
    (event, id) => {
      if (setSelectedItem && id) {
        setSelectedItem(id);
        const clickoutType = findClickoutType(
          event.target,
          'store_card_click',
          event.currentTarget
        );
        const storeList = getStores(currentPage, searchResults, perPage);
        const storeIndex = searchResults.findIndex(store => store.id === id);
        const singleStore = searchResults.find(store => store.id === id);
        const displayModeDetails = displayModeOptions
          .filter(mode => mode.id === displayMode)
          .map((mode, index) => {
            return {
              label: mode.label,
              position: index,
            };
          })[0];

        track('store_click', {
          ...getStoreTrackingData(singleStore, storeIndex),
          clickout_type: clickoutType,
          local_store_list: storeList,
          click_and_collect_displayed: false,
          displayed_tab_type: displayMode,
          displayed_tab_name: displayModeDetails.label,
          displayed_tab_position: displayMode === 'list' ? 1 : 0,
        });
      }
    },
    [
      currentPage,
      displayMode,
      displayModeOptions,
      getStoreTrackingData,
      perPage,
      searchResults,
      setSelectedItem,
      track,
    ]
  );

  return searchResults ? (
    <Wrapper data-ccid={`${ccid}-wrapper`}>
      <Slider data-ccid={`${ccid}`} ref={sliderRef} isSwiping={isSwiping}>
        <Track data-ccid={`${ccid}-track`} slideIndex={slideIndex}>
          {searchResults.map((item, index) => (
            <Slide key={item.id} data-slide={index} data-ccid={`${ccid}-slide`}>
              <LocalStoreSliderItem
                hideContent={Math.abs(index - slideIndex) > 1}
                item={item}
                onClickItem={handleClickCard}
                selected={selectedItem && selectedItem.id === item.id}
                showWhatsApp={showWhatsApp}
                newItemSelected={newItemSelected}
              />
            </Slide>
          ))}
        </Track>
      </Slider>
      {pagination && (
        <Pagination>
          {slideIndex + 1} / {searchResults.length}
        </Pagination>
      )}
    </Wrapper>
  ) : null;
};

LocalStoreSlider.propTypes = {
  pagination: PropTypes.bool,
};

export default LocalStoreSlider;
