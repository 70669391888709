import { keyframes } from 'styled-components';

export const detectingAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const rotateAnim = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const fadeInAnim = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const markerBounceAnim = keyframes`
    0% {
        transform: none;
    }
    50% {
        transform: translateY(-30%);
    }
    100% {
        transform: none;
    }
`;
